import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import Userprofile from "../../components/UserProfile/Userprofile";
import { ActionCreators } from "../../redux/actions/profile";
import { useDispatch } from "react-redux";

const Main = () => {
  const dispatch = useDispatch()

    // userprofile
    const [state, setState] = useState({
      isPaneOpen: false,
      isPaneOpenLeft: false
    });
    //state for showing the dropdownenu for userprofile
    const [showMenu, setShowMenu] = useState(false);

    const openSlide = () => {
      setState({ isPaneOpen: true });
      setShowMenu(false)
    };

    const updateUserProfile = (data) => {
      dispatch(ActionCreators.updateUserProfile(data))
    }

  return (
    <div>
      <Header openSlide={openSlide} showMenu={showMenu} setShowMenu={setShowMenu}  />
      <Userprofile state={state} setState={setState} updateUserProfile={updateUserProfile}  />
      <Outlet />
    </div>
  );
};

export default Main;
