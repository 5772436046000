import React, { useEffect, useState } from "react";
import ToggleSwitch from "../../../ToggleSwitch/ToggleSwitch";
import { addNewShiftConst } from "../../../../utils/Consts/consts";
import { weekDays } from "../../../../utils/Consts/consts";
import { formatTimeDifferece } from "../../../../utils/FormatDate/formatTimeDifference";
import DurationBasedTimeSelectComp from "./DurationBasedTimeSelectComp";
import { TimePicker } from "antd";
import dayjs from "dayjs";

const SelectShiftType = ({ shiftType, setWork, work, totalWorkHour }) => {
  const { shiftType: shiftTypeView } = addNewShiftConst;
  const isClockBasedView = shiftType === shiftTypeView.clockBased;
  const [customErrMsg, setCustomErrMsg] = useState("");

  const cols = [
    { colName: "Working Day", status: true },
    { colName: "Time", status: true },
    { colName: "Break Time", status: isClockBasedView ? true : false },
    { colName: "Total Hours", status: isClockBasedView ? true : false },
  ];

  const [weekDayData, setWeekDayData] = useState(weekDays || []);

  useEffect(() => {
    setWeekDayData(weekDays); //resets the toggle state to diasble the selected field when we change the shift type
  }, [shiftType]);

  useEffect(() => {
    let errMsg = "Minimum one working day is required";
    const status = !!weekDayData?.find((item) => item.status);
    if (status) {
      setCustomErrMsg("");
    } else {
      setCustomErrMsg(errMsg);
    }
  }, [weekDayData]);

  const getToggleState = async (e, weekDayId) => {
    setWeekDayData((prev) => {
      return prev.map((item) => {
        if (item?.id === weekDayId) {
          return {
            ...item,
            status: e,
          };
        }
        return item;
      });
    });

    if (e) {
      setWork((prev) => {
        return {
          ...prev,
          [weekDayId]: {
            weekDayId,
            duration: 8,
            timeFrom: "09:00",
            timeTo: "17:00",
            break: false,
            breakFrom: "13:00",
            breakTo: "14:00",
          },
        };
      });
    } else {
      setWork((prev) => {
        return {
          ...prev,
          [weekDayId]: null,
        };
      });
    }
  };

  const handleChange = (value, source, day) => {
    setWork((prev) => {
      if (!isClockBasedView) {
        return {
          ...prev,
          [day]: {
            ...prev[day],
          },
        };
      }

      return {
        ...prev,
        [day]: {
          ...prev[day],
          weekDayId: day,
          [source]: value,
        },
      };
    });
  };

  const getTimeRef = (e) => {
    const { hourRef, minituRef, item } = e;
    const duration =
      Number(hourRef?.current?.value) + Number(minituRef?.current?.value / 60);
    setWork((prev) => {
      return {
        ...prev,
        [item.id]: { ...prev[item.id], duration },
      };
    });
  };

  const errMsg = (
    <p className="text-[11px] font-medium text-red-600">
      This field is required
    </p>
  );

  return (
    <div>
      {customErrMsg ? (
        <p className="text-red-600 bg-red-50 rounded-md p-2 font-medium">
          {customErrMsg}
        </p>
      ) : null}
      <table className="border-0">
        <thead className="bg-transparent capitalize border-b">
          <tr className="">
            {cols.map((item, index) => {
              if (item?.status) {
                return (
                  <th
                    className="text-black text-sm font-medium text-left py-2"
                    key={`col_${index}`}
                  >
                    {item?.colName}
                  </th>
                );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {weekDayData.map((item) => {
            const workObj = work[item.id];
            const formattedTimeDiff = formatTimeDifferece(
              workObj?.timeFrom,
              workObj?.timeTo
            );
            const totalHours = formattedTimeDiff
              ? `${formattedTimeDiff.hours} Hours ${formattedTimeDiff.minutes} Minutes`
              : "";
            return (
              <tr key={`weekday_${item.id}`}>
                <td className="py-2 pl-0">
                  <div className="flex items-center gap-2 font-medium">
                    <ToggleSwitch
                      getToggleState={(e) => getToggleState(e, item.id)}
                      checked={item.status}
                    />
                    {item.day}
                  </div>
                </td>
                <td className="py-1">
                  {item.status ? (
                    <>
                      <div className="flex gap-2">
                        {isClockBasedView ? (
                          <>
                            <div>
                              <TimePicker
                                // disabled={!fromDate}
                                allowClear={false}
                                changeOnBlur={true}
                                size="large"
                                format={"hh:mm a"}
                                minuteStep={15}
                                className="w-[110px]"
                                defaultValue={dayjs("09:00", "hh:mm")}
                                onChange={(e) =>
                                  handleChange(
                                    dayjs(e).format("HH:mm"),
                                    "timeFrom",
                                    item.id
                                  )
                                }
                              />
                             
                              {workObj?.timeFrom ? null : errMsg}
                            </div>
                            <div>
                              <TimePicker
                                disabled={!workObj?.timeFrom}
                                allowClear={false}
                                changeOnBlur={true}
                                size="large"
                                format={"hh:mm a"}
                                minuteStep={15}
                                className="w-[110px]"
                                defaultValue={dayjs("17:00", "hh:mm")}
                                onChange={(e) =>
                                  handleChange(
                                    dayjs(e).format("HH:mm"),
                                    "timeTo",
                                    item.id
                                  )
                                }
                              />
                              
                              {workObj?.timeTo ? null : errMsg}
                            </div>
                          </>
                        ) : (
                          <>
                            <DurationBasedTimeSelectComp
                              work={work}
                              key={`weekday_day_comp_${item.id}`}
                              getTimeRef={getTimeRef}
                              item={item}
                            />
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    "Non-working day"
                  )}
                </td>
                {isClockBasedView ? (
                  <td className="py-2 pl-0">
                    {item.status ? (
                      <div className="flex items-center gap-2">
                        <input
                          className="cursor-pointer"
                          type="checkbox"
                          onChange={(e) =>
                            handleChange(e.target.checked, "break", item.id)
                          }
                        />

                        <div>
                          <TimePicker
                            disabled={!workObj?.break}
                            allowClear={false}
                            changeOnBlur={true}
                            size="large"
                            format={"hh:mm a"}
                            minuteStep={15}
                            className="w-[110px]"
                            defaultValue={dayjs("13:00", "hh:mm")}
                            onChange={(e) =>
                              handleChange(
                                dayjs(e).format("HH:mm"),
                                "breakFrom",
                                item.id
                              )
                            }
                          />
                          
                          {workObj?.break
                            ? workObj?.breakFrom
                              ? null
                              : errMsg
                            : null}
                        </div>
                        <div>
                          <TimePicker
                            disabled={!workObj?.break}
                            allowClear={false}
                            changeOnBlur={true}
                            size="large"
                            format={"hh:mm a"}
                            minuteStep={15}
                            className="w-[110px]"
                            defaultValue={dayjs("14:00", "hh:mm")}
                            onChange={(e) =>
                              handleChange(
                                dayjs(e).format("HH:mm"),
                                "breakTo",
                                item.id
                              )
                            }
                          />
                          
                          {workObj?.break
                            ? workObj?.breakTo
                              ? null
                              : errMsg
                            : null}
                        </div>
                      </div>
                    ) : null}
                  </td>
                ) : null}
                {isClockBasedView ? (
                  <td className="py-2 pl-0">
                    <div className="flex flex-col">
                      {item.status ? totalHours : null}
                      {formattedTimeDiff.hours < 0 ? (
                        <span className="text-red-600 text-xs">
                          Invalid duration
                        </span>
                      ) : null}
                    </div>
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>
      {totalWorkHour ? (
        <div className="bg-gray-100 p-2 mt-3 rounded-md">
          <i className="ico-clock text-black mr-2"></i>
          <span className="font-semibold">Total: {totalWorkHour}</span>
        </div>
      ) : null}
    </div>
  );
};

export default SelectShiftType;
