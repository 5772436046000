import React, { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import AttendanceDurationTimer from "./AttendanceDurationTimer";
import moment from "moment";
import { formatClockDuration } from "../../../../utils/Attendance/formatClockDuration";
import formatDate from "../../../../utils/FormatDate/formatDate";
import { toast } from "react-toastify";

const CLOCK_IN = 1;
const CLOCK_OUT = 2;

const AttendanceActivityClock = (props) => {
  const firstAttendance = useRef(false);
  const axiosPrivate = useAxiosPrivate();
  const [attendanceActivityType, setAttendanceActivityType] = useState(null);
  const [attendanceActivityData, setAttendanceActivityData] = useState(null);
  const [initialClockDuration, setInitialClockDuration] = useState({
    durationInSeconds: null,
    message: "0hr 0m 0s",
  });

  const createAttendanceActivity = async (activity_type) => {
    props.getClockInOutBtnLoaderState &&
      props.getClockInOutBtnLoaderState(true);
    const newActivity =
      Number(activity_type) === CLOCK_IN ? CLOCK_OUT : CLOCK_IN;
    setAttendanceActivityType(newActivity);
    const activityTime = `${moment().format("YYYY-MM-DD")}T${moment().format(
      "HH:mm:ss"
    )}`;
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .post(`attendance/createClockInClockOut`, {
        activity_type,
        time: activityTime,
      })
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message || "");
        } else {
          props.setClockActivity && props.setClockActivity(null);
          toast.error(res?.data?.message || "Something went wrong, Contact HR");
        }
      })
      .catch((err) => {
        props.setClockActivity && props.setClockActivity(null);
        toast.error(
          err?.response?.data?.message || "Something went wrong, Contact HR"
        );
        setAttendanceActivityType(activity_type);
      })
      .finally(() => {
        props.getClockInOutBtnLoaderState &&
          props.getClockInOutBtnLoaderState(false);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    props.getAttendanceActivity &&
      props.getAttendanceActivity(attendanceActivityType);
  }, [attendanceActivityType]);

  useEffect(() => {
    let isMounted = true;
    const isValidActivity =
      Number.isInteger(Number(props.clockActivity)) &&
      (Number(props.clockActivity) === CLOCK_IN ||
        Number(props.clockActivity) === CLOCK_OUT);
    if (isValidActivity && isMounted) {
      createAttendanceActivity(props.clockActivity);
      if (firstAttendance.current && Number(props.clockActivity) === CLOCK_IN) {
        props.setClockActivityMsg &&
          props.setClockActivityMsg((prev) => ({
            ...prev,
            firstClockInTime: formatDate(new Date()).localString2,
          }));
        firstAttendance.current = false;
      }
    }
    return () => {
      isMounted = false;
    };
  }, [props.clockActivity]);

  const currentDate = moment().format("YYYY-MM-DD");

  const fetchAttendanceActivity = async () => {
    props.getClockInOutBtnLoaderState &&
      props.getClockInOutBtnLoaderState(true);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`attendance/getAttendanceActivity?date=${currentDate}`)
      .then((res) => {
        if (res.data.status) {
          setAttendanceActivityData(res?.data?.result || null);
          props.setAttendanceActivityData &&
            props.setAttendanceActivityData(res?.data?.result || null); //send to the parent component
        } else {
          firstAttendance.current = true;
        }
      })
      .catch((err) => {
        // console.log(err);
        firstAttendance.current = true;
        setInitialClockDuration((prev) => ({
          ...prev,
          durationInSeconds: 0,
        }));
      })
      .finally(() => {
        props.getClockInOutBtnLoaderState &&
          props.getClockInOutBtnLoaderState(false);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    if (attendanceActivityData) {
      const { lastActivityType } = attendanceActivityData;
      if (lastActivityType && lastActivityType === CLOCK_IN) {
        setAttendanceActivityType(CLOCK_OUT);
      } else {
        setAttendanceActivityType(CLOCK_IN);
      }
      setInitialClockDuration(formatClockDuration(attendanceActivityData));
    } else {
      setAttendanceActivityType(CLOCK_IN);
    }
  }, [attendanceActivityData]);

  useEffect(() => {
    fetchAttendanceActivity();
  }, []);

  return (
    <>
      <AttendanceDurationTimer
        attendanceActivityType={attendanceActivityType}
        CLOCK_IN={CLOCK_IN}
        CLOCK_OUT={CLOCK_OUT}
        initialClockDuration={initialClockDuration}
      />
    </>
  );
};

export default AttendanceActivityClock;
