import React, { useEffect, useState, useRef } from "react";
import HolidayListTable from "../HolidayListTable/HolidayListTable";
import Search from "../../../Header/Search/Search";
import AddNewHoliday from "../AddNewHoliday/AddNewHoliday";
import EditHoliday from "../EditHoliday/EditHoliday";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Select, { components } from "react-select";
import Pagination from "../../../Pagination/Pagination";
import { DropdownIndicator } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";

const HolidayList = ({ policyMapping, setSelectedPolicy }) => {
  const [isAddNewHoliday, setIsAddNewHoliday] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [holidayList, setHolidayList] = useState(null);
  const [locationList, setLocationList] = useState({
    country: [],
    state: [],
    district: [],
  });
  const [location, setLocation] = useState({
    country: 0,
    state: 0,
    city: 0,
    date: "",
    search: "",
  }); // selected locaition id and date filtering
  const [searchValue, setSearchValue] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [makeApiCall, setMakeApiCall] = useState(false);
  const filterRef = useRef({
    country: "",
    state: "",
    date: "",
  });
  const isFilterApplied =
    location.country || location.state || location.date || location.search;

  const handleClearFilters = () => {
    setLocation({
      country: 0,
      state: 0,
      city: 0,
      date: "",
    });
    if (filterRef?.current?.country) {
      filterRef.current.country.clearValue();
    }
    if (filterRef?.current?.state) {
      filterRef.current.state.clearValue();
    }
    if (filterRef?.current?.date) {
      filterRef.current.date.value = "";
    }
  };

  const handleMakeApiCallToFetchHolidayList = () => {
    setMakeApiCall((prev) => !prev);
  };

  useEffect(() => {
    let baseUrl = `onboarding/services/location?`;
    let holidayListBaseUrl = `lms/holiday/holidayList?holiday&page_number=${pageNo}`;
    if (location.country) {
      baseUrl += `StatesFrom=${location.country}`;
      holidayListBaseUrl += `&country_id=${location.country}`;
    }
    if (location.state) {
      baseUrl += `&CityFrom=${location.state}`;
      holidayListBaseUrl += `&state_id=${location.state}`;
    }
    if (location.date) {
      holidayListBaseUrl += `&date=${location.date}`;
    }
    if (location.search) {
      holidayListBaseUrl += `&search=${location.search}`;
    }

    fetchLocationList(baseUrl);
    fetchHolidayList(holidayListBaseUrl);
  }, [location, pageNo, makeApiCall]);

  const fetchLocationList = async (baseUrl) => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(baseUrl)
      .then((response) => {
        // isMounted && console.log(response.data);
        const res = response?.data;
        if (res.status || res.statusCode === 200) {
          if (res.country) {
            setLocationList((prev) => {
              return {
                ...prev,
                country: res.country,
              };
            });
          }
          if (res.state) {
            setLocationList((prev) => {
              return {
                ...prev,
                state: res.state,
              };
            });
          }
          if (res.city) {
            setLocationList((prev) => {
              return {
                ...prev,
                city: res.city,
              };
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchHolidayList = async (url) => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(url)
      .then((response) => {
        // isMounted && console.log(response.data);
        const res = response?.data;
        if (res.status) {
          setTotalRecords(res.total);
          setHolidayList(res.holidays);
        } else {
          setTotalRecords(0);
          setHolidayList(null);
        }
      })
      .catch((err) => {
        console.error(err);
        setTotalRecords(0);
        setHolidayList(null);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setLocation((prev) => ({ ...prev, search: searchValue }));
    }, 500);

    return () => {
      clearTimeout(delayTimer);
    };
  }, [searchValue]);

  const getSearchValue = (e) => {
    setSearchValue(e);
  };

  return (
    <>
      <div className="w-full">
        {isAddNewHoliday ? (
          <AddNewHoliday
            open={isAddNewHoliday}
            close={() => setIsAddNewHoliday(false)}
            handleMakeApiCallToFetchHolidayList={
              handleMakeApiCallToFetchHolidayList
            }
          />
        ) : null}

        <div className="flex justify-between items-center px-10 py-6">
          <div>
            <h2 className="">Holiday Lists</h2>
            <p className="pb-4">
              View and manage all your organizations holidays
            </p>
          </div>
          <button
            className="btn text-[14px]"
            onClick={() => setIsAddNewHoliday(true)}
          >
            <span>
              <i className="ico-plus text-white mr-1"></i>
            </span>
            New Holiday
          </button>
        </div>
        <div className="flex justify-between items-center px-10 mb-4">
          <div className="flex gap-3">
            <div>
              <input
                ref={(ele) => (filterRef.current.date = ele)}
                onChange={(e) =>
                  setLocation((prev) => ({ ...prev, date: e.target.value }))
                }
                type="date"
                placeholder="Select a date"
                className="appearance-none bg-transparent border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-600"
              />
            </div>
            <Select
              ref={(ele) => (filterRef.current.country = ele)}
              components={{ DropdownIndicator }}
              className="w-[200px]"
              placeholder={"Country"}
              options={
                locationList.country &&
                locationList.country.map((item) => {
                  return { label: item.flag, value: item.id };
                })
              }
              onChange={(e) =>
                setLocation((prev) => {
                  return { ...prev, country: e?.value };
                })
              }
            />
            <Select
              ref={(ele) => (filterRef.current.state = ele)}
              components={{ DropdownIndicator }}
              className="w-[200px]"
              placeholder={"State"}
              options={
                locationList.state &&
                locationList.state.map((item) => {
                  return { label: item.state_name, value: item.id };
                })
              }
              onChange={(e) => {
                setLocation((prev) => {
                  return { ...prev, state: e?.value };
                });
              }}
            />
            {isFilterApplied && (
              <button
                className="text-blue-600 font-medium"
                onClick={handleClearFilters}
              >
                Clear Filters
              </button>
            )}
          </div>
          <div className="flex items-center gap-3">
            <Search onChange={getSearchValue} />
            <a href="" className="btn btn--border text-[14px]">
              <span>
                <i className="ico-download mr-1"></i>
              </span>
              Download
            </a>
          </div>
        </div>
        <HolidayListTable
          holidayList={holidayList}
          policyMapping={policyMapping}
          setSelectedPolicy={setSelectedPolicy}
        />
        {holidayList && (
          <Pagination
            page={{
              pageNo,
              setPageNo,
              pageSize,
              setPageSize,
              totalRecords,
            }}
          />
        )}
      </div>
    </>
  );
};

export default HolidayList;
