import React, { useState, useEffect } from "react";
import { closeIcon, warningIcon } from "../../../../Icons/Icons";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Select from "react-select";
import { getCurrentMonthDates } from "../../../../utils/FormatDate/formaDateInMonths";

// import {
//   department as departmentoption,
//   designation as designationOption,
//   location as locationOption,
//   DropdownIndicator,
// } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import Search from "../../../Header/Search/Search";
import ViewEmployeeFinalizeAttendaceTable from "./ViewEmployeeFinalizeAttendaceTable";
import { useNavigate } from "react-router-dom";
const { firstDay, lastDay } = getCurrentMonthDates();
const ViewEmployeeFinalizeAttendance = () => {
  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate(-1)
  }
  const axiosPrivate = useAxiosPrivate();
  const [pendingList, setPendingList] = useState([]);
  const fetchAttendanceEmployeeList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("attendance/getAllRegularizationPendingList", {
      })
      .then((response) => {
        const { data } = response;
        // console.log("data",data)
        if (data?.status) {
          setPendingList(data.result);
        }
      })
      .catch((err) => {
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  useEffect(() => {
    fetchAttendanceEmployeeList();
  }, []);
 
 
  return (
    <div className="w-full">
      <div className="flex justify-between bg-yellow-500 px-5 py-2 items-center">
        <div className="flex gap-2 text-black items-center">
          {warningIcon({ fill: "black" })} To finalize employee attendance
          please process all the open employee attendance below
        </div>
        <div>{closeIcon}</div>
      </div>
      <div className="px-10 pb-3">
        <div className="flex items-center gap-3 py-5">
          <div className="">
            <button className="btn btn--border w-7 h-7 p-0" onClick={handleGoBack}>
              <i className="ico-left text-black text-xs"></i>
            </button>
          </div>
          <div>
            <h1 className="text-black text-lg font-semibold">
              Finalize Attendance
            </h1>
            <p>
            {firstDay} - {lastDay}
              <span className="status ml-2">Pending</span>
            </p>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex gap-3">
            {/* <Select
              components={{ DropdownIndicator }}
              options={departmentoption}
            />
            <Select
              components={{ DropdownIndicator }}
              options={designationOption}
            />
            <Select
              components={{ DropdownIndicator }}
              options={locationOption}
            /> */}
          </div>
          <div className="flex">
            <Search />
            <button className="btn btn--border">
              <i className="ico-download mr-2"></i>
              Download
            </button>
          </div>
        </div>
      </div>
      <ViewEmployeeFinalizeAttendaceTable data={pendingList} />
    </div>
  );
};

export default ViewEmployeeFinalizeAttendance;
