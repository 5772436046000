import React from 'react'
import DashboardSidebar from '../../components/DashboardSidebar'
import Aside from '../Dashboard/Aside/Aside'
import { Outlet } from 'react-router-dom'

const menu = [
  {
    text: "Widgets",
    link: `/settings/widget-list`,
    icon: "ico-down",
    roles: [1, 2],
    status: true,
  },
  {
    text: "Tenant Setting",
    link: `/settings/tenant-setting`,
    icon: "ico-down",
    roles: [1, 2],
    status: true,
  },
  {
    text: "App Setting",
    link: `/settings/app-setting`,
    icon: "ico-down",
    roles: [1, 2],
    status: true,
  },
]

const SettingsModule = () => {
  return (
    <div className="lg:flex ">
      <DashboardSidebar/>
      <div className="flex flex-1">
        <Aside submenu={menu} />
        <Outlet />
      </div>
    </div>
  );
}

export default SettingsModule