import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Select, { components } from "react-select";
import Avatar from "../../Avatar/Avatar";
import Line from "../../Line/Line";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import formatLeaveDuration from "../../../utils/FormatLeaveDuration/formatLeaveDuration";
import SpinnerLoader from "../../PageLoader/SpinnerLoader";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const AssignLeaveModal = ({ open, close, employee, leavePolicy }) => {
  const axiosPrivate = useAxiosPrivate();
  const employeeName = `${employee?.first_name} ${employee?.last_name}`;
  const employeeSerial = employee?.employee_serial;
  const department = employee?.department_name;
  const roleName = employee?.role_name;
  const employeePhoto = employee?.photo;
  const employeeId = employee?.employee_id;

  const [errMsg, setErrMsg] = useState("");
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [employeeLeaveBalance, setEmployeeLeaveBalance] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleCloseModal = () => {
    close();
  };

  const fetchLeaveTypes = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`lms/leave/leaveTypesList?status=1&fullDetails=1`)
      .then((response) => {
        // isMounted && console.log(response.data, "leave typees");
        const res = response?.data;
        if (res.status && Number(res.statusCode) === 200) {
          setLeaveTypes(res.data);
        }
      })
      .catch((err) => {
       // console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchEmployeeLeaveBalance = async () => {
    const result = await axiosPrivate
      .get(
        `lms/leave/employeeLeaveBalanceList?sortdOrder=DESC&employee_serial=${employeeSerial}`
      )
      .then((res) => res)
      .catch((err) => err);
    if (result?.data?.status) {
      const [employee] = result?.data?.employee;
      setEmployeeLeaveBalance(employee?.leaveBalances || []);
    }
  };

  useEffect(() => {
    if (employeeSerial) {
      setLoader(true);
      Promise.all([fetchLeaveTypes(), fetchEmployeeLeaveBalance()]).finally(
        () => {
          setLoader(false);
        }
      );
    }
  }, [employee]);

  const assignLeavePolicy = async (leaveTypes) => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .post(`lms/leave/manageNewEmployee`, {
        employee_id: employeeId,
        leaveTypes,
      })
      .then((response) => {
        // isMounted && console.log(response.data, "manageNewEmployee");
        const res = response?.data;
        if (res.message === "KEY_EXPIRED" && Number(res.statusCode) === 401) {
          toast.error("Something went wrong, Try Log in again.");
        } else if (res?.status) {
       
          toast.success(res?.message);
          handleCloseModal();
        } else {
       
          toast.error(res?.response?.data?.message || "Failed to assign leave policy");
        }
      })
      .catch((err) => {
      
        toast.error(err?.response?.data?.message || "Something went wrong while assign leave policy"); // Leave type are already exits?
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const formatLeaveTypesInPolicy = (policy) => {
    let formattedArr = [];
    policy &&
      policy.forEach((item) => {
        formattedArr.push({
          leaveTypeId: item.leave_type_id,
          leaveBalance: item.number_days_allowed,
        });
      });
    assignLeavePolicy(formattedArr);
  };

  const fetchLeavePolicyDetails = async (id) => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`lms/leave/listLeavePolicyMapping?policy_id=${id}`)
      .then((response) => {
        // isMounted && console.log(response.data, "manageNewEmployee");
        const res = response?.data;
        if (res?.status) {
          formatLeaveTypesInPolicy(res?.policy_list);
        }else{
          toast.error("No active leave type in requested policy")
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("No response");
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const handleSubmit = async () => {
    if (selectedPolicy) {
      setErrMsg("");
      fetchLeavePolicyDetails(selectedPolicy);
    } else {
      setErrMsg("Select a leave policy ");
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleCloseModal} center>
        <div className="p-5 min-w-[420px] text-black">
          <h1 className="text-base font-medium">Assign leave types</h1>
          <Line />
          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-3">
              <Avatar image={employeePhoto} />{" "}
              <span className="text-base font-medium">{employeeName}</span>
            </div>
            <div className="flex justify-between">
              <ul className="text-gray-600">
                <li>Employee serial:</li>
                <li>Department:</li>
                <li>Role:</li>
              </ul>
              <ul>
                <li>{employeeSerial}</li>
                <li>{department}</li>
                <li>{roleName}</li>
              </ul>
            </div>

            <div className="flex flex-col justify-between">
              <span className="text-red-600 font-medium">{errMsg}</span>
              {
                loader ? <div className="flex justify-center">
                  <SpinnerLoader/>
                </div> : !!employeeLeaveBalance.length ? <div className="border rounded-md p-2 shadow-md">
                <label>Current leave balances:</label>
                {Array.isArray(employeeLeaveBalance) &&
                  employeeLeaveBalance.map((i) => {
                    const leaveTypeData =
                      leaveTypes?.find?.(
                        (leave) =>
                          Number(leave?.id) === Number(i?.leave_type_id)
                      ) || {};
                    const leaveBalance = formatLeaveDuration({
                      durationInDay: i.leave_balance_days,
                      durationInHour: i.leave_balance_hours,
                    });
                    return (
                      <ul className="flex justify-between">
                        <li>{leaveTypeData.leave_name}: </li>
                        <li>{leaveBalance}</li>
                      </ul>
                    );
                  })}
              </div> : <span className="status">No leave policy assigned for this employee</span>
              }
              <div>
                {leavePolicy?.length ? (
                  <>
                    <p className="py-4 text-sm font-medium">
                      Select leave policy:
                    </p>
                    <Select
                      components={{ DropdownIndicator }}
                      options={
                        leavePolicy &&
                        leavePolicy.map((item) => {
                          return { label: item.policy_name, value: item.id };
                        })
                      }
                      onChange={(e) => {
                        setSelectedPolicy(e.value);
                      }}
                    />
                  </>
                ) : (
                  <span className="text-red-600 font-medium">
                    {"Create leave policy to assign"}
                  </span>
                )}
              </div>
              <div className="flex justify-end gap-3 pt-4">
                <button
                  type="button"
                  className="btn btn--border"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn"
                  onClick={leavePolicy.length && handleSubmit}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AssignLeaveModal;
