import React, { useState, useEffect, useRef } from "react";
import formatSecondsToTime from "../../../../utils/FormatDate/formatSecondsToTime";

const AttendanceDurationTimer = (props) => {
  const {
    attendanceActivityType,
    CLOCK_OUT,
    CLOCK_IN,
    initialClockDuration,
  } = props;

  const initialRender = useRef(true)

  const [clockDuration, setClockDuration] = useState({
    durationInSeconds: null,
    message: "0hr 0m 0s",
  });


  useEffect(() => {
    if (initialRender.current) {
      if (Number.isInteger(initialClockDuration.durationInSeconds)) {
        setClockDuration(initialClockDuration);
        initialRender.current = false;
      }
    }
  }, [initialClockDuration])

  useEffect(() => {
    if (
      Number.isInteger(clockDuration.durationInSeconds) &&
      attendanceActivityType === CLOCK_OUT
    ) {
      const delayTimer = setInterval(() => {
        setClockDuration((prev) => ({
          durationInSeconds: prev.durationInSeconds + 1,
          message: formatSecondsToTime(prev.durationInSeconds + 1),
        }));
      }, 1000);
      return () => {
        clearTimeout(delayTimer);
      };
    }
  }, [clockDuration, attendanceActivityType]);
  return <div className="whitespace-nowrap">{clockDuration.message}</div>;
};

export default AttendanceDurationTimer;
