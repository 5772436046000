import React from "react";

const MultipleLeaveActionPercentageBar = ({actions, length}) => {
  let numberOfActions = actions > length ? length : actions
  let percentage = (Number(numberOfActions) / Number(length)) * 100;

  return (
    <div
      className={`h-2 rounded-xl bg-${"green"}-300`}
      style={{ width: percentage + "%" }}
    ></div>
  );
};

export default MultipleLeaveActionPercentageBar;
