import React, { useState } from "react";
import Aside from "../Dashboard/Aside/Aside";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "../../components/DashboardSidebar/DashboardSidebar";

const EmployeeModule = () => {

  const [submenu, setSubmenu] = useState([
    // {
    //   text: "Overview",
    //   link: "/employee/overview",
    //   roles: [1, 2, 4],
    // },
    {
      text: "Manage Employee",
      link: "/employee/manage-employee/employee-list",
      icon: "ico-down",
      roles: [1, 2, 4],
      menu: [
        {
          text: "Employee Lists",
          link: "/employee/manage-employee/employee-list",
          roles: [1, 2, 4],
        },
        // {
        //   text: "Employee Directory",
        //   link: "/employee/manage-employee/employee-directory",
        //   roles: [1, 2, 4],
        // },
        // {
        //   text: "Organization Tree",
        //   link: "/employee/manage-employee/organization-tree",
        //   roles: [1, 2, 4],
        // },
        // {
        //   text: "Onboarding",
        //   link: "#",
        //   roles: [1, 2, 4],
        // },
        // {
        //   text: "Separation",
        //   link: "/employee/manage-employee/seperation",
        //   roles: [1, 2, 4],
        // },
        // {
        //   text: "Off-boarding",
        //   link: "#",
        //   roles: [1, 2, 4],
        // },
        // {
        //   text: "Background Check",
        //   link: "#",
        //   roles: [1, 2, 4],
        // },
      ],
      status: true,
    },
    // {
    //   text: "Recruitment",
    //   link: "/employee/recruitment/talent-pool",
    //   icon: "ico-down",
    //   roles: [2, 4, 1],
    //   menu: [
    //     {
    //       text: "Talent Pool",
    //       link: "/employee/recruitment/talent-pool",
    //       roles: [2, 4, 1],
    //     },
    //     {
    //       text: "Job Opening",
    //       link: "/employee/recruitment/job-opening",
    //       roles: [2, 4, 1],
    //     },
    //     {
    //       text: "Offer",
    //       link: "/employee/recruitment/offer",
    //       roles: [2, 4, 1],
    //     },
    //   ],
    //   status: false,
    // },
  ]);

  return (
    <div className="lg:flex">
      <DashboardSidebar />
      <div className="flex flex-1">
        <Aside submenu={submenu} setmenu={setSubmenu} />
        <Outlet />
      </div>
    </div>
  );
};

export default EmployeeModule;
