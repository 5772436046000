import React, { useEffect, useState } from "react";
import Select from "react-select";
import { DropdownIndicator } from "../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import AssignShiftTable from "../../../components/Attendance/ManageShift/AssignShift/AssignShiftTable";
import AssignShiftSlidingPanel from "../../../components/Attendance/ManageShift/AssignShift/AssignShiftSlidingPanel";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import moment from "moment";
import formatDate from "../../../utils/FormatDate/formatDate";
import EmptyState from "../../../components/EmptyState";
import PageLoader from "../../../components/PageLoader";
import Pagination from "../../../components/Pagination/Pagination";

const AssignShift = () => {
  const axiosPrivate = useAxiosPrivate();
  const [firstDate, setFirstDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [lastDate, setLastDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [openAssignShiftSlidingPanel, setAssignShiftSlidingPanel] =
    useState(false);
  const [employeeShiftList, setEmployeeShiftList] = useState([]);
  const [departments, setDepartmentList] = useState([]);
  const [locations, setLocationList] = useState([]);
  const [designations, setDesignationList] = useState([]);
  const [employeeShiftListUrl, setEmployeeShiftListUrl] = useState(
    `attendance/getEmployeeShiftList?fromDate=${firstDate}&toDate=${lastDate}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
  const [department, setSelectedDepartment] = useState(null);
  const [location, setSelectedLocation] = useState(null);
  const [designation, setSelectedDesignation] = useState(null);
  const [loaderComponent, setLoaderComponent] = useState(<PageLoader />);
  const isFilterApplied =
    department ||
    location ||
    designation ||
    firstDate !== moment().startOf("month").format("YYYY-MM-DD");

  const formatedFirstDay = formatDate(firstDate).dateString2;
  const formatedLastDay = formatDate(lastDate).dateString2;

  const handleClickAssignShift = (e) => {
    setAssignShiftSlidingPanel(e);
  };
  const clearAllFilter = () => {
    setSelectedDepartment(null);
    setSelectedLocation(null);
    setSelectedDesignation(null);
    setFirstDate(moment().startOf("month").format("YYYY-MM-DD"));
    setLastDate(moment().endOf("month").format("YYYY-MM-DD"));
    let baseUrl = `attendance/getEmployeeShiftList?fromDate=${firstDate}&toDate=${lastDate}&pageNo=${pageNo}&pageSize=${pageSize}`;
    setEmployeeShiftListUrl(baseUrl);
  };

  const handleChangeDate = (e) => {
    if (e === "nextMonth") {
      const nextMonth = moment(firstDate).add(1, "month").format("YYYY-MM-DD");
      setFirstDate(nextMonth);
      setLastDate(moment(nextMonth).endOf("month").format("YYYY-MM-DD"));
    } else {
      const prevMonth = moment(firstDate)
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      setFirstDate(prevMonth);
      setLastDate(moment(prevMonth).endOf("month").format("YYYY-MM-DD"));
    }
  };

  const fetchEmployeeShiftList = async () => {
    setLoaderComponent(<PageLoader />);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(employeeShiftListUrl)
      .then((response) => {
        const res = response?.data;
        let shiftListData = res.result;
        if (shiftListData.length) {
          setTotalRecords(Number(res?.totalRecords || 0));
          setEmployeeShiftList(shiftListData);
          setLoaderComponent(null);
        } else {
          setLoaderComponent(<EmptyState />);
        }
      })
      .catch((err) => {
        setLoaderComponent(<EmptyState />);
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const fetchDepartmentList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`employee/getDepartmentList`)
      .then((response) => {
        const res = response?.data;
        const departmentList = res.result.map((department, index) => {
          return {
            label: department.department_name,
            value: department.id,
            key: index,
          };
        });
        setDepartmentList(departmentList);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const fetchLocationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`employee/getLocationList`)
      .then((response) => {
        const res = response?.data;
        const locationList = res.result.map((location, index) => {
          return {
            label: location.location_name,
            value: location.id,
            key: index,
          };
        });
        setLocationList(locationList);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const fetchDesignationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`employee/getDesignationList`)
      .then((response) => {
        const res = response?.data;
        const designationList = res.result.map((designation, index) => {
          return {
            label: designation.designation_title,
            value: designation.id,
            key: index,
          };
        });
        setDesignationList(designationList);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchEmployeeShiftList();
  }, [employeeShiftListUrl]);
  useEffect(() => {
    let baseUrl = `attendance/getEmployeeShiftList?fromDate=${firstDate}&toDate=${lastDate}&pageNo=${pageNo}&pageSize=${pageSize}`;
    if (department) {
      baseUrl = baseUrl + `&department_id=${department}`;
    }
    if (location) {
      baseUrl = baseUrl + `&location_id=${location}`;
    }
    if (designation) {
      baseUrl = baseUrl + `&designation_id=${designation}`;
    }
    setEmployeeShiftListUrl(baseUrl);
  }, [firstDate, department, designation, location, pageNo, pageSize]);

  useEffect(() => {
    fetchDepartmentList();
    fetchLocationList();
    fetchDesignationList();
  }, []);

  return (
    <>
      {openAssignShiftSlidingPanel ? (
        <AssignShiftSlidingPanel
          open={openAssignShiftSlidingPanel}
          close={() => handleClickAssignShift(false)}
          fetchEmployeeShiftList={fetchEmployeeShiftList}
        />
      ) : null}
      <div className="flex flex-col w-full">
        <div className="p-10 flex flex-col gap-3">
          <div className="flex justify-between">
            <div>
              {" "}
              <h1 className="text-black font-semibold text-xl">Assign Shift</h1>
              <p>Manage and view all shifts</p>
            </div>
            <div>
              <button
                className="btn"
                onClick={() => handleClickAssignShift(true)}
              >
                <i className="ico-plus mr-1.5"></i>
                Assign Shift
              </button>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex gap-3">
              <Select
                placeholder="Department"
                options={departments}
                components={{ DropdownIndicator }}
                onChange={(e) => {
                  setSelectedDepartment(e.value);
                }}
              />
              <Select
                placeholder="Designation"
                options={designations}
                components={{ DropdownIndicator }}
                onChange={(e) => {
                  setSelectedDesignation(e.value);
                }}
              />
              <Select
                placeholder="Location"
                options={locations}
                components={{ DropdownIndicator }}
                onChange={(e) => {
                  setSelectedLocation(e.value);
                }}
              />
              {isFilterApplied ? (
                <p
                  className="text-blue-600 font-medium pt-2 cursor-pointer"
                  onClick={clearAllFilter}
                >
                  Clear filter
                </p>
              ) : null}
            </div>

            <div className="flex gap-3 items-center text-black">
              <button onClick={() => handleChangeDate("prevMonth")}>
                <i className="ico-left text-blue-600"></i>
              </button>
              <p className="font-medium">
                {formatedFirstDay} - {formatedLastDay}
              </p>
              <button
                name="nextMonth"
                onClick={() => handleChangeDate("nextMonth")}
              >
                <i className="ico-right text-blue-600" />
              </button>
            </div>
          </div>
        </div>
        {loaderComponent ? (
          loaderComponent
        ) : employeeShiftList.length ? (
          <AssignShiftTable data={employeeShiftList} />
        ) : null}
        <div>
          <Pagination
            page={{
              pageNo,
              setPageNo,
              pageSize,
              setPageSize,
              totalRecords,
            }}
          />
        </div>
        {/* {employeeShiftList.length ? (
          <AssignShiftTable data={employeeShiftList} />
        ) : (
          <EmptyState />
        )} */}
      </div>
    </>
  );
};

export default AssignShift;
