import useAxiosPrivate from "./useAxiosPrivate";
import { PROFILE_IMAGE, LEAVE_FILES } from "../Consts/filePathConst";

const useFetchFile = () => {
  const axiosPrivate = useAxiosPrivate();

  const fetchFile = async (url) => {
    try {
      if (url) {
        const response = await axiosPrivate
          .get(url, {
            responseType: "blob",
          })
          .then((res) => res)
          .catch((err) => err);
        const fileSrc = URL.createObjectURL(response.data);
        return fileSrc;
      }
    } catch (err) {
      return;
    }
  };

  const checkFileType = (fileName, filePath) => {
    if (fileName && filePath) {
      let url = process.env.REACT_APP_BASE_URL;
      switch (filePath) {
        case PROFILE_IMAGE:
          url += process.env.REACT_APP_EMPLOYEE_PROFILE_IMAGE_PATH;
          break;
        case LEAVE_FILES:
          url += process.env.REACT_APP_LEAVE_FILE_PATH;
          break;
      }
      url += fileName;
      const isNotAllowedFileNames =
        fileName === "'" ||
        fileName === undefined ||
        fileName === "undefined" ||
        fileName === "images/avatar.jpg" ||
        fileName === "/images/avatar.jpg" ||
        fileName === null;
      if (isNotAllowedFileNames) {
        url = null
      }
      return fetchFile(url);
    }
    return new Error("File name and path is required to fetch");
  };
  return checkFileType;
};

export default useFetchFile;
