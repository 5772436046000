import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import Line from "../../../../../components/Line/Line";
import { useForm, Controller } from "react-hook-form";
import Avatar from "../../../../../components/Avatar";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import {
  employeeLevelOptions,
  sourceFrom,
} from "../../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down"></span>
    </components.DropdownIndicator>
  );
};

const AUTOMATED_ENTRY = 2;
const MANUAL_ENTRY = 1;

const entryOptions = [
  { value: MANUAL_ENTRY, label: "Manual Entry" },
  { value: AUTOMATED_ENTRY, label: "Automated Entry" },
];

const AddEmployeeBasic = () => {
  const axiosPrivate = useAxiosPrivate();
  const getDataFromLocalStorage =
    JSON?.parse?.(localStorage?.getItem("employeeBasicData")) || {};
  const [errMsg, setErrMsg] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [textLoadMore, setTextLoadMore] = useState(false);
  const navigate = useNavigate();
  const [serialKeyEntryType, setSerialKeyEntryType] = useState(
    getDataFromLocalStorage?.serialKeyEntryType || AUTOMATED_ENTRY
  );
  const [isSerialKeyAvailable, setIsSerialKeyAvailable] = useState({
    available: false,
    checked: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: getDataFromLocalStorage,
  });

  const [locationList, setLocationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [jobTitleList, setJobTitleList] = useState([]);
  const [employeeType, setEmployeeType] = useState([]);
  const [serialList, setSerialList] = useState();

  const fetchLocationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getLocationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setLocationList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const fetchEmployeeType = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getEmployeeTypeList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setEmployeeType(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDepartmentList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDepartmentList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDepartmentList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDesignationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDesignationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDesignationList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const fetchJobTitleList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getJobTitleList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setJobTitleList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchTenantSerialTypes = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getTenantSerialList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setSerialList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  //

  useEffect(() => {
    fetchTenantSerialTypes();
    fetchEmployeeType();
    fetchJobTitleList();
    fetchDesignationList();
    fetchDepartmentList();
    fetchLocationList();
    if (
      Object.keys(getDataFromLocalStorage).length &&
      localStorage.getItem("isContinue") == "true"
    ) {
      setShowModal(true);
      setErrMsg("You left midway while adding an employee !");
    }
  }, []);

  const openModal = () => {
    setErrMsg("");
    localStorage.removeItem("isContinue");
    setShowModal(!showModal);
  };

  const discardpendingEmployeeCreation = () => {
    setErrMsg("");
    reset({
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      portal_access: false,
      serialKeyEntryType: AUTOMATED_ENTRY,
      office_location: "",
      source: "",
      department: "",
      job_title: "",
      employementType: "",
      designation: "",
      empLevel: "",
      serial_id: ""
    });
    setShowModal(!showModal);
    localStorage.removeItem("employeeBasicData");
    localStorage.removeItem("employeePersonalData");
  };

  const onSubmit = async (data) => {
    if (!isSerialKeyAvailable.checked && serialKeyEntryType === MANUAL_ENTRY) {
      return toast.info("Check availability of the serial number");
    }
    let isMounted = true;
    const controller = new AbortController();

    await axiosPrivate
      .get(`employee/search?email=${data.email}`, {
        signal: controller.signal,
      })
      .then((response) => {
        // isMounted && console.log(response.data);
        if (
          response.data.statusCode == 400 &&
          response.data.message == "No data found."
        ) {
          setErrMsg("");
          localStorage.removeItem("isContinue");
          localStorage.setItem("employeeBasicData", JSON.stringify(data));
          navigate("/employee/manage-employee/employee-personal-details");
        } else if (response.data.statusCode == 200) {
          setErrMsg("Email already exist!");
          toast.error("Email already exist!");
        } else {
          toast.error("No server response!");
          toast.info("Try logging again!");
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const checkSerialKeyAvailablity = async () => {
    const value = getValues("serial_id");
    if (!value) {
      return toast.info("Enter a serial number");
    }
    const serial = serialList.find((i) => i.type === "employee");
    const serialKey = `${serial.prefix}-${value}-${serial.suffix}`;
    await axiosPrivate
      .get(`employee/search?pageSize=1000&employee_serial=${serialKey}`)
      .then((res) => {
        if (Number(res.data.statusCode) === 200) {
          setIsSerialKeyAvailable({
            available: false,
            checked: true,
          });
        } else {
          setIsSerialKeyAvailable({
            available: true,
            checked: true,
          });
        }
      })
      .catch((err) => {
        setIsSerialKeyAvailable({
          available: true,
          checked: true,
        });
        console.log(err);
      });
  };

  return (
    <div className="flex justify-center w-full">
      {/* Continue existing employee creation modal popup  */}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="text-center items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t min-w-[500px] ">
                  <h3 className="text-xl text-black font-semibold">
                    Continue employee creation?
                  </h3>
                </div>
                <div className="flex justify-center">
                  <div className=" min-w-[350px] mb-5 font-semibold">
                    <br />
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 mb-2">
                      {errMsg}
                    </span>

                    <div className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mb-4">
                      <h5 className="mb-2 text-base font-medium tracking-tight text-gray-900 dark:text-white inline-flex items-center">
                        <Avatar image={"/images/avatar.jpg"} />
                        &nbsp; {getDataFromLocalStorage?.firstName || ""}{" "}
                        {getDataFromLocalStorage?.lastName || ""}
                      </h5>
                      <p className="font-normal text-gray-700 dark:text-gray-400">
                        <p> Email: {getDataFromLocalStorage?.email || ""}</p>
                        <p className="mt-2">
                          Click on continue for the creation of this employee
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="btn btn--border mr-2"
                    type="button"
                    onClick={discardpendingEmployeeCreation}
                  >
                    Discard
                  </button>
                  <button
                    className="btn mr-2"
                    type="button"
                    onClick={openModal}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-30 fixed inset-0 z-2 bg-black"></div>
        </>
      ) : null}
      <div
        className={`flex-1 justify-center max-w-[900px] text-[14px] ${
          showModal ? "hidden" : ""
        }`}
      >
        {/* Modal popup ends here */}

        <div className="mb-5">
          <h2 className="text-xl font-bold text-gray-800 mb-2">
            Basic Informations
          </h2>
          <p className="text-[14px] mb-6">
            Fill all employee personal basic information
          </p>
        </div>
        <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1">
          {errMsg}
        </span>
        <form onSubmit={handleSubmit(onSubmit)} className="min-h-screen">
          <div className="lg:flex gap-4">
            <div className="flex-1 text-[14px] mb-4">
              <label htmlFor="">Employee ID Series </label>
              <Controller
                control={control}
                name="serialKeyEntryType"
                render={({ field: { onChange, value } }) => (
                  <Select
                    defaultValue={entryOptions.find((i) => Number(i.value) === serialKeyEntryType)}
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => {
                      onChange(e.value);
                      setSerialKeyEntryType(e.value);
                    }}
                    options={entryOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                )}
              />
            </div>
            <div className="flex-1 text-[14px] mb-4 relative">
              <label htmlFor="">
                ID{" "}
                {serialKeyEntryType === MANUAL_ENTRY && (
                  <span className="text-red-600 font-medium">*</span>
                )}
              </label>
              <input
                className="myForm"
                type="number"
                disabled={serialKeyEntryType === AUTOMATED_ENTRY}
                {...register("serial_id", {
                  required:
                    serialKeyEntryType === MANUAL_ENTRY
                      ? "This field is required"
                      : false,
                })}
              />
              {errors?.serial_id && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.serial_id.message}
                </span>
              )}
              {serialKeyEntryType === MANUAL_ENTRY && (
                <div className="pt-2">
                  <button
                    type="button"
                    className="text-blue-800 "
                    onClick={checkSerialKeyAvailablity}
                  >
                    check availability{" "}
                    {isSerialKeyAvailable.checked && (
                      <span
                        className={`rounded-3xl  p-1 ${
                          isSerialKeyAvailable.available
                            ? "bg-green-100 text-green-800"
                            : "text-red-800 bg-red-100"
                        }`}
                      >
                        {isSerialKeyAvailable.available
                          ? "Available"
                          : "Not available"}
                      </span>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="lg:flex gap-4 text-[14px]">
            <div className="flex-1 mb-4">
              <label htmlFor="">
                First Name <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                className="myForm"
                type="text"
                {...register("firstName", {
                  required: "This field is required",
                  pattern: {
                    value: /^[a-zA-Z]+$/,
                    message: "Numbers and Special Characters are not allowed!",
                  },
                })}
              />
              {errors?.firstName && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.firstName.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Middle Name <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                className="myForm"
                type="text"
                {...register("middleName", {
                  required: "This field is required",
                  pattern: {
                    value: /^[a-zA-Z]+$/,
                    message: "Numbers and Special Characters are not allowed!",
                  },
                })}
              />
              {errors?.middleName && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.middleName.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Last Name <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                className="myForm"
                type="text"
                {...register("lastName", {
                  required: "This field is required",
                  pattern: {
                    value: /^[a-zA-Z]+$/,
                    message: "Numbers and Special Characters are not allowed!",
                  },
                })}
              />
              {errors?.lastName && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.lastName.message}
                </span>
              )}
            </div>
          </div>

          <div className="lg:flex gap-4 text-[14px]">
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Email Address{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                className="myForm"
                type="text"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                })}
              />
              {errors?.email && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.email.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Office Location{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>

              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="office_location"
                
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      locationList.find((i) => Number(i?.id) === Number(value))
                        ?.location_name
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.id)}
                    options={locationList}
                    getOptionLabel={(option) => option.location_name}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />
              {errors?.office_location && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.office_location.message}
                </span>
              )}

              <span
                id="office_location"
                className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
              ></span>
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Source of Career information{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="source"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      sourceFrom.find((i) => Number(i?.value) === Number(value))
                        ?.label
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.value)}
                    options={sourceFrom}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                )}
              />
              {errors?.source && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.source.message}
                </span>
              )}
            </div>
          </div>
          <div className="lg:flex gap-4 text-[14px]">
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Department<span className="text-red-600 font-medium">*</span>{" "}
              </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="department"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      departmentList.find(
                        (i) => Number(i?.id) === Number(value)
                      )?.department_name
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.id)}
                    options={departmentList}
                    getOptionLabel={(option) => option.department_name}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />
              {errors?.department && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.department.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Job Title <span className="text-red-600 font-medium">*</span>
              </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="job_title"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      jobTitleList.find((i) => Number(i?.id) === Number(value))
                        ?.title
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.id)}
                    options={jobTitleList}
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />
              {errors?.job_title && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.job_title.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Employement Type{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="employementType"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      employeeType.find((i) => Number(i?.id) === Number(value))
                        ?.type_label
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.id)}
                    options={employeeType}
                    getOptionLabel={(option) => option.type_label}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />
              {errors?.employementType && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.employementType.message}
                </span>
              )}
            </div>
          </div>
          <div className="lg:flex gap-4 text-[14px]">
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Designation <span className="text-red-600 font-medium">*</span>
              </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="designation"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      designationList.find(
                        (i) => Number(i?.id) === Number(value)
                      )?.designation_title
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.id)}
                    options={designationList}
                    getOptionLabel={(option) => option.designation_title}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />
              {errors?.designation && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.designation.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Employee Level{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="empLevel"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      employeeLevelOptions.find(
                        (i) => Number(i?.value) === Number(value)
                      )?.label
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.value)}
                    options={employeeLevelOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                )}
              />
              {errors?.empLevel && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.empLevel.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4 mt-5">
              <label className="text-sm leading-5 font-medium text-gray-800 inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  {...register("portal_access")}
                  className="mr-2"
                />
                Enable Portal Access{" "}
              </label>
              <p className="mt-1 mb-10 ">
                The employee will be able to view
                {textLoadMore ? (
                  "payslips, submit their IT declaration and create reimbursement claims through the employee portal."
                ) : (
                  <button
                    className="hover:underline text-xl text-blue-600"
                    onClick={() => setTextLoadMore(!textLoadMore)}
                  >
                    ...
                  </button>
                )}
              </p>
            </div>
          </div>
          <Line />

          <div className="flex justify-end pt-6">
            <Link
              to="/employee/manage-employee/employee-list"
              className="btn btn--border ml-3 text-[14px]"
            >
              Discard
            </Link>
            <button type="submit" className="btn ml-3 text-[14px]">
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEmployeeBasic;
