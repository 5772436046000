import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ToggleSwitch from "../../ToggleSwitch/ToggleSwitch";
import { useForm, Controller } from "react-hook-form";
import Line from "../../Line";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import Select, { components } from "react-select";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down"></span>
    </components.DropdownIndicator>
  );
};

const AUTOMATED_ENTRY = 2;
const MANUAL_ENTRY = 1;
const OTHER_ENTRY = "Other";

const entryOptions = [
  { value: AUTOMATED_ENTRY, label: "Automated Entry" },
  { value: MANUAL_ENTRY, label: "Manual Entry" },
  { value: OTHER_ENTRY, label: "Other" },
];

const AddNewEmployeeModal = ({ open, close, fetchEmployeeData }) => {
  const axiosPrivate = useAxiosPrivate();
  const [errMsg, setErrMsg] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });
  const [serialKeyEntryType, setSerialKeyEntryType] = useState(AUTOMATED_ENTRY);
  const [serialList, setSerialList] = useState([]);
  const [isSerialKeyAvailable, setIsSerialKeyAvailable] = useState({
    available: false,
    checked: false,
  });

  const checkSerialKeyAvailablity = async () => {
    setErrMsg("");
    const value = getValues("serial_number");
    if (!value) {
      return toast.info("Enter a serial number");
    }
    const serial = serialList.find((i) => i.type === "employee");
    const serialKey =
      serialKeyEntryType === OTHER_ENTRY
        ? value
        : `${serial.prefix}-${value}-${serial.suffix}`;
    const response = await axiosPrivate
      .get(`employee/search?pageSize=1000&employee_serial=${serialKey}`)
      .then((res) => {
        if (Number(res.data.statusCode) === 200) {
          setIsSerialKeyAvailable({
            available: false,
            checked: true,
          });
          // setErrMsg("Serial key is already taken");
          return false;
        } else {
          setIsSerialKeyAvailable({
            available: true,
            checked: true,
          });
          return true;
        }
      })
      .catch((err) => {
        setIsSerialKeyAvailable({
          available: true,
          checked: true,
        });
        return true;
      });

    return response;
  };

  const createEmloyee = async (data) => {
    await axiosPrivate
      .post("employee/create", {
        ...data,
        role_id: 6,
        status: 1,
        customSerialEntry: serialKeyEntryType === OTHER_ENTRY,
      })
      .then((res) => {
        if (res?.data?.status) {
          toast.success("employee created successfully");
          fetchEmployeeData();
          close();
        } else {
          setErrMsg(res?.data?.message || "Employee creation failed");
        }
      })
      .catch((err) => {
        if (typeof err?.response?.data?.error === "string") {
          setErrMsg(err?.response?.data?.error || "Employee creation failed");
        } else {
          setErrMsg("Employee creation failed");
        }
      });
  };

  const onSubmit = async (data) => {
   
    data.first_name = data.first_name.trim();
    data.middle_name = data.middle_name.trim();
    data.last_name = data.last_name.trim();
    data.email = data.email.trim();
  
    setErrMsg("");
    if (
      serialKeyEntryType === MANUAL_ENTRY ||
      serialKeyEntryType === OTHER_ENTRY
    ) {
      const isSerialKeyAvailable = await checkSerialKeyAvailablity();
      if (isSerialKeyAvailable) {
        return createEmloyee(data);
      }
    } else {
      return createEmloyee(data);
    }
  };
  

  const fetchTenantSerialTypes = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getTenantSerialList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setSerialList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchTenantSerialTypes();
  }, []);

  return (
    <Modal open={open} onClose={close} center>
      <div className="min-w-[500px]">
        <div>
          <h2 className="text-lg leading-6 text-[#1F2937] font-medium mb-5">
            Add new employee
          </h2>
        </div>
        <div className="mb-5">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Basic Informations
          </h2>
          <p className="text-[14px] mb-6">Fill employee basic information</p>
        </div>
        <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1">
          {errMsg}
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="lg:flex gap-4">
            <div className="flex-1 text-[14px] mb-4">
              <label htmlFor="">Employee ID Series </label>
              <Controller
                control={control}
                name="serialKeyEntryType"
                render={({ field: { onChange, value } }) => (
                  <Select
                    defaultValue={entryOptions.find(
                      (i) => Number(i.value) === serialKeyEntryType
                    )}
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => {
                      onChange(e.value);
                      setSerialKeyEntryType(e.value);
                    }}
                    options={entryOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                )}
              />
            </div>
            <div className="flex-1 text-[14px] mb-4 relative">
              <label htmlFor="">
                ID{" "}
                {(serialKeyEntryType === MANUAL_ENTRY ||
                  serialKeyEntryType === OTHER_ENTRY) && (
                  <span className="text-red-600 font-medium">*</span>
                )}
              </label>
              <input
                className="myForm"
                type={serialKeyEntryType === MANUAL_ENTRY ? "number" : "text"}
                disabled={serialKeyEntryType === AUTOMATED_ENTRY}
                {...register("serial_number", {
                  required:
                    serialKeyEntryType === MANUAL_ENTRY ||
                    serialKeyEntryType === OTHER_ENTRY
                      ? "This field is required"
                      : false,
                  validate: (val) => {
                    return serialKeyEntryType === MANUAL_ENTRY
                      ? !/^-\d+(\.\d+)?$/.test(val) ||
                          "Negative numbers are not allowed"
                      : true;
                  },
                  onChange: () =>
                    setIsSerialKeyAvailable({
                      available: false,
                      checked: false,
                    }),
                })}
              />
              {errors?.serial_number && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.serial_number.message}
                </span>
              )}
              {(serialKeyEntryType === MANUAL_ENTRY ||
                serialKeyEntryType === OTHER_ENTRY) && (
                <div className="pt-2">
                  <button
                    type="button"
                    className="text-blue-800 "
                    onClick={checkSerialKeyAvailablity}
                  >
                    Click here to check availability{" "}
                    {isSerialKeyAvailable.checked && (
                      <span
                        className={`rounded-3xl p-1 ml-2 ${
                          isSerialKeyAvailable.available
                            ? "bg-green-100 text-green-800"
                            : "text-red-800 bg-red-100"
                        }`}
                      >
                        {isSerialKeyAvailable.available
                          ? "Available"
                          : "Not available"}
                      </span>
                    )}
                  </button>
                  {!isSerialKeyAvailable.available &&
                    isSerialKeyAvailable.checked && (
                      <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                        Serial key is already taken
                      </span>
                    )}
                </div>
              )}
            </div>
          </div>

          <div className="lg:flex gap-4 text-[14px]">
            <div className="flex-1 mb-4">
              <label htmlFor="">
                First Name <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                className="myForm"
                type="text"
                {...register("first_name", {
                  required: "This field is required",
                  pattern: {
                    value: /^[a-zA-Z]+$/,
                    message: "Numbers and Special Characters are not allowed!",
                  },
                  maxLength: {
                    value: 50,
                    message: "Name should not exceed 50 characters!",
                  }
                })}
              />
              {errors?.first_name && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.first_name.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">Middle Name</label>
              <input
                className="myForm"
                type="text"
                {...register("middle_name", {
                  pattern: {
                    value: /^[a-zA-Z]+$/,
                    message: "Numbers and Special Characters are not allowed!",
                  },
                  maxLength: {
                    value: 50,
                    message: "Name should not exceed 50 characters!",
                  }
                })}
              />
              {errors?.middle_name && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.middle_name.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Last Name <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                className="myForm"
                type="text"
                {...register("last_name", {
                  required: "This field is required",
                  pattern: {
                    value: /^[a-zA-Z]+$/,
                    message: "Numbers and Special Characters are not allowed!",
                  },
                  maxLength: {
                    value: 50,
                    message: "Name should not exceed 50 characters!",
                  }
                })}
              />
              {errors?.last_name && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.last_name.message}
                </span>
              )}
            </div>
          </div>

          <div className="lg:flex gap-4 text-[14px]">
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Email Address{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                className="myForm"
                type="text"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                })}
              />
              {errors?.email && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.email.message}
                </span>
              )}
            </div>
            {/* <div className="flex-1 mb-4">
              <label htmlFor="">
                Office Location{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>

              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="office_location"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      locationList.find((i) => Number(i?.id) === Number(value))
                        ?.location_name
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.id)}
                    options={locationList}
                    getOptionLabel={(option) => option.location_name}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />
              {errors?.office_location && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.office_location.message}
                </span>
              )}

              <span
                id="office_location"
                className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
              ></span>
            </div> */}
            {/* <div className="flex-1 mb-4">
              <label htmlFor="">
                Source of Career information{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="source"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      sourceFrom.find((i) => Number(i?.value) === Number(value))
                        ?.label
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.value)}
                    options={sourceFrom}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                )}
              />
              {errors?.source && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.source.message}
                </span>
              )}
            </div> */}
          </div>
          {/* <div className="lg:flex gap-4 text-[14px]">
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Department<span className="text-red-600 font-medium">*</span>{" "}
              </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="department"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      departmentList.find(
                        (i) => Number(i?.id) === Number(value)
                      )?.department_name
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.id)}
                    options={departmentList}
                    getOptionLabel={(option) => option.department_name}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />
              {errors?.department && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.department.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Job Title <span className="text-red-600 font-medium">*</span>
              </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="job_title"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      jobTitleList.find((i) => Number(i?.id) === Number(value))
                        ?.title
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.id)}
                    options={jobTitleList}
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />
              {errors?.job_title && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.job_title.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Employement Type{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="employementType"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      employeeType.find((i) => Number(i?.id) === Number(value))
                        ?.type_label
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.id)}
                    options={employeeType}
                    getOptionLabel={(option) => option.type_label}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />
              {errors?.employementType && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.employementType.message}
                </span>
              )}
            </div>
          </div> */}
          {/* <div className="lg:flex gap-4 text-[14px]">
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Designation <span className="text-red-600 font-medium">*</span>
              </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="designation"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      designationList.find(
                        (i) => Number(i?.id) === Number(value)
                      )?.designation_title
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.id)}
                    options={designationList}
                    getOptionLabel={(option) => option.designation_title}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />
              {errors?.designation && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.designation.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Employee Level{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="empLevel"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      employeeLevelOptions.find(
                        (i) => Number(i?.value) === Number(value)
                      )?.label
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.value)}
                    options={employeeLevelOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                )}
              />
              {errors?.empLevel && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.empLevel.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4 mt-5">
              <label className="text-sm leading-5 font-medium text-gray-800 inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  {...register("portal_access")}
                  className="mr-2"
                />
                Enable Portal Access{" "}
              </label>
              <p className="mt-1 mb-10 ">
                The employee will be able to view
                {textLoadMore ? (
                  "payslips, submit their IT declaration and create reimbursement claims through the employee portal."
                ) : (
                  <button
                    className="hover:underline text-xl text-blue-600"
                    onClick={() => setTextLoadMore(!textLoadMore)}
                  >
                    ...
                  </button>
                )}
              </p>
            </div>
          </div> */}
          <Line />

          <div className="flex justify-end pt-6">
            <button type="button" onClick={close} className="btn btn--border">
              Discard
            </button>
            <button type="submit" className="btn ml-3 text-[14px]">
              Create Employee
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddNewEmployeeModal;
