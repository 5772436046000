import React, { useEffect, useState } from "react";
import "./Menu.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const menuitems = [
  {
    name: "Home",
    icon: "home",
    link: "/dashboard",
    current: false,
    roles: [1, 2, 4, 6],
  },
  // {
  //   name: "Feeds",
  //   icon: "bulb",
  //   link: "#",
  //   current: false,
  //   roles: [1, 6, 2, 4],
  // },
  // {
  //   name: "Task",
  //   icon: "notes",
  //   link: "/task/approval/leave-applications",
  //   current: false,
  //   roles: [1, 4, 2, 6],
  // },
  // {
  //   name: "Organization",
  //   icon: "office",
  //   link: "/organization/locations",
  //   current: false,
  //   roles: [1, 2],
  // },
  {
    name: "Employee",
    icon: "people",
    link: "/employee/manage-employee/employee-list",
    current: false,
    roles: [1, 2, 4],
  },
  {
    name: "Candidate",
    icon: "people",
    link: "/candidate/candidate-list",
    current: false,
    roles: [1, 2],
  },
  {
    name: "Attendance",
    icon: "calendar",
    link: "/attendance/self-service/my-attendance",
    current: false,
    roles: [1, 2],
  },
  // {
  //   name: "Performance",
  //   icon: "star",
  //   link: "#",
  //   current: false,
  //   roles: [1],
  // },
  // {
  //   name: "Payroll",
  //   icon: "card",
  //   link: "/payroll/my-payroll/payslip",
  //   current: false,
  //   roles: [1, 2],
  // },
  {
    name: "Leaves",
    icon: "clock1",
    link: "/leave/my-leave",
    current: false,
    roles: [1, 4, 6, 2],
  },
  // {
  //   name: "Salary",
  //   icon: "card",
  //   link: "#",
  //   current: false,
  //   roles: [1, 4, 6, 2],
  // },
  // {
  //   name: "Payroll",
  //   icon: "card",
  //   link: "#",
  //   current: false,
  //   roles: [1],
  // },
  // {
  //   name: "Files",
  //   icon: "folder",
  //   link: "#",
  //   current: false,
  //   roles: [1, 4, 6, 2],
  // },
  // {
  //   name: "Assets",
  //   icon: "assets",
  //   link: "/assets/my-assets",
  //   current: false,
  //   roles: [1, 6, 4, 2],
  // },
  // {
  //   name: "Policy",
  //   icon: "notes",
  //   link: "#",
  //   current: false,
  //   roles: [1],
  // },
  // {
  //   name: "Report",
  //   icon: "printer",
  //   link: "#",
  //   current: false,
  //   roles: [1],
  // },
  {
    name: "Settings",
    icon: "settings",
    link: "/settings/widget-list",
    current: false,
    roles: [1, 2],
  },
];

const Menu = () => {
  const auth = useSelector((state) => state.user.auth);
  const roles = auth.roles;
  const [currentTab, setCurrentTab] = useState(window.location.pathname);

  useEffect(() => {
    setCurrentTab(window.location.pathname);
  }, [window.location.pathname]);

  const handleClick = (item) => {
    if (item.link !== "#") {
      setCurrentTab(item.link);
    }
  };

  return (
    <div className="bg-blue-800 menu flex flex-col text-[#789BF6] border-r border-gray-200 px-4 py-6">
      <div className="rounded-md text-center">
        {/* <div className="py-4">
          <img src="/images/ivslogo.png" alt="" className="m-auto w-12" />
        </div> */}
        {menuitems.map((item, index) => {
          const isSelectedTab = currentTab.split("/")[1] === item.link.split("/")[1]
          return item.roles.find((role) => roles.includes(role)) ? (
            <div key={index}>
              <Link to={item.link} onClick={() => handleClick(item)}>
                <div
                  className={`py-4 rounded-md ${
                    isSelectedTab ? "current" : "hover:text-white"
                  } cursor-pointer`}
                  key={index}
                >
                  <div>
                    <i className={`ico-${item.icon} text-xl`}></i>
                  </div>
                  <h3 className="text-xs">{item.name}</h3>
                </div>
              </Link>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

export default Menu;
