import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import BigCalendar from "../../../../components/BigCalendar/BigCalendar";
import moment from "moment";
import PageLoader from "../../../../components/PageLoader";
import EmptyState from "../../../../components/EmptyState";
import {
  getShift,
  formatDayDate,
} from "../../../../utils/Attendance/shiftUtils";
import formatDate from "../../../../utils/FormatDate/formatDate";
import { useSelector } from "react-redux";

const MyShifts = () => {
  const user = useSelector((state) => state.user);
  const employeeId = user?.userProfile?.id;
  const axiosPrivate = useAxiosPrivate();
  const [employeeShiftList, setEmployeeShiftList] = useState([]);
  const [shiftList, setShiftList] = useState(null);
  // const [loaderComponent, setLoaderComponent] = useState(<PageLoader />);
  const [datesCol, setDatesCol] = useState([]);

  const currentDate = moment();
  const startOfMonth = moment().startOf("month");
  const endOfMonth = moment().endOf("month");
  const startDate = startOfMonth
    .clone()
    .subtract(7, "days")
    .format("YYYY-MM-DD");
  const endDate = endOfMonth.clone().add(7, "days").format("YYYY-MM-DD");

  const [dateRange, setDateRange] = useState({
    startDate,
    endDate,
  });

  const [calendarData, setCalendarData] = useState([]);

  const fetchEmployeeShiftList = async () => {
    if (!employeeId) {
      return;
    }
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(
        `attendance/getEmployeeShiftList?fromDate=${dateRange.startDate}&toDate=${dateRange.endDate}&employeeId=${employeeId}`
      )
      .then((response) => {
        const res = response?.data;
        if (res.result.length) {
          setEmployeeShiftList(res.result[0].shiftDetails);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchShiftList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("attendance/getShiftList", {
        signal: controller.signal,
      })
      .then((res) => {
        const { data } = res;
        if (data.status) {
          setShiftList(data.result);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    const daysInMonth =
      moment(dateRange.endDate).diff(moment(dateRange.startDate), "days") + 1;
    let datesArr = [];
    for (let i = 0; i < daysInMonth; i++) {
      const date = moment(dateRange.startDate)
        .add(i, "day")
        .format("YYYY-MM-DD");
      datesArr.push(date);
    }
    setDatesCol(datesArr);
  }, [dateRange]);

  const handleChangeDate = (e) => {
    const { start, end } = e;
    setDateRange({
      startDate: moment(start).format("YYYY-MM-DD"),
      endDate: moment(end).format("YYYY-MM-DD"),
    });
  };

  useEffect(() => {
    fetchShiftList();
  }, []);

  useEffect(() => {
    fetchEmployeeShiftList();
  }, [dateRange]);
  useEffect(() => {
    const formatData = [];
    datesCol.forEach((date) => {
      const shift =
        Array.isArray(employeeShiftList) &&
        employeeShiftList.find(
          (i) => moment(i.date).format("YYYY-MM-DD") === date
        );
      if (shift) {
        const shiftName = shift?.shift_name || "";
        const color = shift?.color_code || "";
        const isWorkingDay = shift?.isWorkingDay;
        if (isWorkingDay) {
          const shiftTimeFrom = formatDate(
            `${date}T${shift?.time_from}`
          )?.localString2;
          const shiftTimeTo = formatDate(
            `${date}T${shift?.time_to}`
          )?.localString2;
          formatData.push({
            shiftName,
            color,
            title: (
              <div>
                <p>{shiftName}</p>
                <p className="text-xs">
                  {shiftTimeFrom} to {shiftTimeTo}
                </p>
              </div>
            ),
            start: new Date(`${date}T${shift?.time_from}`),
            end: new Date(`${date}T${shift?.time_to}`),
            titleColor: "",
            backGroundColor: color,
            borderColor: color,
          });
        }
      }
    });
    setCalendarData(formatData);
  }, [employeeShiftList, datesCol]);

  return (
    <>
      <div className="px-10 w-full">
        <div className="flex justify-between items-center">
          <div className="py-5 flex flex-col gap-2">
            <h1 className="text-black font-medium text-lg">My Shifts</h1>
          </div>
          <button className="btn btn--border">
            <i className="ico-download mr-2"></i>Download
          </button>
        </div>
        <div id="calendar-div">
          <BigCalendar
            myEventsList={calendarData}
            handleChangeDate={handleChangeDate}
          />
        </div>
      </div>
    </>
  );
};

export default MyShifts;
