import React, { useState, useEffect } from "react";
import Select from "react-select";
import { DropdownIndicator } from "../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import ShiftCalenderTable from "../../../components/Attendance/ManageShift/ShiftCalendar/ShiftCalenderTable";
import MiniShiftOverviewBar from "../../../components/Attendance/ManageShift/ShiftCalendar/MiniShiftOverviewBar";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import formatDate from "../../../utils/FormatDate/formatDate";
import moment from "moment";
import EmptyState from "../../../components/EmptyState";
import PageLoader from "../../../components/PageLoader";

const ShiftCalendar = () => {
  const axiosPrivate = useAxiosPrivate();
  const [firstDate, setFirstDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [lastDate, setLastDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [employeeShiftList, setEmployeeShiftList] = useState([]);
  const [shiftList, setShiftList] = useState(null);

  const [departments, setDepartmentList] = useState([]);
  const [locations, setLocationList] = useState([]);
  const [designations, setDesignationList] = useState([]);

  const [department, setSelectedDepartment] = useState(null);
  const [location, setSelectedLocation] = useState(null);
  const [designation, setSelectedDesignation] = useState(null);
  const [employeeShiftListUrl, setEmployeeShiftListUrl] = useState(
    `attendance/getEmployeeShiftList?fromDate=${firstDate}&toDate=${lastDate}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
  const [loaderComponent, setLoaderComponent] = useState(<PageLoader />);

  const isFilterApplied =
    department ||
    location ||
    designation ||
    firstDate !== moment().startOf("month").format("YYYY-MM-DD");

  const formatedFirstDay = formatDate(firstDate).dateString2;
  const formatedLastDay = formatDate(lastDate).dateString2;

  const daysInMonth = moment(lastDate).diff(moment(firstDate), "days") + 1;

  const [datesCol, setDatesCol] = useState([]);

  const clearAllFilter = () => {
    setSelectedDepartment(null);
    setSelectedLocation(null);
    setSelectedDesignation(null);
    setFirstDate(moment().startOf("month").format("YYYY-MM-DD"));
    setLastDate(moment().endOf("month").format("YYYY-MM-DD"));
    let baseUrl = `attendance/getEmployeeShiftList?fromDate=${firstDate}&toDate=${lastDate}&pageNo=${pageNo}&pageSize=${pageSize}`;
    setEmployeeShiftListUrl(baseUrl);
  };
  useEffect(() => {
    let datesArr = [];
    for (let i = 0; i < daysInMonth; i++) {
      const date = moment(firstDate).add(i, "day").format("YYYY-MM-DD");
      datesArr.push(date);
    }
    setDatesCol(datesArr);
  }, [firstDate, lastDate]);

  const handleChangeDate = (e) => {
    if (e === "nextMonth") {
      const nextMonth = moment(firstDate).add(1, "month").format("YYYY-MM-DD");
      setFirstDate(nextMonth);
      setLastDate(moment(nextMonth).endOf("month").format("YYYY-MM-DD"));
    } else {
      const prevMonth = moment(firstDate)
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      setFirstDate(prevMonth);
      setLastDate(moment(prevMonth).endOf("month").format("YYYY-MM-DD"));
    }
  };
  const fetchDepartmentList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`employee/getDepartmentList`)
      .then((response) => {
        const res = response?.data;
        const departmentList = res.result.map((department, index) => {
          return {
            label: department.department_name,
            value: department.id,
            key: index,
          };
        });
        setDepartmentList(departmentList);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const fetchLocationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`employee/getLocationList`)
      .then((response) => {
        const res = response?.data;
        const locationList = res.result.map((location, index) => {
          return {
            label: location.location_name,
            value: location.id,
            key: index,
          };
        });
        setLocationList(locationList);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const fetchDesignationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`employee/getDesignationList`)
      .then((response) => {
        const res = response?.data;
        const designationList = res.result.map((designation, index) => {
          return {
            label: designation.designation_title,
            value: designation.id,
            key: index,
          };
        });
        setDesignationList(designationList);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchEmployeeShiftList = async () => {
    setLoaderComponent(<PageLoader />);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(employeeShiftListUrl)
      .then((response) => {
        const res = response?.data;
        if (res.result.length) {
          setTotalRecords(Number(res?.totalRecords || 0))
          setEmployeeShiftList(res.result);
          setLoaderComponent(null);
        } else {
          setLoaderComponent(<EmptyState />);
        }
      })
      .catch((err) => {
        setLoaderComponent(<EmptyState />);
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchShiftList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("attendance/getShiftList", {
        signal: controller.signal,
      })
      .then((res) => {
        const { data } = res;
        if (data.status) {
          setShiftList(data.result);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchShiftList();
    fetchDepartmentList();
    fetchLocationList();
    fetchDesignationList();
  }, []);

  useEffect(() => {
    let baseUrl = `attendance/getEmployeeShiftList?fromDate=${firstDate}&toDate=${lastDate}&pageNo=${pageNo}&pageSize=${pageSize}`;
    if (department) {
      baseUrl = baseUrl + `&department_id=${department}`;
    }
    if (location) {
      baseUrl = baseUrl + `&location_id=${location}`;
    }
    if (designation) {
      baseUrl = baseUrl + `&designation_id=${designation}`;
    }
    setEmployeeShiftListUrl(baseUrl);
  }, [firstDate, department, designation, location, pageNo, pageSize]);

  useEffect(() => {
    fetchEmployeeShiftList();
  }, [employeeShiftListUrl]);

  const screenWidth = window.innerWidth - 326; //362 is the width of aside bar and side bar

  const totalPages = Math.ceil(totalRecords / pageSize) || 1;

  const nextPage = () => {
    if (pageNo < totalPages) {
      setPageNo(pageNo + 1);
    }
  };

  const previousPage = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };
  return (
    <div className={`flex flex-col relative`} style={{ width: screenWidth }}>
      <div className="p-10 flex flex-col gap-5">
        <div>
          <h1 className="text-black font-semibold text-base">Shift Calendar</h1>
          <p>Manage and view shifts</p>
        </div>
        <div className="flex justify-between">
          <div className="flex gap-3">
            <Select
              placeholder="Department"
              options={departments}
              components={{ DropdownIndicator }}
              onChange={(e) => {
                setSelectedDepartment(e.value);
              }}
            />
            <Select
              placeholder="Designation"
              options={designations}
              components={{ DropdownIndicator }}
              onChange={(e) => {
                setSelectedDesignation(e.value);
              }}
            />
            <Select
              placeholder="Location"
              options={locations}
              components={{ DropdownIndicator }}
              onChange={(e) => {
                setSelectedLocation(e.value);
              }}
            />
            {isFilterApplied ? (
              <p
                className="text-blue-600 font-medium pt-2 cursor-pointer"
                onClick={clearAllFilter}
              >
                Clear filter
              </p>
            ) : null}
          </div>
          <div className="flex gap-4">
            <div className="flex gap-3 items-center">
              <button onClick={() => handleChangeDate("prevMonth")}>
                <i className="ico-left"></i>
              </button>
              <p className="text-black font-medium">
                {formatedFirstDay} - {formatedLastDay}
              </p>
              <button onClick={() => handleChangeDate("nextMonth")}>
                <i className="ico-right"></i>
              </button>
            </div>
            <div>
              <button className="btn btn--border">
                <i className="ico-download"></i>
                Download
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-2 items-center">
          <button className="btn btn--border" onClick={previousPage}>Previous</button>
          <span className="text-gray-800 font-medium">{pageNo} / {totalPages} pages</span>
          <button className="btn btn--border" onClick={nextPage}>Next</button>
        </div>
      </div>
      <div>
        {loaderComponent ? (
          loaderComponent
        ) : employeeShiftList.length ? (
          <ShiftCalenderTable data={employeeShiftList} datesCol={datesCol} />
        ) : null}
      </div>
      <div className="fixed bottom-0 w-full mt-5 bg-white">
        <MiniShiftOverviewBar shiftList={shiftList} />
      </div>
    </div>
  );
};

export default ShiftCalendar;
