import React, { useEffect, useState } from "react";
import LeaveRequestData from "../LeaveRequestData/LeaveRequestData";

const ADDITIONAL_LEAVE_TAB = 2;

const LeaveRequestList = ({
  leaveData,
  hr,
  manager,
  makeApiCall,
  toggleState,
}) => {
  const isAdditionalLeaveTab = toggleState === ADDITIONAL_LEAVE_TAB;
  const tableCols = [
    { colName: "ID", icoDown: true, action: null, status: true },
    { colName: "Leave Type", icoDown: true, action: null, status: true },
    {
      colName: "From",
      icoDown: true,
      action: null,
      status: !isAdditionalLeaveTab,
    },
    {
      colName: "To",
      icoDown: true,
      action: null,
      status: !isAdditionalLeaveTab,
    },
    {
      colName: "Total",
      icoDown: true,
      action: null,
      status: !isAdditionalLeaveTab,
    },
    {
      colName: "Leave Date",
      icoDown: true,
      action: null,
      status: isAdditionalLeaveTab,
    },
    { colName: "Status", icoDown: true, action: null, status: true },
    {
      colName: " ",
      icoDown: false,
      action: null,
      status: !isAdditionalLeaveTab,
    },
  ];
  const [col, setCol] = useState(tableCols);

  useEffect(() => {
    setCol(tableCols);
  }, [toggleState]);

  return (
    <div className="mx-10 border rounded-lg overflow-auto">
      <table className="">
        <thead>
          <tr className="px-5">
            {Array.isArray(col)
              ? col.map((item, index) => {
                  if (item.status) {
                    return (
                      <td
                        className="items-center"
                        key={`leave_req_list_col_${index}`}
                      >
                        <span>{item.colName}</span>
                        {item.icoDown ? (
                          <i className="ico-down pl-2 text-blue-600"></i>
                        ) : null}
                      </td>
                    );
                  }
                })
              : null}
          </tr>
        </thead>
        <tbody>
          {leaveData &&
            leaveData.map((leave, index) => (
              <LeaveRequestData
                leave={leave}
                hr={hr}
                manager={manager}
                key={`leave_rew_data_${index}`}
                makeApiCall={makeApiCall}
                isAdditionalLeaveTab={isAdditionalLeaveTab}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeaveRequestList;
