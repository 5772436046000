import React, { useState, useEffect } from "react";
import ReactSlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Line from "../../../Line/Line";
import { closeIcon } from "../../../../Icons/Icons";
import Select, { components } from "react-select";
import { useForm, Controller } from "react-hook-form";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { holidayTypeOptions } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import { toast } from "react-toastify";

const AddNewHoliday = ({
  open,
  close,
  handleMakeApiCallToFetchHolidayList,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <span className="ico-down text-blue-600"></span>
      </components.DropdownIndicator>
    );
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      year_id: 1,
    },
  });

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [countryList, setCountry] = useState([]);
  const [stateList, setState] = useState([]);

  const fetchCountry = async () => {
    await axiosPrivate.get("onboarding/services/location").then((response) => {
      setCountry(response.data.country);
    });
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  // GET STATE API STARTS
  const fetchState = async () => {
    if (selectedCountry) {
      let isMounted = true;
      const controller = new AbortController();

      await axiosPrivate
        .get(`onboarding/services/location?StatesFrom=${selectedCountry}`, {
          signal: controller.signal,
        })
        .then((response) => {
          // isMounted && console.log(response.data);
          setState(response.data.state);
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        isMounted = false;
        controller.abort();
      };
    }
  };

  useEffect(() => {
    fetchState();
  }, [selectedCountry]);

  // GET STATE API ENDS

  const reqObj = {
    holiday_name: "Third Onam",
    date: "2024-09-16",
    holiday_type: 1,
    country_code: 101,
    state_code: 1,
    year_id: 1,
  };

  const onSubmit = async (data) => {
    const result = await axiosPrivate
      .post("lms/holiday/addHoliday", data)
      .then((res) => res)
      .catch((err) => err);
    if (result?.data?.status) {
      toast.success(result.data.message);
      handleMakeApiCallToFetchHolidayList();
      close();
    } else {
      toast.error(
        result?.response?.data?.error || "Failed to add holiday details"
      );
    }
  };
  return (
    <ReactSlidingPane className="add-new-holiday-modal" isOpen={open} width="480px" onRequestClose={close}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="text-black flex flex-col items h-full justify-between"
        >
          <div>
            <div className="px-6 pb-3 flex justify-between items-center">
              <p className=" font-semibold text-base">Add Holiday</p>
              <span className="cursor-pointer" onClick={close}>{closeIcon}</span>
            </div>
            <Line />

            <div className="px-6 flex flex-col justify-between">
              <div>
                <div className="mb-4">
                  <label className="">
                    Name<span className="text-red-700">*</span>
                  </label>
                  <input
                    {...register("holiday_name", {
                      required: "This field is required",
                    })}
                    type="text"
                    className="mt-1"
                  />
                  {errors?.holiday_name && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {errors.holiday_name.message}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label>Date</label>
                  <input
                    {...register("date", {
                      required: "This field is required",
                    })}
                    type="date"
                  />
                  {errors?.date && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {errors.date.message}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label>Holiday Type</label>
                  <Controller
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    name="holiday_type"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        components={{ DropdownIndicator }}
                        className="custom-select-container"
                        onChange={(e) => {
                          onChange(Number(e.value));
                        }}
                        options={holidayTypeOptions}
                      />
                    )}
                  />
                  {errors?.holiday_type && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {errors.holiday_type.message}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label>Country</label>
                  <Controller
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    name="country_code"
                    render={({ field: { onChange } }) => (
                      <Select
                        components={{ DropdownIndicator }}
                        className="custom-select-container"
                        onChange={(e) => {
                          onChange(Number(e.id));
                          setSelectedCountry(e.id);
                        }}
                        options={countryList}
                        getOptionLabel={(option) => option.flag}
                        getOptionValue={(option) => option.id}
                      />
                    )}
                  />
                  {errors?.country_code && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {errors.country_code.message}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label>State</label>
                  <Controller
                    // rules={{
                    //   required: "This field is required",
                    // }}
                    control={control}
                    name="state_code"
                    render={({ field: { onChange } }) => (
                      <Select
                        components={{ DropdownIndicator }}
                        className="custom-select-container"
                        onChange={(e) => {
                          onChange(Number(e.id));
                        }}
                        options={stateList}
                        getOptionLabel={(option) => option.state_name}
                        getOptionValue={(option) => option.id}
                      />
                    )}
                  />
                  {errors?.state_code && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {errors.state_code.message}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label>Year</label>
                  <Controller
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    name="year_id"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        components={{ DropdownIndicator }}
                        className="custom-select-container"
                        onChange={(e) => {
                          onChange(Number(e.value));
                        }}
                        options={[{ value: 1, label: "2024" }]}
                        defaultValue={{ value: 1, label: "2024" }}
                      />
                    )}
                  />
                  {errors?.year_id && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {errors.year_id.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <Line />
            <div className="flex justify-end gap-4 px-6">
              <button onClick={close} type="button" className="btn btn--border">Cancel</button>
              <button type="submit" className="btn">Add Holiday</button>
            </div>
          </div>
        </form>
      </ReactSlidingPane>
  );
};

export default AddNewHoliday;
