import React, { useEffect, useState, useRef } from "react";
import Avatar from "../../Avatar/Avatar";
import EmployeeSettings from "./EmployeeSettings";
import GeneralInfo from "./GeneralInfo";
import JobInfo from "./JobInfo";
import BankInfo from "./BankInfo";
import SalaryInfo from "./SalaryInfo";
import DocumentsInfo from "./DocumentsInfo";
import DependentCard from "./DependentCard";
import AccessCardData from "./AccessCardData";
import AssetInfo from "./AssetInfo";
import SettingsInfo from "./SettingsInfo";
import EmployeeSettingsTab from "./EmployeeSettingsTab";
import userRoleAuth from "../../../utils/userRoleAuth/userRoleAuth";
import { useSelector } from "react-redux";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const SingleEmployeeDetails = () => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const navigate = useNavigate();
  const [apiCall, makeApiCall] = useState(false);

  const params = useParams();
  const user = useSelector((state) => state.user);
  const { userProfile } = user;
  const [employee, setEmployee] = useState(null);
  const fullName = `${employee?.first_name || ""} ${employee?.last_name || ""}`;
  const lineManagerName = employee?.line_manager_name || "";
  const officeLocation = employee?.office_location || "";
  const designationTitle = employee?.designation_title || "";
  const photo = employee?.photo || null

  const handleMakeApiCall = () => {
    makeApiCall((prev) => (!prev))
  }

  const fetchEmployeeData = async (empId) => {
    // console.log("employee id",empId)
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(
        `employee/search?&sortOrder=DESC&employeeId=${empId}&fullDetails=1`,
        {
          signal: controller.signal,
        }
      )
      .then((response) => {
        // isMounted && // console.log(response.data);
        const res = response?.data || {};
        if (Number(res.statusCode) === 200) {
          setEmployee(res.employee[0]);
        } else if (Number(res.statusCode) === 400) {
          setEmployee(null);
        }
      })
      .catch((err) => {
        // console.error(err);
        setEmployee(null);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    const employeeId = params?.employeeId || null
    const pathname = location.pathname.split("/").slice(-2)[0];
    const empId =
      pathname === "employee-information" ? employeeId : userProfile.id;
    if (empId) {
      fetchEmployeeData(empId);
    } else if (pathname === "employee-information" && !employeeId) {
      navigate("/employee/manage-employee/employee-list");
    }
  }, [userProfile, params, apiCall]);

  const [index, setIndex] = useState(0);

  const tabs = [
    { name: "GENERAL INFO", status: true },
    { name: "JOB", status: true },
    { name: "BANK/PF/ESI", status: true },
    // { name: "SALARY", status: true },
    // { name: "DOCUMENTS", status: true },
    // { name: "DEPENDENTS", status: true },
    // { name: "ACCCES CARD", status: true },
    // { name: "ASSETS", status: true },
    // { name: "SETTINGS", status: true },
  ];

  const components = [
    <GeneralInfo employee={employee} handleMakeApiCall={handleMakeApiCall} />,
    <JobInfo employee={employee} handleMakeApiCall={handleMakeApiCall} />,
     <BankInfo employee={employee} handleMakeApiCall={handleMakeApiCall}/>,
    <SalaryInfo />,
    <DocumentsInfo />,
    <DependentCard />,
    <AccessCardData />,
    <AssetInfo />,
    <EmployeeSettingsTab />,
  ];
  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center pb-6">
          <div className="pr-4">
            <Avatar status="available" image={photo} />
          </div>
          <div>
            <div className="flex items-center">
              <h3 className="text-lg font-semibold text-[#1F2937] pr-3">
                {fullName}
              </h3>
              <p className="text-sm text-[#059669] font-medium">Active</p>
            </div>
            <div className="flex gap-4">
              <div className="flex gap-1 items-center">
                <i className="ico-briefcase text-sm"></i>
                <p>{designationTitle}</p>
              </div>
              <div className="flex gap-1 items-center">
                <i className="ico-people"></i>
                <p>{lineManagerName}</p>
              </div>
              <div className="flex gap-1 items-center">
                <img src="/images/location-icon.svg" alt="" />
                <p>{officeLocation}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="pr-3">
            <a href="#" className="btn btn--border py-4 px-3">
              <i className="ico-more"></i>
            </a>
          </div>
          <div>
            <a href="#" className="btn">
              <span>
                <i className="ico-chat mr-1"></i>
              </span>
              Message
            </a>
          </div>
        </div>
      </div>
      <ul className="tab-list tab-head">
        {tabs.map((item, i) => {
          if (item.status) {
            return (
              <li
                key={i}
                className={`tab-li ${index == i && `active-tab`}`}
                onClick={() => {
                  setIndex(i);
                }}
              >
                {item.name}
              </li>
            );
          }
        })}
      </ul>
      <div className="current-tab-div">{components[index]}</div>
    </>
  );
};

export default SingleEmployeeDetails;
