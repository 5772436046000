import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Select, { components } from "react-select";
import { reactSelectBoxValidator } from "../../utils/ReactSelectBoxValidator/reactSelectBoxValidator";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const ApplyAdditionalLeaveModal = ({ open, close, leave, makeApiCall }) => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState({
    leave_type_id: null,
    applied_for_date: null,
  });
  const [errMsg, setErrMsg] = useState(
    !leave.length ? "No leave types are eligible for additional leave" : ""
  );

  const applyAdditionalLeave = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .post(`lms/leave/applyAdditionalLeaves`, data)
      .then((response) => {
        // isMounted && console.log(response.data, "apply leave additional");
        const res = response?.data;
        if (res.status) {
          toast.success(res.message);
          makeApiCall();
          close();
        } else {
          toast.error(res.message || "Failed to apply additional leave");
        }
      })
      .catch((err) => {
      // console.error(err.response.data.message);
        toast.error(err?.response?.data.message || "Something went wrong, try again");
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const handleSubmit = () => {
    const isData = reactSelectBoxValidator(data);
    if (isData) {
      setErrMsg("");
      applyAdditionalLeave();
    } else {
      setErrMsg("Fill the required fields");
    }
  };

  return (
    <>
      <Modal open={open} onClose={close} center>
        <div className="p-5 min-w-[450px] text-black flex flex-col gap-3">
          <h1 className="text-lg font font-medium border-b border-gray-300 pb-4">
            Request additional leave
          </h1>
          <span className="text-red-600">{errMsg}</span>
          {leave.length ? (
            <>
              <div>
                <label>
                  Select leave type<span className="text-red-600">*</span>
                </label>
                <Select
                  options={
                    leave.length &&
                    leave.map((i) => ({ label: i.leave_name, value: i.id }))
                  }
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      leave_type_id: Number(e.value),
                    }))
                  }
                  components={{ DropdownIndicator }}
                />
              </div>
              <div>
                <label>
                 Requested for date<span className="text-red-600">*</span>
                </label>
                <input
                  type="date"
                  max={new Date().toISOString().split('T')[0]}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      applied_for_date: e.target.value,
                    }))
                  }
                />
              </div>
            </>
          ) : null}
          <div className="flex justify-end gap-3">
            <button type="button" onClick={close} className="btn btn--border">
              Close
            </button>
            {leave.length ? (
              <button type="button" onClick={handleSubmit} className="btn">
                Apply leave
              </button>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ApplyAdditionalLeaveModal;
