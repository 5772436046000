import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Line from "../../../../Line";
import Select, { components } from "react-select";
import { useForm } from "react-hook-form";
import { reactSelectBoxValidator } from "../../../../../utils/ReactSelectBoxValidator/reactSelectBoxValidator";
import ToggleSwitch from "../../../../ToggleSwitch/ToggleSwitch";
import { carryForwardTypeOptions } from "../../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const EditLeaveTypesInPolicyModal = ({
  openModal,
  onCloseModal,
  leave,
  setPolicyData,
}) => {
  const leaveName = leave.leave_name;
  const leaveApplicationId = leave.notIncluded ? leave.id : leave.leave_type_id;
  const carryForward = leave.carry_forward;
  const carryForwardLimit = leave.carry_forward_limit;
  const carryForwardType = leave.carry_forward_type;
  const carryForwardYears = leave.notIncluded
    ? leave.carry_forward_years
    : leave.carry_forward_year;
  const description = leave.description;
  const notIncluded = leave.notIncluded;
  const numberOfDaysAllowed = leave.number_days_allowed;
  const status = leave.status;
  const isLopLeave = leave.is_lop;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      leave_type_id: Number(leaveApplicationId),
      leave_name: leaveName,
      number_days_allowed: numberOfDaysAllowed,
      description: description,
      carry_forward: carryForward,
      carry_forward_type: carryForwardType,
      carry_forward_limit: carryForwardLimit,
      carry_forward_year: carryForwardYears,
      status: Number(status),
    },
  });

  const [isActive, setIsActive] = useState(Number(status));
  const [isCarryForward, setIsCarryForward] = useState(
    Number(getValues("carry_forward"))
  );

  const createLeavePolicyMapping = async (data) => {
    // console.log("CREATE");
    const {
      carry_forward,
      carry_forward_limit,
      carry_forward_type,
      carry_forward_year,
      description,
      leave_name,
      number_days_allowed,
      status,
    } = data;

    setPolicyData((prev) => {
      return {
        ...prev,
        itemsToInsert: prev.itemsToInsert.map((item) => {
          if (Number(item.id) === Number(leave.id)) {
            return {
              leave_type_id: Number(leaveApplicationId),
              leave_name,
              number_days_allowed: Number(number_days_allowed),
              description,
              carry_forward: carry_forward ? 1 : 0,
              carry_forward_type: Number(carry_forward_type),
              carry_forward_limit: Number(carry_forward_limit),
              carry_forward_year: Number(carry_forward_year),
              status: Number(status),
              draftted: true,
            };
          } else {
            return item;
          }
        }),
      };
    });
  };

  const updateLeavePolicyMapping = async (data) => {
    const {
      carry_forward,
      carry_forward_limit,
      carry_forward_type,
      carry_forward_year,
      description,
      leave_name,
      number_days_allowed,
      status,
    } = data;
    setPolicyData((prev) => {
      return {
        ...prev,
        itemsToUpdate: prev.itemsToUpdate.map((item) => {
          if (Number(item.leave_type_id) === Number(leave.leave_type_id)) {
            return {
              leave_type_id: Number(leaveApplicationId),
              leave_name,
              number_days_allowed: Number(number_days_allowed),
              description,
              carry_forward: carry_forward ? 1 : 0,
              carry_forward_type: Number(carry_forward_type),
              carry_forward_limit: Number(carry_forward_limit),
              carry_forward_year: Number(carry_forward_year),
              status: Number(status),
            };
          } else {
            return {
              leave_type_id: Number(item.leave_type_id),
              leave_name: item.leave_name,
              number_days_allowed: Number(item.number_days_allowed),
              description,
              carry_forward: Number(item.carry_forward),
              carry_forward_type: Number(item.carry_forward_type),
              carry_forward_limit: Number(item.carry_forward_limit),
              carry_forward_year: Number(item.carry_forward_year),
              status: Number(item.status),
            };
          }
        }),
      };
    });
  };

  const getToggleState = (e) => {
    setValue("status", e ? 1 : 0);
    setIsActive(e);
  };

  const onSubmit = async (data) => {
    if (notIncluded) {
      await createLeavePolicyMapping(data);
    } else {
      await updateLeavePolicyMapping(data);
    }
    onCloseModal();
  };

  return (
    <>
      <Modal open={openModal} onClose={onCloseModal} center>
        <form onSubmit={handleSubmit(onSubmit)} className="min-w-[500px]">
          <div className="p-5 max-w-[450px] text-black flex flex-col gap-3">
            <div className="text-lg">
              <label>Draft leave details</label>
              <Line />
            </div>
            <div>
              <p className="font-medium text-lg"> {leaveName}</p>
            </div>
            {!isLopLeave ? (
              <div>
                <label className="py-3 text-gray-600">
                  Number of days allowed
                </label>
                <input
                  type="text"
                  {...register("number_days_allowed", {
                    required: "This field is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid number",
                    },
                  })}
                />
                {errors?.number_days_allowed && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-2 ml-1">
                    {errors.number_days_allowed.message}
                  </span>
                )}
              </div>
            ) : null}

            {!isLopLeave ? (
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <label className="py-3 text-gray-600">Carry forward</label>
                  <input
                    type="checkbox"
                    {...register("carry_forward")}
                    onChange={(e) => setIsCarryForward(e.target.checked)}
                  />
                </div>
              </div>
            ) : null}

            {isCarryForward ? (
              <div className="flex flex-col gap-2">
                <div>
                  <label className="text-gray-600">Carry forward type</label>
                  <Select
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    options={carryForwardTypeOptions}
                    defaultValue={
                      carryForwardType &&
                      carryForwardTypeOptions.map((item) => {
                        if (Number(item.value) === Number(carryForwardType))
                          return item;
                      })
                    }
                  />
                  <span
                    id="carry_forward_type"
                    className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
                  ></span>
                </div>
                <div>
                  <label className="text-gray-600">Carry forward limit</label>
                  <input
                    type="text"
                    {...register("carry_forward_limit", {
                      required: "This field is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Please enter a valid number",
                      },
                    })}
                  />
                  {errors?.carry_forward_limit && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-2 ml-1">
                      {errors.carry_forward_limit.message}
                    </span>
                  )}
                </div>
                <div>
                  <label className="text-gray-600">Carry forward years</label>
                  <input
                    type="text"
                    {...register("carry_forward_year", {
                      required: "This field is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Please enter a valid number",
                      },
                    })}
                  />
                  {errors?.carry_forward_year && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-2 ml-1">
                      {errors.carry_forward_year.message}
                    </span>
                  )}
                </div>
              </div>
            ) : null}

            <div>
              <div className="flex items-center gap-2">
                {" "}
                {isActive ? "Active" : "Inactive"}
                <ToggleSwitch
                  checked={isActive}
                  getToggleState={getToggleState}
                />
              </div>
            </div>
            <div>
              <label className="py-3 text-gray-600">Description</label>
              <textarea
                {...register("description", {
                  required: "This field is required",
                })}
              ></textarea>
              {errors?.description && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-2 ml-1">
                  {errors.description.message}
                </span>
              )}
            </div>
            <div className="flex justify-end gap-4 mt-10">
              <button
                type="button"
                className="btn btn--border"
                onClick={onCloseModal}
              >
                Cancel
              </button>
              <button type="submit" className="btn">
                Draft
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditLeaveTypesInPolicyModal;
