import React, { useEffect, useState, useRef } from "react";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon } from "../../../../Icons/Icons";
import { addNewShiftConst } from "../../../../utils/Consts/consts";
import SelectShiftType from "./SelectShiftType";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
import { formatTimeDifferece } from "../../../../utils/FormatDate/formatTimeDifference";
import { toast } from "react-toastify";
import { formatHourAndMinitueDuration } from "../../../../utils/FormatDate/formatTimeHourDuration";

const fieldRequiredErrMsg = (
  <p className="text-xs pt-1 font-medium text-red-600">
    This field is required
  </p>
);
const requiredMsg = <span className="text-red-600">*</span>;

const AddNewShiftSlidingPanel = ({ open, close, fetchShiftList }) => {
  const axiosPrivate = useAxiosPrivate();
  const errRef = useRef();

  const { shiftType: shiftTypeView } = addNewShiftConst;

  const [work, setWork] = useState({}); //work shedule details are initially added here and used to process data for child components and formatting to working time array
  const [totalWorkHour, setTotalWorkHour] = useState(""); //total work hour and minite eg 40h 00m
  const [workingTime, setWorkingTime] = useState([]); // formatted workingtime array to use in req body

  const [shiftType, setShiftType] = useState(shiftTypeView.clockBased);
  const [openSelectShitColor, setSelectShiftColor] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const [shiftColors, setShiftColor] = useState([
    { color: "#C2D1F8", status: true },
    { color: "#BAE6FD" },
    { color: "#A7F3D0" },
    { color: "#99F6E4" },
    { color: "#DDD6FE" },
    { color: "#FECACA" },
    { color: "#FED7AA" },
    { color: "#9AB3F3" },
    { color: "#7DD3FC" },
    { color: "#6EE7B7" },
    { color: "#5EEAD4" },
    { color: "#A78BFA" },
    { color: "#FCA5A5" },
    { color: "#FDBA74" },
  ]);

  const [selectedShiftColor, setSelectedShiftColor] = useState(
    shiftColors[0].color
  );

  const handleChangeShiftType = (e) => {
    setWorkingTime([]);
    setShiftType(e);
    setTotalWorkHour(``);
    setWork({});
  };

  const handleOpenSelectShiftColor = () => {
    setSelectShiftColor((prev) => !prev);
  };

  const handleSetShiftColor = (e) => {
    setSelectShiftColor(e);
    setShiftColor((prev) =>
      prev.map((item) => {
        if (item?.color === e) {
          return {
            ...item,
            status: true,
          };
        }
        return {
          ...item,
          status: false,
        };
      })
    );
    setSelectedShiftColor(e);
    handleOpenSelectShiftColor();
  };

  const addNewShift = async (reqObj) => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .post("attendance/addNewShift", reqObj)
      .then((res) => {
        const { data } = res;
        if (data.status) {
          fetchShiftList();
          toast.success(`${reqObj.shiftName} added successfully`);
          close();
        } else {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err?.response?.data?.message || "Failed to add new shift");
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const formatFinalWorkingData = async () => {
    let hours = 0;
    let minitues = 0;
    let formatArr = [];

    const keys = Object.keys(work); // getting the keys, becoz stored the shift day detailss using the day id
    keys?.forEach((key) => {
      const time = formatTimeDifferece(work[key]?.timeFrom, work[key]?.timeTo); //formatting from and to time
      if (time || shiftType === shiftTypeView.durationBased) {
        if (shiftType === shiftTypeView.clockBased) { //checking shift type is clock based, in clock based we have from and to time, then adding the results to hours and minitues
          hours += time?.hours || 0;
          minitues += time?.minutes || 0;
        } else { //duration based shift type
          const formatedDurationBasedTime = formatHourAndMinitueDuration(
            work[key]?.duration
          ); // formatting the duaration, in duration type we only have hour and miniute, then adding the result to hours and minitues
          hours += Number(formatedDurationBasedTime?.hour) || 0;
          minitues += Number(formatedDurationBasedTime?.minitue) || 0;
        }
      }
      if (work[key]) {
        const formatDuration = formatTimeDifferece(
          work[key]?.timeFrom,
          work[key]?.timeTo
        ); // formatting the day time from and time to of data inside the work array

        formatArr.push({
          ...work[key],
          break: work[key]?.break || false,
          duration:
            shiftType === shiftTypeView.clockBased
              ? formatDuration?.hours + formatDuration?.minutes / 60
              : work[key]?.duration, // checking shift type and adding the durations
        });
      }

      setWorkingTime(formatArr); // set the final formatted data for our api call
      setErrMsg("")
    });
    setTotalWorkHour(`${hours}h ${minitues}m`); // total time and minite to show in the sliding panel
    return true;
  };

  useEffect(() => {
    formatFinalWorkingData();
  }, [work]);

  const setErrMsgAndScrollToView = (msg) => {
    setErrMsg(msg || "Fill all the required fields");
    errRef?.current?.focus();
    errRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const saveShift = async (data) => {
    await formatFinalWorkingData();
    const { shiftName, effectiveFrom } = data;

    const reqObj = {
      shiftType,
      shiftName,
      effectiveFrom,
      workingTime,
      colorCode: selectedShiftColor,
    };

    if (workingTime?.length) {
      if (shiftType === shiftTypeView.clockBased) {
        for (let i of workingTime) {
          const timeNotIncluded = !i?.timeFrom || !i?.timeTo; //checking time from and to is not exist
          const breakTimeNotIncluded =
            i?.break && (!i?.breakFrom || !i?.breakTo); // checking break time from and to is not exist
          const timeDifferece = formatTimeDifferece(i?.timeFrom, i?.timeTo); // getting the formatted time
          const isInvalidTime =
            timeDifferece?.hours < 0 || timeDifferece?.minutes < 0; // checking the selected time is in negative format

          if (timeNotIncluded || breakTimeNotIncluded || isInvalidTime) { // checking all confitions
            return setErrMsgAndScrollToView(
              isInvalidTime ? "Invalid durations are not accepted" : null
            ); //if true return set error message and scroll to top
          }
        }
        addNewShift(reqObj); //else calling addnewshift fn and passing the formatted reqObj to use are req.body
        return;
      } else {
        addNewShift(reqObj);
        return;
      }
    }
    return setErrMsgAndScrollToView();
  };


  return (
    <ReactSlidingPane
      isOpen={open}
      onRequestClose={close}
      closeIcon=" "
      width="960px"
    >
      <div className="flex flex-col justify-between text-black" ref={errRef}>
        <div className="">
          <div className="border-b flex justify-between items-center px-10 py-3">
            <h1 className="font-semibold text-base">Add New Shift</h1>
            <button onClick={close}>{closeIcon}</button>
          </div>

          <div className="px-10 py-3">
            <label className="text-base">Shift Name {requiredMsg}</label>
            <div className="flex gap-2 items-center">
              <div className="relative">
                <span
                  style={{ backgroundColor: selectedShiftColor }}
                  className={`h-6 w-6 rounded-full inline-block cursor-pointer`}
                  onClick={handleOpenSelectShiftColor}
                ></span>
                {openSelectShitColor ? (
                  <div className="border flex flex-wrap w-[240px] gap-2 rounded-md absolute p-2 bg-white">
                    {shiftColors.map((item) => (
                      <span
                        style={{ backgroundColor: item?.color || "" }}
                        className={`cursor-pointer h-6 w-6 rounded-full text-center inline-block`}
                        onClick={() => handleSetShiftColor(item?.color || "")}
                      >
                        {item.status ? (
                          <i className="ico-tick text-[8px]"></i>
                        ) : null}
                      </span>
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="w-full">
                <input
                  type="text"
                  {...register("shiftName", { required: "Enter shift name" })}
                />
                {errors?.shiftName && fieldRequiredErrMsg}
              </div>
            </div>
          </div>
          <div className="px-10 py-3">
            <label className="text-base">Effective from {requiredMsg}</label>
            <input
              type="date"
              {...register("effectiveFrom", {
                required: "Select the effective from date",
              })}
            />
            {errors?.effectiveFrom && fieldRequiredErrMsg}
          </div>
          <div className="px-10 py-3">
            <label className="text-base">Shift type {requiredMsg}</label>
            <div className="flex justify-between gap-10">
              <div
                className={`border rounded-md flex gap-2 p-3 cursor-pointer ${
                  shiftType === shiftTypeView.clockBased
                    ? "border-blue-600"
                    : ""
                }`}
                onClick={() => handleChangeShiftType(shiftTypeView.clockBased)}
              >
                <input
                  type="radio"
                  checked={shiftType === shiftTypeView.clockBased}
                />
                <p>
                  <h1 className="font-medium">Clock-based</h1>
                  <p
                    className={` ${
                      shiftType === shiftTypeView.clockBased
                        ? "text-blue-600"
                        : "text-gray-500"
                    }`}
                  >
                    Schedule with a fixed start and end time. Only time clocked
                    during the schedule will be counted as paid time.
                  </p>
                </p>
              </div>
              <div
                className={`border rounded-md flex gap-2 p-3 cursor-pointer  ${
                  shiftType === shiftTypeView.durationBased
                    ? "border-blue-600"
                    : ""
                }`}
                onClick={() =>
                  handleChangeShiftType(shiftTypeView.durationBased)
                }
              >
                <input
                  type="radio"
                  checked={shiftType === shiftTypeView.durationBased}
                />
                <p>
                  <h1 className="font-medium">Duration Based</h1>
                  <p
                    className={` ${
                      shiftType === shiftTypeView.durationBased
                        ? "text-blue-600"
                        : "text-gray-500"
                    }`}
                  >
                    Schedule based on a duration without a start and end time.
                    Any time clocked will be counted as paid time.
                  </p>
                </p>
              </div>
            </div>
          </div>

          <div className="flex gap-2 flex-col px-10">
            <label className="text-base">Working Time {requiredMsg}</label>
            <p className="font-medium text-red-600">{errMsg}</p>
            <SelectShiftType
              shiftType={shiftType}
              setWork={setWork}
              work={work}
              totalWorkHour={totalWorkHour}
            />
          </div>
        </div>

        <div className="px-10 mt-5 flex gap-3 justify-end border-t py-3">
          <button className="btn btn--border" onClick={close}>
            Close
          </button>
          <button className="btn" onClick={handleSubmit(saveShift)}>
            Save Shift
          </button>
        </div>
      </div>
    </ReactSlidingPane>
  );
};

export default AddNewShiftSlidingPanel;
