import React, { useEffect, useState } from "react";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon, filterIcon } from "../../../../Icons/Icons";
import Avatar from "../../../Avatar/Avatar";
import Select from "react-select";
import { DropdownIndicator } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import ApplicableForSelectComp from "./ApplicableForSelectComp/ApplicableForSelectComp";
import { useForm, Controller } from "react-hook-form";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import LoadingButton from "../../../Buttons/LoadingButton";

const requiredErrMsg = (
  <p className="text-red-600 font-medium text-sm py-1">
    This field is required
  </p>
);
const requiredField = <span className="text-red-600">*</span>;

const AssignShiftSlidingPanel = ({ open, close, fetchEmployeeShiftList }) => {
  const axiosPrivate = useAxiosPrivate();

  const [applicableForModal, setApplicableForModal] = useState(false);
  const [applicableForData, setApplicapleForData] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [isLoaderBtn, setLoaderBtn] = useState(false);

  const [fromDateForValidate, setFromDate] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ mode: "onBlur" });

  const fetchShiftList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("attendance/getShiftList", {
        signal: controller.signal,
      })
      .then((res) => {
        const { data } = res;
        if (data.status) {
          setTimeout(() => {
            setShiftList(data?.result);
          }, 300);
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchShiftList();
  }, []);

  const handleChangeApplicableFor = (data) => {
    setErrMsg("");
    const { status, referenceId, tag, title, photo, uniqueId } = data;
    if (status) {
      const isExist = applicableForData.find((i) => i.uniqueId === uniqueId);
      if (isExist) {
        return;
      }
      setApplicapleForData((prev) => [
        ...prev,
        { referenceId, tag, title, photo, uniqueId },
      ]);
    } else {
      setApplicapleForData((prev) =>
        prev.filter((item) => item.uniqueId !== uniqueId)
      );
    }
  };

  const handleCloseSlidingPanel = () => {
    setApplicapleForData([]);
    close();
  };

  const handleFilter = (e) => {
    setApplicableForModal(e);
  };

  const assignShift = async (reqObj) => {
    if (reqObj) {
      setLoaderBtn(true)
      let isMounted = true;
      const controller = new AbortController();
      await axiosPrivate
        .post("attendance/assignShift", reqObj)
        .then((res) => {
          const { data } = res;
          if (data.status) {
            //success
            toast.success(data.message);
            fetchEmployeeShiftList();
            close();
          } else {
            toast.error("Failed to assign shift");
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.response.data.message || "No server response");
        })
        .finally(() => {
          setLoaderBtn(false)
        });
      return () => {
        isMounted = false;
        controller.abort();
      };
    } else {
      setErrMsg("Fill the required fields");
    }
  };

  const onSubmit = (data) => {
    if (applicableForData.length) {
      const { shiftId, fromDate, toDate, note } = data;
      const reqObj = {
        shiftId: Number(shiftId),
        fromDate,
        toDate,
        note,
        applicableFor: applicableForData.map((data) => ({
          referenceId: data.referenceId,
          tag: data.tag,
        })),
      };

      assignShift(reqObj);
    } else {
      setErrMsg("Minimum one tag is required");
    }
  };

  const handleRemoveItemFromApplicableFor = (item) => {
    setApplicapleForData((prev) =>
      prev.filter((data) => data.uniqueId !== item.uniqueId)
    );
  };

  return (
    <ReactSlidingPane
      isOpen={open}
      onRequestClose={handleCloseSlidingPanel}
      width="560px"
      closeIcon=" "
    >
      <div className="flex justify-between flex-col h-full">
        <div>
          <div className="flex justify-between items-center border-b py-3 px-5">
            <h1 className="text-black font-medium">Assign Shift</h1>
            <button onClick={handleCloseSlidingPanel}>{closeIcon}</button>
          </div>

          <div className="px-5 flex flex-col gap-3 text-black py-4">
            <div className="flex flex-col">
              {errMsg ? (
                <span className="text-red-600 font-medium">{errMsg}</span>
              ) : null}
              <label>Applicable For {requiredField}</label>
              <div className="border min-h-[35px] p-2 rounded-lg border-gray-300 shadow flex justify-start flex-wrap gap-2 font-normal relative hover:bg-white">
                <button
                  className="absolute z-50 top-2 right-2 ml-2"
                  onClick={() => handleFilter((prev) => !prev)}
                >
                  {filterIcon()}
                </button>
                {applicableForData.map((item, index) => (
                  <div
                    className="bg-gray-100 rounded-3xl flex items-center p-1"
                    key={`applcablefor_uniq_${index + 120}`}
                  >
                    {item.photo ? (
                      <Avatar image={item.photo} width={"6"} height={"6"} />
                    ) : null}
                    <p className="text-sm font-medium ml-2 mr-4">
                      {item.title}
                    </p>
                    <button
                      className="mx-1"
                      onClick={() => handleRemoveItemFromApplicableFor(item)}
                    >
                      {closeIcon}
                    </button>
                  </div>
                ))}

                {applicableForModal ? (
                  <ApplicableForSelectComp
                    applicableForData={applicableForData}
                    handleChangeApplicableFor={handleChangeApplicableFor}
                  />
                ) : null}
              </div>
              {errors?.applicableFor && requiredErrMsg}
            </div>
            <div>
              <label>Date {requiredField}</label>
              <div className="flex justify-between items-center gap-3">
                <input
                  type="date"
                  {...register("fromDate", { required: true })}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                <p>to</p>
                <input
                  type="date"
                  disabled={!fromDateForValidate}
                  min={fromDateForValidate ? fromDateForValidate : ""}
                  {...register("toDate", { required: true })}
                />
              </div>
              {errors?.fromDate || errors?.toDate ? requiredErrMsg : null}
            </div>
            <div>
              <label>Select Shift {requiredField}</label>
              <Controller
                control={control}
                name="shiftId"
                render={({ field }) => (
                  <Select
                    components={{ DropdownIndicator }}
                    options={shiftList.map((item) => ({
                      label: item.shift.shift_name,
                      value: item.shift.id,
                    }))}
                    onChange={(e) => field.onChange(e.value)}
                  />
                )}
                rules={{
                  required: true,
                }}
              />
              {errors?.ShiftId && requiredErrMsg}
            </div>
            <div>
              <label>Add note {requiredField}</label>
              <textarea {...register("note", { required: true })}></textarea>
              {errors?.note && requiredErrMsg}
            </div>
          </div>
        </div>
        <div className="border-t px-5 py-3 flex justify-end gap-3">
          <button className="btn btn--border" onClick={handleCloseSlidingPanel}>
            Close
          </button>
          {isLoaderBtn ? (
            <LoadingButton title="Assigning Shift"/>
          ) : (
            <button className="btn" onClick={handleSubmit(onSubmit)}>
              Assign Shift
            </button>
          )}
        </div>
      </div>
    </ReactSlidingPane>
  );
};

export default AssignShiftSlidingPanel;
