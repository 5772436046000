import { components } from "react-select";

export const carryForwardTypeOptions = [
  { label: "Number of days", value: 1 },
  { label: "Percentage", value: 2 },
];

export const employeeType = [
  { value: 2, label: "Permanent Employee" },
  { value: 3, label: "Part-time Employee" },
  { value: 4, label: "Temporary Employee" },
  { value: 5, label: "Intern" },
];

export const employementStatus = [
  { value: 6, label: "Long Absence" },
  { value: 7, label: "Terminated" },
  { value: 8, label: "Resigned" },
  { label: "Onboarding", value: 2 },
  { label: "On leave", value: 3 },
  { label: "Active", value: 1 },
  { label: "Off-boarding", value: 5 },
  { label: "Probationary", value: 4 },
];

export const leaveMode = [
  { value: 1, label: "Half Day" },
  { value: 2, label: "Full Day" },
];

export const sourceFrom = [
  { value: 5, label: "Advertisement" },
  { value: 6, label: "Social Media" },
  { value: 7, label: "Website" },
  { value: 8, label: "Job Apps" },
];

export const sourceOfHire = [
  { value: 6, label: "Employee Referrals" },
  { value: 7, label: "Campus Recruitment" },
  { value: 8, label: "Walk-in Interview" },
  { value: 9, label: "Staffing" },
  { value: 10, label: "Direct Recruitment" },
];

export const bloodGroup = [
  { value: "A+", label: "A+" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B-", label: "B-" },
  { value: "O+", label: "O+" },
  { value: "O-", label: "O-" },
  { value: "AB+", label: "AB+" },
  { value: "AB-", label: "AB-" },
];
export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};
export const bankNames = [
  { value: "SBI", label: "SBI" },
  { value: "AXIS", label: "AXIS" },
  { value: "ICICI", label: "ICICI" },
];
export const accountTypes = [
  { value: "Current Account", label: "Current Account" },
  { value: "Savings account", label: "Savings account" },
  { value: "Salary account", label: "Salary account" },
];

export const shiftTypes = [
  { value: 1, label: "Clock based" },
  { value: 2, label: "Duration based" },
];
export const statusOptions = [
  { value: "1", label: "active" },
  { value: "0", label: "inactive" },
];

export const roleOptions = [
  { value: 1, label: "Admin" },
  { value: 2, label: "HR" },
  { value: 4, label: "Manager" },
  { value: 6, label: "Employee" },
];

export const regularizationTypeOptions = [
  { value: 1, label: "Single Day" },
  { value: 2, label: "Multiple Days" },
];

export const regularizationStatusOptions = [
  { value: 1, label: "Approve" },
  { value: 2, label: "Pending" },
  { value: 3, label: "Reject" },
  { value: 4, label: "Cancel" },
];

export const genderOptions = [
  
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
  // { value: "Non-Binary", label: "Non-Binary" },
  // { value: "Agender", label: "Agender" },
  // { value: "Bigender", label: "Bigender" },
  // { value: "Genderqueer", label: "Genderqueer" },
  // { value: "Genderfluid", label: "Genderfluid" },
  // { value: "Two-Spirit", label: "Two-Spirit" },
  // { value: "Prefer not to say", label: "Prefer not to say" },
];

export const residentialStatusOptions = [
  { value: "Resident", label: "Resident" },
  { value: "Non-resident", label: "Non-Resident" },
  { value: "Citizen", label: "Citizen" },
  { value: "Permanent-resident", label: "Permanent Resident" },
  { value: "Temporary-resident", label: "Temporary Resident" },
  { value: "Visa-holder", label: "Visa Holder" },
  { value: "Refugee-asylee", label: "Refugee/Asylee" },
  { value: "Undocumented", label: "Undocumented/Illegal Immigrant" },
];

export const employeeLevelOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
];

export const relationShipOptionList = [
  { label: "Parent", value: "Parent" },
  { label: "Sibling", value: "Sibling" },
  { label: "Spouse", value: "Spouse" },
  { label: "Child", value: "Child" },
  { label: "Grandparent", value: "Grandparent" },
  { label: "Grandchild", value: "Grandchild" },
  { label: "Aunt/Uncle", value: "Aunt/Uncle" },
  { label: "Niece/Nephew", value: "Niece/Nephew" },
  { label: "Cousin", value: "Cousin" },
  { label: "Friend", value: "Friend" },
  { label: "Colleague", value: "Colleague" },
  { label: "Supervisor", value: "Supervisor" },
  { label: "Employee", value: "Employee" },
  { label: "Mentor", value: "Mentor" },
  { label: "Student", value: "Student" },
];

export const titleOptions = [
  { label: "Mr.", value: "Mr." },
  { label: "Mrs.", value: "Mrs." },
  { label: "Ms.", value: "Ms." },
  { label: "Miss", value: "Miss" },
  { label: "Dr.", value: "Dr." },
  { label: "Prof.", value: "Prof." },
  { label: "Rev.", value: "Rev." },
  { label: "Sir", value: "Sir" },
  { label: "Madam", value: "Madam" },
];

export const holidayTypeOptions = [
  { value: 1, label: "Public Holiday" },
  { value: 2, label: "Regional Holiday" },
];
