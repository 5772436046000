import React, { Fragment, useEffect, useState } from "react";
import { closeIcon } from "../../../../../Icons/Icons";
import LeaveTypesInPolicyList from "./LeaveTypesInPolicyList";
import { Transition } from "@headlessui/react";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

const EditLeavePolicyModal = ({
  open,
  close,
  policy,
  leaveTypesList,
  makeApiCall,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const leaveTypesInPolicy = leaveTypesList;
  const [policyName, setPolicyName] = useState(policy?.policy_name);
  const [errMsg, setErrMsg] = useState("");
  const policyId = policy?.id;
  const [leavePolicyList, setLeavePolicyList] = useState(null);
  const [policyData, setPolicyData] = useState({
    itemsToInsert: {},
    itemsToUpdate: {},
  });

  
  const updatePolicyName = async () => {
    if (policyName) {
      setErrMsg("");
      let isMounted = true;
      const controller = new AbortController();
      await axiosPrivate
        .put(`lms/leave/updateLeavePolicy`, {
          policy_id: policy?.id,
          policy_name: policyName,
        })
        .then((response) => {
          // isMounted && console.log(response.data, "update leave policy");
          const res = response?.data;
          if (res.status) {
            toast.success("Successfully updated policy name");
            makeApiCall();
          } else {
            toast.error(res.message);
            setErrMsg(res.message);
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Something went wrong, try again");
        });

      return () => {
        isMounted = false;
        controller.abort();
      };
    } else {
      setErrMsg("Enter a valid policy name");
    }
  };


  const fetchListLeavePolicyMapping = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`lms/leave/listLeavePolicyMapping?policy_id=${policyId}`)
      .then((response) => {
        // isMounted && console.log(response.data, "update leave policy");
        const res = response?.data;
        if (res.status) {
          setLeavePolicyList(res.policy_list);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong, try again");
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };


  const createLeavePolicyMapping = async (data) => {
    // console.log(data,"CREATE API");
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .post(`lms/leave/createLeavePolicyMapping`, {
        policy_id: policyId,
        leave_policy_details: data,
      })
      .then((response) => {
        // isMounted && console.log(response.data, "create leave policy mapping");
        const res = response?.data;
        if (!res.status) {
          toast.error("Failed to add leave type to policy");
        } else if (res.status) {
          // toast.success("Leave type(s) added to policy");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong, try again");
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const updateLeavePolicyMapping = async (data) => {
    // console.log(data, "UPDATE API");
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .put(`lms/leave/updateLeavePolicyMapping`, {
        policy_id: policyId,
        leave_policy_details: data,
      })
      .then((response) => {
        isMounted && console.log(response.data, "update leave policy mapping");
        const res = response?.data;
        if (res.status) {
          // toast.success("Leave policy updated");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong, try again");
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };


  const handleUpdate = async () => {
    let insertItems = [];
    let updateItems = [];

    policyData.itemsToInsert.map((item) => {
      if (item.draftted) insertItems.push(item);
    });
    policyData.itemsToUpdate.map((item) => {
      updateItems.push({
        leave_type_id: Number(item.leave_type_id),
        leave_name: item.leave_name,
        number_days_allowed: Number(item.number_days_allowed),
        description: item.description,
        carry_forward: Number(item.carry_forward),
        carry_forward_type: Number(item.carry_forward_type),
        carry_forward_limit: Number(item.carry_forward_limit),
        carry_forward_year: Number(item.carry_forward_year),
        status: Number(item.status),
      });
    });
    if (insertItems.length) {
      await createLeavePolicyMapping(insertItems).then(() => {
        updateLeavePolicyMapping([...updateItems, ...insertItems]);
      });
    } else if (updateItems.length) {
      await updateLeavePolicyMapping([...updateItems, ...insertItems]);
    }
    await fetchListLeavePolicyMapping();
    toast.success("Leave policy updated");
    close();
  };

  useEffect(() => {
    fetchListLeavePolicyMapping();
  }, []);

  const [show, setShow] = useState(false);
  const transitonDelay = 150;
  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setShow(open);
    }, transitonDelay);

    return () => {
      clearTimeout(delayTimer);
    };
  }, [open]);

  const handleClose = () => {
    setShow(false);
    close();
  };

  const [formattedLeaveTypes, setFormattedLeavetypes] = useState(null);
  useEffect(() => {
    if (leaveTypesInPolicy) {
      let formattedArr = [];
      let itemsToUpdate = [];
      let itemsToInsert = [];
  
      leaveTypesInPolicy.forEach((item) => {
        const checkLeavePolicyActive = leavePolicyList?.find(
          (i) => Number(i.leave_type_id) === Number(item.id)
        );
        if (checkLeavePolicyActive) {
          formattedArr.push(checkLeavePolicyActive);
          itemsToUpdate.push(checkLeavePolicyActive);
        } else {
          formattedArr.push({
            ...item,
            status: 0,
            notIncluded: true,
          });
          itemsToInsert.push({
            ...item,
            status: 0,
          });
        }
      });
      setFormattedLeavetypes(formattedArr);
      setPolicyData((prev) => {
        return {
          ...prev,
          itemsToInsert,
          itemsToUpdate,
        };
      });
    }
  }, [leavePolicyList, leaveTypesInPolicy]);


  return (
    <>
      <div
        className={`flex  fixed top-0 left-0 right-0 z-50  p-4 overflow-x-hidden overflow-y-auto md:inset-0  bg-[#00000080] max-h-screen ${
          !open && "hidden"
        }`}
      >
        <Transition
          as={Fragment}
          enter={`transition ease-out duration-${transitonDelay}`}
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave={`transition ease-in duration-${transitonDelay}`}
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          show={show}
        >
          <div className="flex flex-col bg-white overflow-y-auto rounded-md justify-center p-5  m-auto w-auto max-h-full">
            <div className="flex items-center justify-between px-6 py-4">
              <h1 className="text-lg font-medium text-black">Leave Policy</h1>
              <span className="cursor-pointer" onClick={handleClose}>
                {closeIcon}
              </span>
            </div>
            <div className="flex gap-4 pb-4 px-6">
              <input
                type="text"
                className="max-w-[220px]"
                defaultValue={policy.policy_name}
                onChange={(e) => setPolicyName(e.target.value)}
              />
              <button type="button" onClick={updatePolicyName} className="btn">
                Update policy name
              </button>
            </div>
            <span className="text-red-600 px-6 pb-3">{errMsg}</span>
            <div className="flex flex-col justify-between min-h-[350px]">
              <div className="">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500">
                  <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
                    <tr>
                      <th className="px-4 py-4">Leave name</th>
                      <th className="px-4 py-4">Number of days allowed</th>
                      <th className="px-4 py-4">Status</th>
                      <th className="px-4 py-4"></th>
                    </tr>
                  </thead>
                  {formattedLeaveTypes &&
                    formattedLeaveTypes.map((item) => (
                      <LeaveTypesInPolicyList
                        leave={item}
                        policyId={policyId}
                        fetchListLeavePolicyMapping={
                          fetchListLeavePolicyMapping
                        }
                        setPolicyData={setPolicyData}
                      />
                    ))}
                </table>
              </div>
              <div className="flex justify-end gap-3 px-5 py-6">
                <button className="btn btn--border" onClick={handleClose}>
                  Close
                </button>
                <button className="btn" onClick={handleUpdate}>
                  Update
                </button>
              </div>
              <div
                className="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
                role="alert"
              >
                <span className="font-medium">Info: </span>
                Once you are done your draft, Please click on update
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
};

export default EditLeavePolicyModal;
