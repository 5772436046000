import React from 'react';
import { Outlet } from 'react-router-dom';
import DashboardSidebar from '../../components/DashboardSidebar';

const CandidateModule = () => {
    return (
        <div className="content overflow-hidden flex flex-1 self-stretch">
          <DashboardSidebar />
          <Outlet />
        </div>
      );
}

export default CandidateModule