import React from "react";
import ShiftCalendarData from "./ShiftCalendarData";
import formatDate from "../../../../utils/FormatDate/formatDate";

const ShiftCalenderTable = ({ data, datesCol }) => {
  const cols = [{ colName: "Employee Name" }];

  return (
    <>
      <div className="flex mb-[67px]">
        {/* EMPLOYEE NAME TABLE */}
        <div>
          <table className="w-[250px]">
            <thead className="">
              <tr>
                {cols.map((item, index) => (
                  <th className="py-3 px-4" key={index}>
                    {item.colName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <ShiftCalendarData data={item} key={index} />
              ))}
            </tbody>
          </table>
        </div>
        {/* EMPLOYEE NAME TABLE ENDS */}

        {/* EMPLOYEE DATE RANGE TABLE STARTSS*/}

        <div
          className="table-container"
          style={{ overflowX: "auto", maxWidth: "100%" }}
        >
          <table className="">
            <thead className="">
              <tr className="">
                {datesCol.map((item, index) => {
                  const formattedDate = formatDate(item).dateString3;
                  return (
                    <th
                      className="py-3 px-4 whitespace-nowrap border min-w-[90px]"
                      key={`date_col_${index}`}
                    >
                      {formattedDate}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <ShiftCalendarData
                data={data}
                isDates={true}
                datesCol={datesCol}
              />
            </tbody>
          </table>
        </div>

        {/* EMPLOYEE DATE RANGE TABLE ENDS */}
      </div>
    </>
  );
};

export default ShiftCalenderTable;
