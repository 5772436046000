import React, { useEffect, useState } from "react";
import Select from "react-select";
import Search from "../../../components/Header/Search/Search";
import ShiftCard from "../../../components/ShiftCard/ShiftCard";
import AddNewShiftSlidingPanel from "../../../components/Attendance/ManageShift/ShiftList/AddNewShiftSlidingPanel";
import {
  DropdownIndicator,
  shiftTypes,
  statusOptions
} from "../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import EmptyState from "../../../components/EmptyState/EmptyState";
import PageLoader from "../../../components/PageLoader";

const ShiftSchedule = () => {
  const [component, setComponent] = useState(<PageLoader />);
  const axiosPrivate = useAxiosPrivate();
  const [openAddNewShiftSlidingPanel, setOpenAddNewShiftSlidingPanel] =
    useState(false);
  const [shiftList, setShiftList] = useState(null);

  const handleOpenAddNewShiftSlidingPanel = () => {
    setOpenAddNewShiftSlidingPanel(true);
  };
  const handleCloseAddNewShiftSlidingPanel = () => {
    setOpenAddNewShiftSlidingPanel(false);
  };

  const [shiftType, setSelectedShiftType] = useState(null);
  const [statusOption, setStatus] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [shiftListUrl, setShiftListUrl] = useState(`attendance/getShiftList?`);
  const getSearchValue = (data) => {
    setSearchValue(data);
  };
  const isFilterApplied = statusOption || searchValue || shiftType;
  const fetchShiftList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(shiftListUrl, {
        signal: controller.signal
      })
      .then((res) => {
        const { data } = res;
        if (data.status) {
          setTimeout(() => {
            setShiftList(data?.result);
            setComponent(null);
          }, 300);
        } else {
          setShiftList(null);
          setComponent(<EmptyState />);
        }
      })
      .catch((err) => {
        setShiftList(null);
        setComponent(<EmptyState />);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const clearAllFilter = () => {
    setSelectedShiftType(null);
    setSearchValue(null);
    setStatus(null);
    let baseUrl = `attendance/getShiftList?`;
    setShiftListUrl(baseUrl);
  };
  useEffect(() => {
    let baseUrl = `attendance/getShiftList?`;

    if (shiftType) {
      baseUrl = baseUrl + `&shift_type_id=${shiftType}`;
    }
    if (statusOption) {
      baseUrl = baseUrl + `&status=${statusOption}`;
    }
    if (searchValue) {
      const delayTimer = setTimeout(() => {
        baseUrl = baseUrl + `&shift_name=${searchValue}`;
        setShiftListUrl(baseUrl);
      }, 600);
      return () => {
        clearTimeout(delayTimer);
      };
    }
    // console.log("baseUrl", baseUrl);
    setShiftListUrl(baseUrl);
  }, [shiftType, statusOption, searchValue]);

  useEffect(() => {
    fetchShiftList();
  }, [shiftListUrl]);

  return (
    <>
      {openAddNewShiftSlidingPanel ? (
        <AddNewShiftSlidingPanel
          open={openAddNewShiftSlidingPanel}
          close={handleCloseAddNewShiftSlidingPanel}
          fetchShiftList={fetchShiftList}
        />
      ) : null}
      <div className="w-full px-10">
        <div className="flex items-center justify-between pb-4 mt-8">
          <div>
            <h2>Shift Lists</h2>
            <p>Manage all employe regularization</p>
          </div>
          <div>
            <button
              className="btn text-[14px]"
              onClick={handleOpenAddNewShiftSlidingPanel}
            >
              <span>
                <i className="ico-plus text-white mr-1"></i>
              </span>
              Add new shift
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center pb-4">
          <div className="flex gap-3">
            <Select
              options={shiftTypes}
              components={{ DropdownIndicator }}
              className="custom-select-container text-gray-500"
              defaultValue={{
                value: "Choose Shift Type",
                label: "All Shift Type"
              }}
              onChange={(e) => {
                setSelectedShiftType(e.value);
              }}
            />
            <Select
              options={statusOptions}
              components={{ DropdownIndicator }}
              className="custom-select-container text-gray-500"
              defaultValue={{
                value: "Status: All",
                label: "Status: All"
              }}
              onChange={(e) => {
                setStatus(e.value);
              }}
            />
            {isFilterApplied ? (
              <p
                className="text-blue-600 font-medium pt-2 cursor-pointer"
                onClick={clearAllFilter}
              >
                Clear filter
              </p>
            ) : null}
          </div>

          <div className="flex">
            <div>
              <Search onChange={getSearchValue} />
            </div>
            <a href="" className="btn btn--border text-[14px]">
              <span>
                <i className="ico-download mr-1"></i>
              </span>
              Download
            </a>
          </div>
        </div>
        {shiftList ? <ShiftCard shiftList={shiftList} /> : component}
      </div>
    </>
  );
};

export default ShiftSchedule;
