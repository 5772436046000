import React from "react";
import { Link } from "react-router-dom";

const Welcome = () => {
  return (
    <div className="flex justify-center">
      <div className="text-center max-w-[500px] pt-20">
        <figure className="mb-6">
          <img className="m-auto" src="/images/illustration2.svg" alt="" />
        </figure>
        <h2 className="text-2xl font-bold mb-2 text-gray-800">
          Successfully completed Onboarding. <br />
          Your journey in our organisation starts here.
        </h2>
        <p className="mb-6">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries
        </p>
        <div className="inline-flex gap-4">
          <Link to="/dashboard" className="btn">
            Get back to Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
