import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import Select, { components } from "react-select";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { carryForwardTypeOptions } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import ToggleSwitch from "../../../ToggleSwitch/ToggleSwitch";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon } from "../../../../Icons/Icons";
import { genderOptions } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const AddNewLeaveTypeModal = ({ openModal, onCloseModal, makeApiCall }) => {
  const axiosPrivate = useAxiosPrivate();
  const [isCarryForward, setCarryForward] = useState(false);
  const [selectedCarryForwardType, setSelectedCarryForwardType] = useState(
    carryForwardTypeOptions[0]
  );
  const [isLop, setIsLop] = useState(false);
  const [isAutoIncrement, setIsAutoIncrement] = useState(false); 

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      priorDays: 30,
      carryForwardType: selectedCarryForwardType.value,
      status: 1,
      paid: 0,
      autoIncrementValue: 1, 
    },
  });

  const [isActive, setIsActive] = useState(getValues("status"));
  const [isRestrictPriorApplication, setRestrictPriorApplication] =
    useState(false);
  const [isCanRequestByEmployee, setIsCanRequestByEmployee] = useState(false);

  const getToggleState = (e) => {
    setValue("status", e ? 1 : 0);
    setIsActive(e);
  };

  const handleCloseModal = () => {
    onCloseModal();
  };

  const onSubmit = async (data) => {
    const {
      leaveTypeName,
      numberOfDaysAllowed,
      carryForward,
      carryForwardLimit,
      carryForwardType,
      carryForwardYears,
      status,
      description,
      restrictPriorApplication,
      priorDays,
      canRequestByEmployee,
      paid,
      gender,
      isAutoIncrementEnable,
      autoIncrementValue, 
    } = data;
    const reqBody = {
      leaveTypeName,
      numberOfDaysAllowed: Number(numberOfDaysAllowed || 0),
      carryForwardYears: Number(carryForwardYears) || 0,
      status,
      description,
      carryForwardLimit: Number(carryForwardLimit) || 0,
      carryForward: Number(carryForward),
      carryForwardType: Number(carryForwardType),
      restrictPriorApplication,
      priorDays: Number(priorDays),
      canRequestByEmployee: canRequestByEmployee ? 1 : 0,
      paid,
      isLop,
      gender,
      isAutoIncrementEnable : Number(isAutoIncrementEnable),
      autoIncrementValue: Number(autoIncrementValue), 
    };
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .post(`lms/leave/createLeaveType`, reqBody)
      .then((response) => {
        // console.log(response.data, "add leave type");
        const res = response?.data;
        if (res.status) {
          toast.success(res.message);
          makeApiCall();
          handleCloseModal();
        } else {
          toast.info(res.message);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong, try again");
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const getErrorMessage = (key) => {
    if (errors[key]) {
      return (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-2 ml-1">
          {errors[key].message}
        </span>
      );
    }
  };

  const handleCarryForwardType = (e) => {
    setSelectedCarryForwardType(e);
    setValue("carryForwardType", e.value);
  };

  return (
    <>
      <ReactSlidingPane
        isOpen={openModal}
        onRequestClose={handleCloseModal}
        width="450px"
      >
        <div className="max-w-[450px] text-black flex flex-col gap-3 h-full">
          <div className="flex justify-between items-center px-5 border-b pb-3">
            <h1 className="font-medium">Add Leave Type & Policy</h1>
            <button onClick={handleCloseModal}>{closeIcon}</button>
          </div>
          <form
            className="flex flex-col justify-between h-full"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="px-5">
              <div className="py-3">
                <label className="py-1">Leave type name</label>
                <input
                  type="text"
                  {...register("leaveTypeName", {
                    required: "This field is required",
                  })}
                />
                {getErrorMessage("leaveTypeName")}
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <label className="py-3">is LOP (Loss of Pay)</label>
                  <input
                    type="checkbox"
                    {...register("isLop")}
                    onChange={(e) => setIsLop(e.target.checked)}
                  />
                </div>
              </div>
              {isLop ? (
                <div className="border rounded-md p-2 bg-blue-500 text-white font-medium shadow-md">
                  This leave type is defined as Loss of Pay as the deduction in
                  salary due to leave taken by an employee
                </div>
              ) : null}
              {!isLop ? (
                <>
                  <div className="py-1">
                    <label className="py-3">Number of days allowed</label>
                    <input
                      type="text"
                      {...register("numberOfDaysAllowed", {
                        required: "This field is required",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid number",
                        },
                      })}
                    />
                    {getErrorMessage("numberOfDaysAllowed")}
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <label className="py-3">Carry forward</label>
                      <input
                        type="checkbox"
                        {...register("carryForward")}
                        onChange={(e) => setCarryForward(e.target.checked)}
                      />
                    </div>
                  </div>
                </>
              ) : null}

              {isCarryForward ? (
                <>
                  <div className="flex flex-col gap-2">
                    <div>
                      <label>Carry forward type</label>
                      <Select
                        components={{ DropdownIndicator }}
                        className="custom-select-container"
                        options={carryForwardTypeOptions}
                        value={selectedCarryForwardType}
                        onChange={(e) => handleCarryForwardType(e)}
                      />
                    </div>
                    <div>
                      <label>Carry forward limit</label>
                      <input
                        type="text"
                        {...register("carryForwardLimit", {
                          required: "This field is required",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a valid number",
                          },
                        })}
                      />
                      {getErrorMessage("carryForwardLimit")}
                    </div>

                    <div>
                      <label>Carry forward years</label>
                      <input
                        type="text"
                        {...register("carryForwardYears", {
                          required: "This field is required",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a valid number",
                          },
                        })}
                      />
                      {getErrorMessage("carryForwardYears")}
                    </div>
                  </div>
                </>
              ) : null}

              {!isLop ? (
                <>
                  <div className="py-3">
                    <div className="flex items-center gap-2">
                      {" "}
                      Paid
                      <ToggleSwitch
                        checked={getValues("paid")}
                        getToggleState={(e) => setValue("paid", e ? 1 : 0)}
                      />
                    </div>
                  </div>
                 
                </>
              ) : null}
               <div className="py-3">
              <div className="flex items-center gap-2">
                {" "}
                Enable Auto Increment
                <ToggleSwitch
                  checked={isAutoIncrement}
                  getToggleState={(e) => {
                    setIsAutoIncrement(e);
                    setValue("isAutoIncrementEnable", Number(e));
                    if (!e) {
                      setValue("autoIncrementValue", "");
                    }
                  }}
                />
              </div>
            </div>
            {isAutoIncrement && (
              <div className="py-3">
                <label>Auto Increment Value</label>
                <input
                  type="text"
                  {...register("autoIncrementValue", {
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid number",
                    },
                  })}
                />
                {getErrorMessage("autoIncrementValue")}
              </div>
            )}

              <div className="py-3">
                <div className="flex items-center gap-2">
                  {" "}
                  {isActive ? "Active" : "Inactive"}
                  <ToggleSwitch
                    checked={getValues("status")}
                    getToggleState={getToggleState}
                  />
                </div>
              </div>
              <div className="pt-3 flex items-center gap-3">
                <label>Restrict prior application</label>
                <input
                  type="checkbox"
                  {...register("restrictPriorApplication")}
                  onChange={(e) =>
                    setRestrictPriorApplication(e.target.checked)
                  }
                />
              </div>
              {isRestrictPriorApplication && (
                <div
                  className="p-2 text-xs text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
                  role="alert"
                >
                  <span className="font-medium">Info: </span>
                  Employee can only apply leave for prior dates
                </div>
              )}
              {!isLop ? (
                <div className=" flex pt-3 items-center gap-3">
                  <label>Can request by employee for additional leave</label>
                  <input
                    type="checkbox"
                    {...register("canRequestByEmployee")}
                    onChange={(e) =>
                      setIsCanRequestByEmployee(e.target.checked)
                    }
                  />
                </div>
              ) : null}
              {isCanRequestByEmployee && (
                <div
                  className="p-2 text-xs text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
                  role="alert"
                >
                  <span className="font-medium">Info: </span>
                  Employee can apply additional leave for this leave type if
                  they required
                </div>
              )}
              {/* gender */}
              <div className="py-3">
                <label>Applicable for</label>
                <select {...register("gender")} defaultValue="">
                  <option value="" disabled>
                    All Gender
                  </option>
                  {genderOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="py-3">
                <label>Prior days</label>
                <input
                  type="text"
                  {...register("priorDays", {
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid number",
                    },
                  })}
                />
              </div>

              <div className="pb-3">
                <label className="py-3">Description</label>
                <Controller
                  control={control}
                  name="description"
                  rules={{
                    required: "This field is required",
                  }}
                  render={({ field }) => {
                    return <ReactQuill {...field} theme="snow" />;
                  }}
                />
              </div>

              {getErrorMessage("description")}
            </div>

            <div className="flex justify-end gap-4 border-t py-3 px-5">
              <button
                type="button"
                className="btn btn--border"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button type="submit" className="btn">
                Add leave type
              </button>
            </div>
          </form>
        </div>
      </ReactSlidingPane>
    </>
  );
};

export default AddNewLeaveTypeModal;
