export const addNewShiftConst = {
  shiftType: {
    clockBased: 1,
    durationBased: 2,
  },
};

export const shiftTypeConst = {
  clockBased: 1,
  durationBased: 2,
};

export const weekDays = [
  { id: 1, day: "Monday" },
  { id: 2, day: "Tuesday" },
  { id: 3, day: "Wednesday" },
  { id: 4, day: "Thursday" },
  { id: 5, day: "Friday" },
  { id: 6, day: "Saturday" },
  { id: 7, day: "Sunday" },
];

export const shiftTagTypes = {
  employee: "employee",
  employeeType: "employee type",
  location: "location",
  department: "department",
  designation: "designation",
};


