import React, { useEffect, useState } from "react";
import OfferListData from "./OfferListData";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Pagination from "../../Pagination/Pagination";
import EmptyState from "../../EmptyState/EmptyState";
import PageLoader from "../../PageLoader/PageLoader";

const OfferList = (props) => {
  const { category } = props;
  const searchValue = props.searchData;
  const toggleState = props.toggleData;
  const axiosPrivate = useAxiosPrivate();
  const [candidateOfferList, setGetCandidate] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [loaderComponent, setLoaderComponent] = useState(<PageLoader />);

  let baseUrl = `onboarding/candidate/getCandidateOfferList?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC`;
  const [offerSearchUrl, setOfferSearchUrl] = useState(baseUrl);

  useEffect(() => {
    if (searchValue) {
      baseUrl = baseUrl + `&filter=${searchValue}`;
    }
    if (category && category != 0) {
      baseUrl = baseUrl + `&jobTitle=${category}`;
    }
    setOfferSearchUrl(baseUrl);
  }, [searchValue, category, pageNo]);

  const fetchOfferList = async () => {
    let isMounted = true;
    const controller = new AbortController();

    await axiosPrivate
      .get(offerSearchUrl, {
        signal: controller.signal,
      })
      .then((response) => {
        isMounted && console.log(response.data, "offer list");
        const res = response?.data;
        if (Number(res.statusCode) === 200) {
          setLoaderComponent(<PageLoader />);
          setTimeout(() => {
            setGetCandidate(res.candidateOfferList);
            setTotalRecords(res.totalRecords);
            setLoaderComponent(null);
          }, 500);
        } else if (res.message === "Inavlid page number") {
          setPageNo(1);
        } else {
          setGetCandidate(null);
          setLoaderComponent(<EmptyState />);
        }
      })
      .catch((err) => {
        console.error(err);
        setGetCandidate(null);
        setLoaderComponent(<EmptyState />);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchOfferList();
  }, [pageNo, toggleState, offerSearchUrl, props.makeApiCall]);

  return (
    <>
      <div className="relative mb-10">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500">
          <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-gray-800 border-b border-t dark:border-gray-200 tracking-wider">
            <tr>
              <th scope="col" className="px-10 py-3">
                ID
              </th>
              <th scope="col" className="px-10 py-3">
                candidate Name
              </th>
              <th scope="col" className="px-10 py-3">
                Offer sent date
              </th>
              <th scope="col" className="px-10 py-3">
                Due Date
              </th>
              <th scope="col" className="px-10 py-3">
                Designation
              </th>
              <th scope="col" className="px-10 py-3">
                Candidate Status
              </th>
              <th scope="col" className="px-10 py-3">
                Manager Status
              </th>
              <th scope="col" className="px-10 py-3">
                HR status
              </th>
              <th scope="col" className="px-10 py-3">
                Password setup status
              </th>
              <th scope="col" className="px-10 py-3"></th>
            </tr>
          </thead>
          <tbody className="">
            {candidateOfferList &&
              candidateOfferList.map((val, key) => {
                return <OfferListData requestdata={val} key={key} />;
              })}
          </tbody>
        </table>
        {loaderComponent}
        {candidateOfferList && (
          <Pagination
            page={{
              pageNo,
              setPageNo,
              pageSize,
              setPageSize,
              totalRecords,
            }}
          />
        )}
      </div>
    </>
  );
};

export default OfferList;
