import React, { useState } from "react";
import Aside from "../Dashboard/Aside/Aside";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "../../components/DashboardSidebar/DashboardSidebar";

const TaskModule = () => {
  const [submenu, setSubmenu] = useState([
    {
      text: "Approval",
      link: "/task/approval/leave-applications",
      icon: "ico-down",
      roles: [2, 4],
      menu: [
        {
          text: "Regularization",
          link: "#",
          roles: [2, 4],
        },
        {
          text: "Leave Applications",
          link: "/task/approval/leave-applications",
          roles: [2, 4],
        },
        {
          text: "Reimbursement",
          link: "#",
          roles: [2, 4],
        },
        {
          text: "Separation",
          link: "#",
          roles: [2, 4],
        },
        {
          text: "Task",
          link: "#",
          roles: [2, 4],
        },
      ],
      status: true,
    },
    {
      text: "Task",
      link: "#",
      icon: "ico-down",
      roles: [1, 2, 4, 6],
      menu: [
        {
          text: "Task",
          link: "#",
          roles: [1, 2, 4, 6],
        },
      ],
      status: false,
    },
  ]);
  return (
    <div className="lg:flex ">
      <DashboardSidebar />
      <div className="flex flex-1">
        <Aside submenu={submenu} setmenu={setSubmenu} />
        <Outlet />
      </div>
    </div>
  );
};

export default TaskModule;
