import React, { useEffect, useState } from "react";
import ToggleSwitch from "../../../../components/ToggleSwitch/ToggleSwitch";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

const CustomizeWidgetList = ({
  handleWidgetUpdate,
  closeCustomizedWidgetList,
}) => {
  const [widgets, setWidgets] = useState([]);
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [sidePanelVisible, setSidePanelVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    axiosPrivate.get("employee/listOfWidgets").then((response) => {
      if (response.data.status) {
        // console.log("Response from server for list", response.data)
        setWidgets(response.data.data);
        setLoading(false);
      } else {
        // console.log("Error while fetching widget data:", response.data.error);
        setLoading(false);
      }
    });
  }, [axiosPrivate]);

  const closeSidePanel = () => {
    setSelectedWidget(null);
    closeCustomizedWidgetList();
  };
  const toggleWidget = (widgetId) => {
    const updatedWidgets = widgets.map((widget) => {
      if (widget.widget_id === widgetId) {
        // console.log("Reponse from server for toggle", widgetId)
        const updatedViewStatus = widget.view_status === 1 ? 0 : 1;
        return { ...widget, view_status: updatedViewStatus };
      }
      return widget;
    });
    setWidgets(updatedWidgets);
    const updatedWidget = updatedWidgets.find(
      (widget) => widget.widget_id === widgetId
    );
    axiosPrivate
      .put("employee/updateWidgetViewStatus", {
        widget_mapping_id: updatedWidget.widget_mapping_id,
        view_status: updatedWidget.view_status,
      })
      .then((response) => {
        if (response.data.status) {
          // console.log("Response from server for update", response.data)
          toast.success("Employee widget view status has been updated.");
          handleWidgetUpdate();
        } else {
          // console.log("error in  toggle")
          setWidgets((prevWidgets) =>
            prevWidgets.map((widget) => ({ ...widget }))
          );
          toast.error("Unable to disable, kindly check widget settings.");
        }
      })
      .catch((error) => {
        // console.error("Error updating widget view status:", error);
        toast.error("An error occurred while updating the widget.");
      });
  };

  const handleToggleWidget = (widgetId, newToggleState) => {
    // console.log("Handle Toggle change", widgetId)
    toggleWidget(widgetId);
  };

  return (
    <div className="relative h-full">
      {sidePanelVisible && (
        <div
          onClick={closeSidePanel}
          className="fixed top-20 left-0 w-[70%] h-full bg-black opacity-40"
        ></div>
      )}
      <div
        className={`fixed top-20 right-0 w-[30%] h-full bg-white ${
          sidePanelVisible ? "border-l border-gray-400" : ""
        }`}
      >
        <div className="flex flex-col h-full p-5 pl-10 text-black">
          <div className="flex justify-between items-center p-4 border-b border-b-gray-300 py-3">
            <h2 className="text-lg font-semibold text-center">
              Dashboard Widgets List
            </h2>
          </div>

          <div className="overflow-y-auto">
            <ul>
              <div className="flex justify-between top-30 items-center border-b border-b-gray-300 py-3">
                <h2 className="text-base font-medium text-center">
                  Customize Dashboard Widgets
                </h2>
              </div>
              {widgets.length === 0 ? (
                <li>
                  <span className="flex items-center py-2 text-gray-800">
                    No widget has been assigned!{" "}
                  </span>
                </li>
              ) : (
                widgets.map((widget) => (
                  <li key={widget.widget_id} className="flex items-center py-2">
                    <span className="text-lg">{widget.widget_name}</span>
                    <div className="ml-auto">
                      <ToggleSwitch
                        checked={widget.view_status === 1}
                        getToggleState={(newToggleState) =>
                          handleToggleWidget(widget.widget_id, newToggleState)
                        }
                        onHandleClose={closeSidePanel}
                      />
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      </div>
      <div
        className="absolute right-0 h-full w-1/2 bg-white"
        style={{ zIndex: 10 }}
      >
        {sidePanelVisible && (
          <div className="h-full p-10 pl-20">
            <h2>{selectedWidget ? selectedWidget.widget_name : ""}</h2>
          </div>
        )}
      </div>
    </div>
  );
};
export default CustomizeWidgetList;
