import React, { useEffect, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import ViewEventFullListModal from "./ViewEventFullListModal";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CalendarStyle.scss";

const localizer = momentLocalizer(moment);
const DAY_VIEW = "day";
const MONTH_VIEW = "month";

const BigCalendar = ({ handleChangeDate, myEventsList }) => {
  const bigCalenderRef = useRef(); //ref for big calendar
  const initialCalenderStateSet = useRef(true); // ref to keep the current state to set the changes to buttons , set this false after setting the event listners to prevent multiple apply of event listeners to btn
  const [isViewFullEventsOpen, setViewFullEventsOpen] = useState(false);
  const [eventDataForModal, setEventDataForModal] = useState([]);

  const nextMonthBtn = () => {
    console.log("next month");
    // handleChangeDate && handleChangeDate("nextMonth");
  };

  const prevMonthBtn = () => {
    console.log("prev month");
    // handleChangeDate && handleChangeDate("prevMonth");
  };

  useEffect(() => {
    if (bigCalenderRef.current && initialCalenderStateSet.current) {
      //next and previous buttont changing text to icons
      try {
        const rbc_toolbar =
          document.getElementsByClassName("rbc-toolbar")[0]?.children[0];
        const backButton = rbc_toolbar?.children[1];
        const nextButton = rbc_toolbar?.children[2];
        backButton.innerHTML = "";
        nextButton.innerHTML = "";
        backButton.classList.add("ico-left");
        nextButton.classList.add("ico-right");
        backButton.addEventListener("click", prevMonthBtn);
        nextButton.addEventListener("click", nextMonthBtn);
        initialCalenderStateSet.current = false;
      } catch (err) {
        console.warn(err);
      }
    }
  }, [bigCalenderRef]);

  const onRangeChange = (e) => {
    handleChangeDate && handleChangeDate(e);
  };

  const handleViewEventList = (status, dataOrView) => {
    if (myEventsList.length) {
      setViewFullEventsOpen(status);
      if (status) {
        setTimeout(() => {
          let date;
          if (dataOrView === DAY_VIEW) {
            date = bigCalenderRef.current.props.date;
          } else {
            date = dataOrView.start;
          }
          const formattedDate = moment(date).format("DD-MM-YYYY");
          const filteredData = myEventsList.filter(
            (item) => moment(item.start).format("DD-MM-YYYY") === formattedDate
          );
          setEventDataForModal(filteredData);
        }, 800);
      } else {
        setEventDataForModal([])
      }
    }
  };

  return (
    <div className="w-full h-screen">
      <ViewEventFullListModal
        open={isViewFullEventsOpen}
        close={() => handleViewEventList(false)}
        events={eventDataForModal || []}
        bigCalenderRef={bigCalenderRef}
      />
      <Calendar
        ref={bigCalenderRef}
        localizer={localizer}
        startAccessor={"start"}
        endAccessor={"end"}
        onView={(e) => handleViewEventList(true, e)}
        // views={{ month: true }} // avoid this to prevent the range change issue
        view={MONTH_VIEW} // use this intead of views
        events={myEventsList || []}
        messages={{
          noEventsInRange: "No events",
        }}
        onRangeChange={onRangeChange}
        onSelectEvent={(e) => handleViewEventList(true, e)} // event for clicking on events listed in the calendar
        toolbar={true}
        eventPropGetter={(event, start, end, isSelected) => ({
          event,
          start,
          end,
          isSelected,
          style: {
            color: event.titleColor || "#000",
            backgroundColor: event.backGroundColor,
            fontWeight: 500,
            border: `1px solid ${event.borderColor}`,
            borderRadius: 3,
          },
        })}
      />
    </div>
  );
};

export default BigCalendar;
