import React, { Fragment, useEffect, useState } from "react";
import SlidingPanel from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./Slidingpane.scss";
import { AiOutlineEdit, AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FaCamera } from "react-icons/fa";
import Line from "../Line/Line";
import { useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Avatar from "../Avatar/Avatar";
import IdleStateValidator from "../../components/IdleStateValidator";

const Userprofile = ({ state, setState, updateUserProfile }) => {

  const profile = useSelector((state) => state.user.userProfile);
  const { isPaneOpen } = state;
  const [editProfile, setEditProfile] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [uploadedProfileImage, setUploadedProfileImage] = useState(null);
  const [errMsg, setErrMsg] = useState([]);
  const [previewOfProfileImage, setPreviewOfProfileImage] = useState(null);

  const {
    first_name,
    middle_name,
    last_name,
    department_name,
    designation_title,
    line_manager_name,
    location_name,
    phone,
    email,
    photo,
    personal_email
  } = profile;

  useEffect(() => {
    setPreviewOfProfileImage(`${photo}`);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  // Validating the uploaded image file and also creating url for the preview ***
  const validateUploadedImageFile = (e) => {
    setErrMsg("");
    let uploadedFile = e.target.files[0];
    let fileSize = uploadedFile.size;
    let fileType = uploadedFile.type;
    if (fileType == "image/jpeg" || fileType == "image/png") {
      if (fileSize < 1033414) {
        setUploadedProfileImage(uploadedFile);
        setPreviewOfProfileImage(URL.createObjectURL(uploadedFile));
      } else {
        setErrMsg(
          "Uploaded image file is too large, try uploading image less than 1 MB"
        );
      }
    } else {
      setErrMsg(
        "Uploaded file is not a valid image. Only JPG and PNG files are allowed"
      );
    }
  };
  // Validation of file upload ended ***

  // Update profile API Call ** Starts **
  const updateProfile = async (data) => {
    setErrMsg("");
    const { personal_email, first_name, last_name, middle_name, phone } = data;
    let formData = new FormData();
    uploadedProfileImage && formData.append("photo", uploadedProfileImage);
    formData.append("personal_email", personal_email);
    formData.append("phone", phone);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("middle_name", middle_name);

    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .put(`employee/updateProfile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // isMounted && console.log(response.data);
        const { status } = response.data;
        if (status) {
          updateUserProfile({
            email: email,
            first_name: first_name,
            last_name: last_name,
            middle_name: middle_name,
            phone: phone,
            photo: previewOfProfileImage,
          });
          setEditProfile(false);
          toast.success("Profile updated successfully");
        } else {
          const { message } = response.data;
          setErrMsg(message);
        }
      })
      .catch((err) => {
        console.error(err);
        const { message } = err.response.data;
        setErrMsg(message);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  // Update profile API Call ** Ends **

  return (
    <Fragment>
      <IdleStateValidator/>
      <div>
        <div className="w-3/4">
          <div>
            <SlidingPanel
              isOpen={isPaneOpen}
              width="480px"
              onRequestClose={() => {
                setState(false);
                setPreviewOfProfileImage(`${photo}`);
                setEditProfile(false);
              }}
            >
              <div>
                <h2 className="p-3">Profile</h2>
              </div>
              <Line />
              <span className="flex items-center font-medium tracking-wide text-red-500 text-sm ml-3 mt-1">
                {errMsg}
              </span>
              <div className="w-full px-4 ">
                <div className=" relative flex justify-center item-center ">
                  <Avatar
                    height={"20"}
                    width={"20"}
                    image={previewOfProfileImage}
                    isProfileSideBar = {true}
                  />
                  {editProfile ? (
                    <label className="cursor-pointer">
                      <span className="bottom-0 left-56 top-16 absolute  w-4 h-4 bg-blue rounded-full">
                        <div className=" flex justify-center items-center  w-[30px] h-[30px] border-2 border-solid  bg-green-600 text-white rounded-full">
                          <FaCamera className="align-center" />
                        </div>
                        <input
                          type="file"
                          className="hidden"
                          alt="Profile picture"
                          accept="image/jpeg, image/png"
                          onChange={(e) => {
                            validateUploadedImageFile(e);
                          }}
                        ></input>
                      </span>
                    </label>
                  ) : null}
                </div>
                <h2 className="text-lg text-center font-semibold leading-6 text-gray-800 not-italic pt-3">
                  {first_name + " " + middle_name + " " + last_name}
                </h2>
                <p className="text-sm text-center font-normal leading-5 text-gray-500 not-italic pt-1">
                  {designation_title}
                </p>
                {!editProfile ? (
                  <div className="">
                    <div className="md:text-left py-4 border border-solid border-r-0 border-l-0 border-gray-200 mt-7">
                      <p className="pb-3 flex items-center justify-between">
                        <div className="text-sm flex items-center font-medium leading-5 text-gray-800  pl-1">
                          <span className="mr-1 ico-email"></span>
                          {email}
                        </div>
                        <button
                          className="ico-edit inline-block mr-[10px] float-right"
                          onClick={() => setEditProfile(true)}
                        ></button>
                      </p>
                      <p className="pb-3 flex items-center ">
                        <span className="ico-phone ml-1"></span>
                        <span className="text-sm font-medium leading-5 text-gray-800 inline pl-1">
                          {phone}
                        </span>
                      </p>
                    </div>
                    <div className="md:text-left py-4 border border-solid border-r-0 border-l-0 border-t-0 border-gray-200">
                      <p className="text-sm font-medium leading-5 text-gray-500 pb-1">
                        Department
                      </p>
                      <p className="text-sm font-normal leading-5 text-gray-800 pb-3">
                        {department_name}
                      </p>
                      <p className="text-sm font-medium leading-5 text-gray-500 pb-1">
                        Office
                      </p>
                      <p className="text-sm font-normal leading-5 text-gray-800 pb-3">
                        {location_name}
                      </p>
                      <p className="text-sm font-medium leading-5 text-gray-500 pb-1">
                        Line Manager
                      </p>
                      <p className="text-sm font-normal leading-5 text-gray-800 pb-3">
                        {line_manager_name}
                      </p>
                    </div>
                  </div>
                ) : (
                  <form
                    onSubmit={handleSubmit(updateProfile)}
                    className="min-h-[600px] flex"
                  >
                    <div className="w-full flex flex-col justify-between">
                      <div className="md:text-left border border-solid border-r-0 border-l-0 border-gray-200 mt-7">
                        <div className="mt-3.5">
                          <div className="text-sm font-medium leading-5 text-gray-800 inline ">
                            <div className="search relative">
                              <input
                                id="first-name"
                                type="text"
                                {...register("first_name", {
                                  required: "This field is required",
                                })}
                                defaultValue={first_name}
                              />
                              <label
                                for="first-name"
                                className="absolute text-base font-semibold dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] left-3 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 focus:bg-white peer-focus:-translate-y-4 bg-white px-1.5"
                              >
                                First name
                              </label>
                              {errors?.first_name && (
                                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1  ml-1">
                                  {errors.first_name.message}
                                </span>
                              )}
                              <div className="icon-div absolute left-2 top-3 mt-1">
                                <i className="text-base ">
                                  <AiOutlineEdit />
                                </i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3.5">
                          <div className="text-sm font-medium leading-5 text-gray-800 inline ">
                            <div className="search relative">
                              <input
                                id="middle-name"
                                type="text"
                                {...register("middle_name", {

                                })}
                                defaultValue={middle_name}
                              />
                              <label
                                for="middle-name"
                                className="absolute text-base font-semibold dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] left-3 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 focus:bg-white peer-focus:-translate-y-4 bg-white px-1.5"
                              >
                                Middle name
                              </label>

                              {errors?.middle_name && (
                                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1  ml-1">
                                  {errors.middle_name.message}
                                </span>
                              )}
                              <div className="icon-div absolute left-2 top-3 mt-1">
                                <i className="text-base ">
                                  <AiOutlineEdit />
                                </i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3.5">
                          <div className="text-sm font-medium leading-5 text-gray-800 inline ">
                            <div className="search relative">
                              <input
                                placeholder=" "
                                id="last-name"
                                type="text"
                                {...register("last_name", {
                                  required: "This field is required",
                                })}
                                defaultValue={last_name}
                              />
                              <label
                                for="last-name"
                                className="absolute text-base font-semibold dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] left-3 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 focus:bg-white peer-focus:-translate-y-4 bg-white px-1.5"
                              >
                                Last name
                              </label>
                              {errors?.last_name && (
                                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1  ml-1">
                                  {errors.last_name.message}
                                </span>
                              )}
                              <div className="icon-div absolute left-2 top-3 mt-1">
                                <i className="text-base ">
                                  <AiOutlineEdit />
                                </i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3.5">
                          <div className="text-sm font-medium leading-5 text-gray-800 inline ">
                            <div className="search relative">
                              <input
                                type="text"
                                id="email"
                                {...register("personal_email", {

                                  pattern: {
                                    value:
                                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Please enter a valid email",
                                  },
                                })}
                                defaultValue={personal_email}
                              />
                              <label
                                for="email"
                                className="absolute text-base font-semibold dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] left-3 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 focus:bg-white peer-focus:-translate-y-4 bg-white px-1.5"
                              >
                                Personal Email{" "}
                              </label>

                              {errors?.email && (
                                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1  ml-1">
                                  {errors.email.message}
                                </span>
                              )}
                              <div className="icon-div absolute left-2 top-3 mt-1">
                                <i className="text-base ">
                                  <AiOutlineMail />
                                </i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3.5">
                          <div className="text-sm font-medium leading-5 text-gray-800 inline ">
                            <div className="search relative">
                              <input
                                type="text"
                                id="phone"
                                {...register("phone", {

                                  pattern: {
                                    value: /^(\+?\d{1,3}[- ]?)?\d{10}$/,
                                    message:
                                      "Please enter a valid phone number",
                                  },
                                })}
                                defaultValue={phone}
                              />
                              <label
                                for="phone"
                                className="absolute text-base font-semibold dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] left-3 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 focus:bg-white peer-focus:-translate-y-4 bg-white px-1.5"
                              >
                                Phone
                              </label>
                              {errors?.phone && (
                                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                  {errors.phone.message}
                                </span>
                              )}
                              <div className="icon-div absolute left-2 top-4">
                                <AiOutlinePhone />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="flex mt-10">
                          <div className="pb-2 px-5 flex justify-end  my-3 border-t-2 pt-7 w-full">
                            <button
                              type="button"
                              className="btn btn--border text-[14px] mr-2"
                              onClick={() => {
                                setEditProfile(false);
                                setPreviewOfProfileImage(`${profile.photo}`);
                                setUploadedProfileImage(null);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className=" mr-3  btn text-[13px]"
                              type="submit"
                            >
                              Update
                            </button>
                          </div>
                        </div> */}
                      </div>
                      <div className="flex">
                          <div className="pb-2 px-5 flex justify-end  my-3 border-t-2 pt-7 w-full">
                            <button
                              type="button"
                              className="btn btn--border text-[14px] mr-2"
                              onClick={() => {
                                setEditProfile(false);
                                setPreviewOfProfileImage(`${profile.photo}`);
                                setUploadedProfileImage(null);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className=" mr-3  btn text-[13px]"
                              type="submit"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                    </div>
                  </form>
                )}
              </div>
            </SlidingPanel>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Userprofile;
