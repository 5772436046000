
const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  
  export const getCurrentMonthDates = () => {
    const currentDate = new Date();
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const formattedFirstDay = formatDate(firstDay);
    const formattedLastDay = formatDate(lastDay);
    return { firstDay: formattedFirstDay, lastDay: formattedLastDay };
  };
  
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };
  
  export const getCurrentMonthNames = () => {
    const currentMonthIndex = new Date().getMonth();
    return monthNames.slice(0, currentMonthIndex + 1);
  };

  export const getMonthName = (monthIndex) => {
    return monthNames[monthIndex - 1]; 
  };