import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import { closeIcon } from "../../Icons/Icons";
import IdleStateSessionTimeoutTimer from "./IdleStateSessionTimeoutTimer";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { ActionCreators } from "../../redux/actions/profile";
import { useNavigate } from "react-router-dom";

const IdleStateValidator = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    let idleTimer;
    const resetTimer = () => {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(() => {
        setShowModal(true);
      }, 720000); // 12 minutes of idle will trigger the modal
    };

    resetTimer();

    const events = ["mousemove", "keydown", "mousedown", "touchstart", "scroll", "mouseup"];
    const resetTimerHandler = () => resetTimer();
    events.forEach((event) => {
      window.addEventListener(event, resetTimerHandler);
    });

    return () => {
      clearTimeout(idleTimer);
      events.forEach((event) => {
        window.removeEventListener(event, resetTimerHandler);
      });
    };
  }, []);

  const logOut = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .post("auth/logout", {
        signal: controller.signal,
      })
      .then((response) => {
        // isMounted && console.log(response.data);
        const { status } = response.data;
        if (status) {
          dispatch(ActionCreators.logout());
          navigate("/login", { replace: true });
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  return (
    <Modal center open={showModal} showCloseIcon={false}>
      <div className="flex flex-col gap-3 max-w-[400px]">
        <div className="flex justify-end">
          <button onClick={handleCloseModal}>{closeIcon}</button>
        </div>
        <div>
          <h1 className="text-center font-medium text-gray-800 text-lg">
            You will be logged out soon
          </h1>
        </div>
        <div className="p-2">
          <p>
            For your security, we log you out automatically when you have not
            been active for a certain period of time
          </p>
        </div>
        <div className="flex justify-center">
          <IdleStateSessionTimeoutTimer logOut={logOut} />
        </div>
        <div className="flex justify-center gap-3 py-3">
          <button type="button" className="btn btn--border" onClick={logOut}>
            Log out now
          </button>
          <button type="button" onClick={handleCloseModal} className="btn">
            Continue session
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default IdleStateValidator;
