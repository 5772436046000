import React from "react";
import ShiftAccordion from "./ShiftAccordion";

const ShiftCard = ({ shiftList }) => {
  return (
    <div>
      {shiftList &&
        shiftList.map((item, index) => (
          <ShiftAccordion data={item} key={index} index={index} />
        ))}
    </div>
  );
};

export default ShiftCard;
