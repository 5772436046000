import React, { useEffect, useState } from "react";
import EditHoliday from "../EditHoliday/EditHoliday";
import formatDate from "../../../../utils/FormatDate/formatDate";

const HolidayListData = ({ data, index, policyMapping, setSelectedPolicy }) => {
  const [isEditHoliday, setIsEditHoliday] = useState(false);
  const name = data.holiday_name;
  const date = formatDate(data.date);
  const state = data.state_name;
  const country = data.country_nmae;
  const [isIncludedInPolicy, setIsIncludedInPolicy] = useState(false);
  const [handleChecked, setHandleChecked] = useState(false);
  const holidayType = data.holiday_type_name;

  useEffect(() => {
    const checkIsIncluded = policyMapping
      ? policyMapping.find((i) => i.holiday_id === data.holiday_id)
      : false;
    if (checkIsIncluded) {
      setSelectedPolicy((prev) => {
        const isExist = prev.update.find((i) => i === data.holiday_id);
        if (isExist) {
          return prev;
        } else {
          return {
            ...prev,
            update: [...prev.update, data.holiday_id],
          };
        }
      });
    }
    setIsIncludedInPolicy(checkIsIncluded);
    setHandleChecked(checkIsIncluded ? true : false);
  }, [data]);

  const handleCheck = (e) => {
    const value = e.target.checked;
    const method = isIncludedInPolicy ? "update" : "insert";
    if (value) {
      setSelectedPolicy((prev) => {
        const isExist = prev[method].find((i) => i === data.holiday_id);
        if (isExist) {
          return prev;
        } else {
          return {
            ...prev,
            [method]: [...prev[method], data.holiday_id],
          };
        }
      });
    } else {
      setSelectedPolicy((prev) => {
        return {
          ...prev,
          [method]: prev[method].filter((i) => i !== data.holiday_id),
        };
      });
    }
    setHandleChecked(value)
  };

  return (
    <>
      <EditHoliday open={isEditHoliday} close={() => setIsEditHoliday(false)} />
      <tr
        className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-200"
        key={index}
      >
        <td className="px-10 py-4">
          {" "}
          <input
            type="checkbox"
            checked={handleChecked}
            onClick={handleCheck}
          />
        </td>
        <td className="px-10 py-4">{name}</td>
        <td className="px-10 py-4">{date.dateString2}</td>
        {/* <td className="px-10 py-4">{holidayType}</td> */}
        <td className="px-10 py-4">{holidayType}</td>
        <td className="px-10 py-4">{country}</td>

        <td className="py-4 flex items-center justify-center gap-5 border-none">
          <img
            src="/images/edit-black-icon.svg"
            alt=""
            className="cursor-pointer"
            onClick={() => setIsEditHoliday(true)}
          />
          <img
            src="/images/delete-menu-icon.svg"
            alt=""
            className="cursor-pointer"
          />
        </td>
      </tr>
    </>
  );
};

export default HolidayListData;
