import React, { useState, useEffect } from "react";
import AttendanceActivityClock from "../MyAttendance/AttendanceActivityClock";
import LoadingButton from "../../../Buttons/LoadingButton";
import formatDate from "../../../../utils/FormatDate/formatDate";

const CLOCK_IN = 1;
const CLOCK_OUT = 2;
const AttendanceLoginInfo = () => {
  const [attendanceActivityBtnLoader, setAttendanceActivityBtnLoader] =
    useState(false);
  const [attendanceActivityType, setAttendanceActivityType] = useState(null);
  const isClockInActivity = attendanceActivityType === CLOCK_IN;
  const [clockActivity, setClockActivity] = useState();
  const clockInOrOut = (activity) => {
    setClockActivity(activity);
  };

  const getAttendanceActivity = (activityType) => {
    setAttendanceActivityType(activityType);
  };
  const getClockInOutBtnLoaderState = (btnStatus) => {
    setAttendanceActivityBtnLoader(btnStatus);
  };

  const today = formatDate(new Date()).dateString;

  const [attendanceActivityData, setAttendanceActivityData] = useState(null);
  const [clockActivityMsg, setClockActivityMsg] = useState({
    firstClockInTime: "--:--",
    lastClockInTime: "--:--",
    lastClockOutTime: "--:--",
  });

  useEffect(() => {
    if (attendanceActivityData) {
      const { firstClockIn, lastClockIn, lastClockOut } =
        attendanceActivityData;
      const firstClockInTime =
        formatDate(firstClockIn?.split(".")?.[0])?.localString2 || "--:--";
      const lastClockOutTime =
        formatDate(lastClockOut?.split(".")?.[0])?.localString2 || "--:--";
      const lastClockInTime =
        formatDate(lastClockIn?.split(".")?.[0])?.localString2 || "--:--";
      setClockActivityMsg({
        firstClockInTime,
        lastClockInTime,
        lastClockOutTime,
      });
    }
  }, [attendanceActivityData]);

  return (
      <div className="border rounded-md p-5 h-full w-full">
        <div className="flex justify-between font-medium">
          <p>Attendance</p>
          <p className="flex items-center gap-2">{today}</p>
        </div>
        <div className="flex gap-2 justify-center py-12 font-extrabold text-5xl">
          <AttendanceActivityClock
            clockActivity={clockActivity}
            getClockInOutBtnLoaderState={getClockInOutBtnLoaderState}
            getAttendanceActivity={getAttendanceActivity}
            setAttendanceActivityData={setAttendanceActivityData}
            setClockActivityMsg={setClockActivityMsg}
          />
        </div>
        <div>
          <span className="flex  justify-center py-2">
            First Clock-in : {clockActivityMsg.firstClockInTime}
          </span>
        </div>
        <div className="">
          {attendanceActivityBtnLoader ? (
            <LoadingButton class={"w-full"} bg={"btn--border"} />
          ) : (
            <button
              onClick={() =>
                clockInOrOut(isClockInActivity ? CLOCK_IN : CLOCK_OUT)
              }
              className={`w-full btn btn--${
                isClockInActivity ? "green" : "red"
              }`}
            >
              Clock-{isClockInActivity ? "In" : "Out"}
              <i className="ico-clock ml-4"></i>
            </button>
          )}
        </div>

        <div className="gap-4 py-4">
          <div className="mb-5 h-1 bg-gray-200">
            <div className="h-1 bg-gray-500"></div>
          </div>

          <div className="grid gap-x-8 gap-y-4 grid-cols-3">
            <p>08:00AM</p>
            <p>Regualar Shift</p>
            <p>16:00PM</p>
          </div>
        </div>
        <div className="flex gap-2 justify-center py-2"></div>
      </div>
  );
};

export default AttendanceLoginInfo;
