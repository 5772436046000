import React from "react";
import styles from "./BasicInfo.module.scss";
import { Link } from "react-router-dom";
import Avatar from "../../../../components/Avatar";
import { useSelector } from "react-redux";
import SimpleBar from "simplebar-react";

const BasicInfo = () => {
  const profile = useSelector((state) => state.user.userProfile);
 
  return (
    <div className="border rounded-md p-3 w-full h-full">
      <SimpleBar style={{ maxHeight: 360 }}>
        <div className="flex items-center p-4 pb-2">
          <Avatar classes="mr-2" image={profile.photo} />
          <div className="flex-1">
            <div className="flex">
              <h2>{`${profile.first_name} ${profile.last_name}`}</h2>
            </div>
            <span className="">{profile.designation_title}</span>

            <p className="text-xs">{profile.department_name}</p>
          </div>
          <div>
            <Link to="/dashboard/employee-settings">
              <i className="ico-edit text-lg text-gray-600"></i>
            </Link>
          </div>
        </div>
        <ul className={styles.list}>
          <li>
            <div className={styles.icon}>
              <i className="ico-office text-lg"></i>
            </div>
            <div className={styles.text}>
              <div>
                <h3> {`${profile.location_name} Office`}</h3>
                <p>Work Location</p>
              </div>
            </div>
          </li>
          <li>
            <div className={styles.icon}>
              <i className="ico-briefcase text-lg"></i>
            </div>
            <div className={styles.text}>
              <div>
              <h3>
              {profile?.shift_name === "No Shift Assigned" ? 
              ("No shift assigned")
              : profile?.shift_name && !profile?.time_from && !profile?.time_to ? 
              (`Not a working day (${profile.shift_name})`)
              : profile?.shift_name && profile?.time_from && profile?.time_to ? 
               (`${profile.time_from} AM To ${profile.time_to} PM (${profile.shift_name})`) 
              :("No shift details found")
              }
                </h3>
                <p>My shift</p>
              </div>
              <div className="ml-auto">
                <span className="inline-block p-2">
                <Link to="/attendance/self-service/my-shift">
                <i className="ico-right text-xs"></i>
            </Link>
               
                </span>
              </div>
            </div>
          </li>
          {/* <li>
            <div className={styles.icon}>
              <i className="ico-card text-lg"></i>
            </div>
            <div className={styles.text}>
              <div>
                <h3>₹40,52,512.00</h3>
                <p>Earning</p>
              </div>
              <div className="ml-auto">
                <span className="inline-block p-2">
                  <i className="ico-right text-xs"></i>
                </span>
              </div>
            </div>
          </li> */}
          {/* <li>
            <div className={styles.icon}>
              <i className="ico-finance text-lg"></i>
            </div>
            <div className={styles.text}>
              <div>
                <h3>₹4,52,512.00</h3>
                <p>Deductions</p>
              </div>
              <div className="ml-auto">
                <span className="inline-block p-2">
                  <i className="ico-right text-xs"></i>
                </span>
              </div>
            </div>
          </li> */}
        </ul>
      </SimpleBar>
    </div>
  );
};

export default BasicInfo;