import React, { Fragment, useState } from "react";
import "../ApplyLeaveModal/ApplyLeaveModal.scss";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import LoadingButton from "../Buttons/LoadingButton";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import formatDate from "../../utils/FormatDate/formatDate";
import Select from "react-select";

const CancelLeaveModal = ({ leave, open, close, makeApiCall }) => {
  const [isLoaderBtn, setLoaderBtn] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const leaveApplicationId = leave?.leave_application_id;
  const leaveName = leave?.leave_name;
  const fromDate = formatDate(leave?.from_date);
  const toDate = formatDate(leave?.to_date);

  const handleCancel = () => {
    setErrMsg("");
    close();
  };

  const handleReasonInput = (e) => {
    setErrMsg("");
    setCancellationReason(e.target.value);
  };

  const handleSubmit = async () => {
    !leaveApplicationId &&
      toast.error(
        "Something went wrong with the leave application, please try again."
      );
    if (cancellationReason && leaveApplicationId) {
      setErrMsg("");
      setLoaderBtn(true);
      let isMounted = true;
      const controller = new AbortController();
      await axiosPrivate
        .put(`lms/leave/cancelLeaveRequest`, {
          leaveApplicationId,
          remark: cancellationReason,
        })
        .then((response) => {
          // isMounted && console.log(response.data);
          const res = response?.data;
          if (res.status) {
            toast.success(res.message);
            close();
            makeApiCall();
            // setState(false);
          } else if (res.message === "KEY_EXPIRED") {
            setLoaderBtn(false);
            toast.error("NO SERVER RESPONSE, TRY LOGIN AGAIN.");
          } else {
            setLoaderBtn(false);
          }
        })
        .catch((err) => {
          // console.error(err);
          setLoaderBtn(false);
          const res = err?.response?.data;
          if (res?.error === "canceled") {
            toast.info(res?.message);
          } else {
            toast.error(res?.message || "Something went wrong! please try again.");
          }
        });

      return () => {
        isMounted = false;
        controller.abort();
      };
    } else {
      setErrMsg("Please fill the required fields.");
    }
  };

  return (
    <Fragment>
      <Modal open={open} onClose={close} center>
        <div className="flex flex-col min-w-[400px]">
          <div>
            <div className="px-5 text-black">
              <h2 className="pb-3">Cancel Request</h2>
              <p className="font-medium">
                Are you sure you want to cancel your leave request?
              </p>
              <div className="py-3">
                <p className="font-medium">{leaveName}</p>
                <p className="text-gray-500 text-xs">
                  {fromDate.dateString2} - {toDate.dateString2}
                </p>
              </div>
              {errMsg ? (
                <span className="text-red-600 font-medium">{errMsg}</span>
              ) : null}
            </div>

            <div className="px-5 pt-1">
              <div className="pb-1 font-medium text-gray-800">
                Cancellation Reason{" "}
                <span className="text-red-600 font-medium">*</span>
              </div>

              <div>
                <Select
                  options={[
                    {
                      label: "Wrong Input",
                      value: 1,
                    },
                    {
                      label: "System Error",
                      value: 2,
                    },
                    {
                      label: "Other",
                      value: 3,
                    },
                  ]}
                />
              </div>

              <div>
                <p className="pt-4 pb-1 font-medium text-gray-800">
                  Add notes <span className="text-red-600 font-medium">*</span>
                </p>
                <div>
                  <textarea
                    className="h-[96px]"
                    placeholder="please tell us the reason"
                    onChange={handleReasonInput}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="mt-10"></div>

            <div className="flex justify-end items-end px-5">
              <div className="mr-2">
                <button
                  className="btn btn--border text-[14px]"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
              <div>
                {!isLoaderBtn ? (
                  <button
                    className="btn btn--red text-[14px] pt-[11px]"
                    onClick={() => handleSubmit()}
                  >
                    Cancel Leave Request
                  </button>
                ) : (
                  <LoadingButton title={"Cancelling leave application"} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default CancelLeaveModal;
