import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import LoadingButton from "../../components/Buttons/LoadingButton";
import { toast } from "react-toastify";
import formatLeaveDuration from "../../utils/FormatLeaveDuration/formatLeaveDuration";
import MultipleLeaveActionPercentageBar from "../../components/Leaves/MultipleLeaveActionPercentageBar/MultipleLeaveActionPercentageBar";
import formatDate from "../../utils/FormatDate/formatDate";
import Avatar from "../../components/Avatar/Avatar";
import formatAvatarLetter from "../../utils/FormatAvatarLetter/formatAvatarLetters";

const DeclineLeaveModal = ({
  openModal,
  onCloseModal,
  leaveApplication,
  makeApiCall,
  eligibleToApproveOrDecline,
  setSelectedId,
  isMultipleAction,
  toggleState,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const [remark, setRemark] = useState("");
  const user = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(user, 2);
  const employeeName = isHr
    ? leaveApplication?.employee_name ||
      `${leaveApplication?.first_name} ${leaveApplication?.last_name}`
    : `${leaveApplication?.first_name} ${leaveApplication?.last_name}`;
  const [errMsg, setErrMsg] = useState("");
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const leaveApplicationId =
    leaveApplication?.additional_leave_application_id ||
    leaveApplication?.leave_application_id;
  const [hrForceDecline, setHrForceDecline] = useState(false);
  const [declineBtnTitle, setDeclineBtnTitle] = useState("Decline Leave");
  const [numberOfActions, setNumberOfActions] = useState(0);
  const additionalLeaveIndex = 2;
  const availableLeaveBalance = formatLeaveDuration({
    durationInDay: leaveApplication?.leave_balance_days,
    durationInHour: leaveApplication?.leave_balance_hours,
  });
  const applicationDate = formatDate(leaveApplication?.application_date);
  const fromDate = formatDate(
    leaveApplication?.from_date || leaveApplication?.applied_for_date
  );
  const toDate = formatDate(leaveApplication?.to_date);
  const totalDays =
    toggleState === additionalLeaveIndex
      ? "1"
      : formatLeaveDuration({
          durationInDay: leaveApplication?.duration_in_day,
          durationInHour: leaveApplication?.duration_in_hour,
        });
  const leaveName = leaveApplication?.leave_name;

  useEffect(() => {
    if (
      numberOfActions > 0 &&
      eligibleToApproveOrDecline?.length === numberOfActions
    ) {
      toast.success(`${numberOfActions} Leave applications declined`);
      setTimeout(() => {
        setIsLoadingBtn(false);
        makeApiCall();
        setRemark(null);
        setNumberOfActions(0);
        onCloseModal();
        setSelectedId([]);
      }, 1000);
    }
  }, [numberOfActions]);

  const hrDeclineLeave = async (id) => {
    if (remark) {
      const baseUrl =
        toggleState === additionalLeaveIndex
          ? "lms/leave/hrAdditionalLeaveReject"
          : "lms/leave/hrReject";
      const hrForceAction = isMultipleAction ? true : hrForceDecline;
      const reqBody =
        toggleState === additionalLeaveIndex
          ? {
              additional_leave_id: id || leaveApplicationId,
              hr_comments: remark,
              force_approval: hrForceAction,
            }
          : {
              leave_application_id: id || leaveApplicationId,
              hr_remarks: remark,
              force_reject: hrForceAction,
            };
      setErrMsg("");
      setIsLoadingBtn(true);
      let isMounted = true;
      const controller = new AbortController();
      await axiosPrivate
        .put(baseUrl, reqBody)
        .then((response) => {
          // isMounted && console.log(response.data, "hrreject");
          const res = response?.data;
          if (res?.manager_action_pending) {
            setIsLoadingBtn(false);
            setErrMsg(res.message);
            setHrForceDecline(true);
            setDeclineBtnTitle("Force Declince");
          } else if (res?.status) {
            setNumberOfActions((prev) => prev + 1);
            if (!isMultipleAction) {
              setIsLoadingBtn(false);
              toast.success(res.message);
              makeApiCall();
              onCloseModal();
            }
          } else {
            setErrMsg("Something went wrong, Try again.");
            setIsLoadingBtn(false);
          }
        })
        .catch((err) => {
          // console.error(err);
          setIsLoadingBtn(false);
        });
      return () => {
        isMounted = false;
        controller.abort();
      };
    } else {
      setErrMsg("Fill the required fields.");
      setIsLoadingBtn(false);
    }
  };

  const managerDeclineLeave = async (id) => {
    if (remark) {
      const baseUrl =
        toggleState === additionalLeaveIndex
          ? "lms/leave/managerAdditionalLeaveReject"
          : "lms/leave/managerRejection";
      const reqBody =
        toggleState === additionalLeaveIndex
          ? {
              additional_leave_id: leaveApplicationId,
              manager_comments: remark,
            }
          : {
              leave_application_id: id || leaveApplicationId,
              remark: remark,
            };
      setErrMsg("");
      setIsLoadingBtn(true);
      let isMounted = true;
      const controller = new AbortController();
      await axiosPrivate
        .put(baseUrl, reqBody)
        .then((response) => {
          //  isMounted && console.log(response.data, "managerreject");
          const res = response?.data;
          if (res.status) {
            setNumberOfActions((prev) => prev + 1);

            if (!isMultipleAction) {
              toast.success("Leave request has been rejected");
              setIsLoadingBtn(false);
              makeApiCall();
              onCloseModal();
            }
          } else {
            setErrMsg(res.message || "Something went wrong, please try again.");
            setIsLoadingBtn(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setErrMsg(
            err?.response?.data?.message ||
              "Something went wrong, please try again."
          );
          setIsLoadingBtn(false);
        });
      return () => {
        isMounted = false;
        controller.abort();
      };
    } else {
      setErrMsg("Fill the required fields.");
    }
  };
  const handleSubmit = () => {
    if (leaveApplicationId) {
      if (isHr) {
        hrDeclineLeave();
      } else {
        managerDeclineLeave();
      }
    } else {
      setErrMsg(
        "Something went wrong with the leave application. please try again!"
      );
    }
  };

  const handleMultipleDecline = async () => {
    if (remark) {
      setErrMsg("");
      if (eligibleToApproveOrDecline?.length) {
        setIsLoadingBtn(true);
        await Promise.all(
          eligibleToApproveOrDecline.map((item) =>
            isHr
              ? hrDeclineLeave(item?.leave_application_id)
              : managerDeclineLeave(item?.leave_application_id)
          )
        )
          .then((res) => res)
          .catch((err) => err);
      }
    } else {
      setErrMsg("Fill out the required field");
    }
  };

  useEffect(() => {
    if (toggleState === additionalLeaveIndex) {
      setDeclineBtnTitle("Decline");
    } else {
      setDeclineBtnTitle("Decline Leave");
    }
  }, [toggleState, additionalLeaveIndex]);
  return (
    <>
      <Modal open={openModal} onClose={onCloseModal} center>
        <div className="p-5 min-w-[420px]">
          <>
            <form>
              <h2 className="text-lg font-semibold">Decline Leave</h2>
              {!isMultipleAction && employeeName && (
                <>
                  <div className="p-2 flex gap-2 items-center rounded-3xl w-1/2 bg-gray-100">
                    <Avatar width={"6"} height={"6"} />
                    <p className="font-medium">{employeeName}</p>
                  </div>
                  <div className="p-2 mt-2 flex items-center rounded-3xl w-full bg-gray-100">
                    <p className="font-medium">Leave name: {leaveName}</p>
                  </div>
                  {toggleState !== additionalLeaveIndex && (
                    <div className="p-2 mt-2 flex gap-2 items-center rounded-md w-full bg-gray-100 line-clamp-3">
                      <p className="font-medium">
                        Available leave balance: {availableLeaveBalance} <br />
                        Leave applied on: {applicationDate.dateString2}
                        <br />
                        Leave requested for: {fromDate.dateString2} -{" "}
                        {toDate.dateString2} ({totalDays})
                      </p>
                    </div>
                  )}
                </>
              )}
              <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 mb-2">
                {errMsg}
              </span>

              {isMultipleAction && eligibleToApproveOrDecline.length ? (
                <>
                  <p className="my-6">
                    <span className="text-black font-medium pb-2">
                      {eligibleToApproveOrDecline.length} eligible leave
                      application to decline
                    </span>

                    {eligibleToApproveOrDecline?.length &&
                      eligibleToApproveOrDecline.map((leave) => {
                        const avatarTitle = formatAvatarLetter(
                          `${leave.first_name} ${leave.last_name}`
                        );
                        return (
                          <>
                            <div className="flex items-center justify-between py-1 gap-x-10">
                              <div className="p-2 flex h-10 gap-2 items-center rounded-3xl w-1/2 bg-gray-100">
                                <Avatar
                                  title={avatarTitle}
                                  width={"6"}
                                  height={"6"}
                                />
                                <p className="font-medium whitespace-nowrap w-full">{`${leave.first_name} ${leave.last_name}`}</p>
                              </div>
                              <div className="flex justify-end">
                                <div>
                                  <p className="text-end font-medium text-black">
                                    {leave.leave_name}
                                  </p>
                                  <span className="text-xs text-gray-500 whitespace-nowrap">
                                    {formatDate(leave?.from_date).dateString2} -{" "}
                                    {formatDate(leave?.to_date).dateString2}{" "}
                                    {`(${formatLeaveDuration({
                                      durationInDay: leave?.duration_in_day,
                                      durationInHour: leave?.duration_in_hour,
                                    })})`}
                                  </span>
                                  <br />
                                  <span className="text-xs text-gray-500 whitespace-nowrap">
                                    Available leave balance:{" "}
                                    {formatLeaveDuration({
                                      durationInDay: leave?.leave_balance_days,
                                      durationInHour:
                                        leave?.leave_balance_hours,
                                    })}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </p>
                  {numberOfActions > 0 ? (
                    <>
                      <p>
                        Declined{" "}
                        {`${numberOfActions} / ${eligibleToApproveOrDecline?.length}`}
                      </p>
                      <MultipleLeaveActionPercentageBar
                        actions={numberOfActions}
                        length={eligibleToApproveOrDecline?.length}
                      />
                    </>
                  ) : null}
                </>
              ) : isMultipleAction && !eligibleToApproveOrDecline.length ? (
                <span className="text-red-600">
                  Selected leave applications are not eligible to decline <br />
                </span>
              ) : null}
              {isMultipleAction && !eligibleToApproveOrDecline.length ? null : (
                <>
                  <label className="text-sm text-[#1F2937] font-medium">
                    Add note
                  </label>
                  <textarea
                    name="noteContent"
                    value={remark}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\s/.test(value)) {
                        setErrMsg("Note cannot start with a space");
                      } else {
                        setErrMsg("");
                        setRemark(value);
                      }
                    }}
                  />
                </>
              )}

              <div className="flex justify-end gap-3 mt-6">
                <button
                  type="button"
                  className="btn btn--border text-sm font-medium"
                  onClick={() => onCloseModal()}
                >
                  Cancel
                </button>
                {isLoadingBtn ? (
                  <LoadingButton title={"Rejecting Leave"} bg={"btn--red"} />
                ) : isMultipleAction &&
                  !eligibleToApproveOrDecline.length ? null : (
                  <button
                    type="button"
                    className="btn btn--red text-sm font-medium"
                    onClick={
                      isMultipleAction ? handleMultipleDecline : handleSubmit
                    }
                  >
                    {declineBtnTitle}
                  </button>
                )}
              </div>
            </form>
          </>
        </div>
      </Modal>
    </>
  );
};

export default DeclineLeaveModal;
