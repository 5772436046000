import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "../../components/DashboardSidebar";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Userprofile from "../../components/UserProfile/Userprofile";
import { ActionCreators } from "../../redux/actions/profile";
import { useDispatch } from "react-redux";

const Dashboard = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 1000);

  // userprofile
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false
  });
  //state for showing the dropdownenu for userprofile
  const [showMenu, setShowMenu] = useState(false);

  const openSlide = () => {
    setState({ isPaneOpen: true });
    setShowMenu(false)
  };

  const updateUserProfile = (data) => {
    dispatch(ActionCreators.updateUserProfile(data))
  }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header openSlide={openSlide} showMenu={showMenu} setShowMenu={setShowMenu} />
          <div className="flex">
  
            <Userprofile state={state} setState={setState} updateUserProfile={updateUserProfile}  />
            <DashboardSidebar  />
            <Outlet />
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
