const formatLeaveDuration = ({ durationInDay, durationInHour }) => {
  let duration = "";
  if (durationInDay) {
    duration += `${durationInDay} day(s) `;
  }
  if (durationInHour) {
    duration += `${durationInHour} hour(s) `;
  }
  return duration.trim();
};

export default formatLeaveDuration;
