import React, { useState } from "react";
import EditOrAddEmergencyContactModal from "../EditOrAddEmergencyContactModal/EditOrAddEmergencyContact";

const EmergencyContact = ({ employee, handleMakeApiCall }) => {
  const [openModal, setOpenModel] = useState(false);
  const onOpenModal = () => setOpenModel(true);
  const onCloseModal = () => setOpenModel(false);
  const emergencyContactName = employee?.emergency_contact_name || "NA"
  const emergencyContactPhone1 = employee?.emergency_phone_1 || "NA"
  const emergencyContactPhone2 = employee?.emergency_phone_2 || "NA"
  const emergencyContactRelationship = employee?.emergency_contact_relation || "NA"
  return (
    <div className="bg-white px-8 py-6 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] mt-6 mb-5">
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center gap-3">
          <img src="/images/basic-info-icon.svg" alt="" />
          <p className="text-base text-[#1F2937] font-semibold">
            Emergency Contact
          </p>
        </div>
        <div className="cursor-pointer">
          <img
            src="/images/editicon.svg"
            alt=""
            onClick={() => onOpenModal()}
          />
          {
            openModal && <EditOrAddEmergencyContactModal
            openModal={openModal}
            onCloseModal={onCloseModal}
            employee={employee}
            handleMakeApiCall={handleMakeApiCall}
          />
          }
        </div>
      </div>
      <div className="flex justify-between employee-info-card">
        <table className="border-0">
          <tbody>
            <tr>
              <td className="title">Contact Name </td>
              <td className="data">{emergencyContactName}</td>
            </tr>
            <tr>
              <td className="title">Relationship </td>
              <td className="data">{emergencyContactRelationship}</td>
            </tr>
            <tr>
              <td className="title">Phone Number 1</td>
              <td className="data">{emergencyContactPhone1}</td>
            </tr>
            <tr>
              <td className="title">Phone Number 2</td>
              <td className="data">{emergencyContactPhone2}</td>
            </tr>
            {/* <tr>
              <td className="title">Contact Name #2 </td>
              <td className="data">Female </td>
            </tr> */}
            {/* <tr>
              <td className="title">Relationship </td>
              <td className="data">Female </td>
            </tr>
            <tr>
              <td className="title">Phone Number </td>
              <td className="data">Female </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmergencyContact;
