import React, { useState } from "react";
import Line from "../../Line/Line";
import EditOrAddAdressModal from "../EditOrAddAdressModal/EditOrAddAdressModal";

const AddressInfo = ({ employee, handleMakeApiCall }) => {
  const [openModal, setOpenModel] = useState(false);
  const onOpenModal = () => setOpenModel(true);
  const onCloseModal = () => setOpenModel(false);
  const permanentAdress = employee?.permanent_address || "";
  const presentAdress = employee?.present_address || "";

  return (
    <div className="bg-white px-8 py-6 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] mt-6 mb-5">
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center gap-3">
          <img src="/images/basic-info-icon.svg" alt="" />
          <p className="text-base text-[#1F2937] font-semibold">Address</p>
        </div>
        <div className="cursor-pointer">
          <img
            src="/images/editicon.svg"
            alt=""
            onClick={() => onOpenModal()}
          />
          {openModal && (
            <EditOrAddAdressModal
              openModal={openModal}
              onCloseModal={onCloseModal}
              employee={employee}
              handleMakeApiCall={handleMakeApiCall}
            />
          )}
        </div>
      </div>
      <div className="flex justify-between employee-info-card">
        <table className="border-0">
          <tbody>
            <tr>
              <td className="title">Permanent Address</td>
              <td className="data">{permanentAdress}</td>
            </tr>

            <tr>
              <td className="title">Present Address </td>
              <td className="data">{presentAdress}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddressInfo;
