import React from "react";
import HashLoader from "react-spinners/HashLoader";

const PageLoader = () => {
  return (
    <div className="flex w-full justify-center  h-screen">
      <div className="mt-44">
        <HashLoader
          loading={true}
          size={60}
          color={"#307af2"}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
};

export default PageLoader;
