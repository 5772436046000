import React from "react";
import Aside from "../Dashboard/Aside";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "../../components/DashboardSidebar/DashboardSidebar";
import { useSelector } from "react-redux";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../config/userRoles";

const leaveUrl = {
  myLeave: "/leave/my-leave",
  leaveBalance: "/leave/leave-balance",
  holiday: "/leave/settings/holiday",
  leaveType: "/leave/settings/leave-type",
  customizePolicy: "/leave/settings/customize-policy",
  customizeBalance: "/leave/settings/customize-balance",
  leaveCalendar: "/leave/leave-calendar",
  holidayCalendar: "/leave/holiday-calendar",
  employeeLeaveApplications: "/leave/manage-leave/employee-leave-applications",
  employeeLeave: "/leave/manage-leave/employee-leave",
  leaveOverview: "/leave/manage-leave/overview",
  autoIncrementLeaveBalance: "/leave/settings/auto-increment-leave-balance"
};

const Leaves = () => {
  const user = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(user, userRoles.HR);

  const menu = [
    {
      text: "Overview",
      link: leaveUrl.myLeave,
      roles: [6],
    },
    {
      text: "My Leave Balances",
      link: leaveUrl.leaveBalance,
      roles: [6],
    },
    {
      text: "Leave Calendar",
      link: leaveUrl.leaveCalendar,
      roles: [6],
    },

    {
      text: "Holiday Calendar",
      link: leaveUrl.holidayCalendar,
      roles: [6],
    },
    {
      text: "Self Services",
      link: leaveUrl.myLeave,
      icon: "ico-down",
      roles: [2, 4, 1],
      menu: [
        {
          text: "My Leave",
          link: leaveUrl.myLeave,
          roles: [2, 4, 1],
        },
        {
          text: "Leave Balance",
          link: leaveUrl.leaveBalance,
          roles: [2, 4, 1],
        },
        {
          text: "Employee Leave",
          link: leaveUrl.employeeLeave,
          roles: [2, 4, 1],
        },
        {
          text: "Holiday Calendar",
          link: leaveUrl.holidayCalendar,
          roles: [2, 4, 1],
        },
      ],
      status: true,
    },
    {
      text: "Manage Leave",
      link: leaveUrl.employeeLeaveApplications,
      icon: "ico-down",
      roles: [2, 4, 1],
      menu: [
        // {
        //   text: "Overview",
        //   link: leaveUrl.leaveOverview,
        //   roles: [2, 4],
        // },
        {
          text: "Leave Approval",
          link: leaveUrl.employeeLeaveApplications,
          roles: [2, 4],
        },
        {
          text: isHr ? "Adjust Leave Balance" : "Employee Leave Balance",
          link: leaveUrl.customizeBalance,
          roles: [2, 4, 1],
        },
      ],
      status: false,
    },
    {
      text: "Settings",
      link: leaveUrl.holiday,
      icon: "ico-down",
      roles: [2, 1],
      menu: [
        {
          text: "Holiday List",
          link: leaveUrl.holiday,
          roles: [2, 1],
        },
        {
          text: "Leave Type & Policy",
          link: leaveUrl.leaveType,
          roles: [2, 1],
        },
        {
          text: "Customize Policy",
          link: leaveUrl.customizePolicy,
          roles: [2, 1],
        },
        {
          text: "Auto Increment Leave Balances",
          link: leaveUrl.autoIncrementLeaveBalance,
          roles: [2, 1],
        },
      ],
      status: false,
    },
  ];
  return (
    <div className="content overflow-hidden flex flex-1 self-stretch">
      <DashboardSidebar />
      <Aside submenu={menu} />
      <Outlet />
    </div>
  );
};

export default Leaves;
