import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Avatar from "../../../components/Avatar/Avatar";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import {
  genderOptions,
  titleOptions,
} from "../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../utils/userRoleAuth/userRoleAuth";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ActionCreators } from "../../../redux/actions/profile";

const MANUAL_ENTRY = 1;

const entryOptions = [{ value: MANUAL_ENTRY, label: "Manual Entry" }];

const EditBasicInformationModal = ({
  openModal,
  onCloseModal,
  employee,
  handleMakeApiCall,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname.split("/").slice(-1)[0];
  const user = useSelector((state) => state.user.auth);
  const isHrAuth = userRoleAuth(user, 2);
  const isAdminAuth = userRoleAuth(user, 1);
  const hasAccessToEditSerialKey = isAdminAuth || isHrAuth;
  const photo = employee?.photo || null;
  const employeeSerial = employee?.employee_serial || "";
  const email = employee?.email || "";
  const first_name = `${employee?.first_name || ""}`;
  const middle_name = ` ${employee?.middle_name || ""}`;
  const last_name = `${employee?.last_name || ""}`;
  const phone = employee?.phone || "";
  const gender = employee?.gender || "";
  const titleLabel = employee?.title || "";
  const employeeId = employee?.employee_id;
  const work_phone = employee?.work_phone || "";
  const personal_email = employee?.personal_email || "";
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      first_name,
      middle_name,
      last_name,
      phone,
      gender,
      email,
      work_phone,
      personal_email,
    },
  });
  const axiosPrivate = useAxiosPrivate();
  const [uploadedProfileImage, setUploadedProfileImage] = useState(null);
  const [errMsg, setErrMsg] = useState([]);
  const [previewOfProfileImage, setPreviewOfProfileImage] = useState(photo);
  const [isSerialKeyAvailable, setIsSerialKeyAvailable] = useState({
    available: false,
    checked: false,
    value: "",
  });
  const isSerialKeyChanges =
    isSerialKeyAvailable.value && isSerialKeyAvailable.value !== employeeSerial;

  const checkSerialKeyAvailablity = async () => {
    if (!isSerialKeyChanges) {
      return true;
    }
    setErrMsg("");
    const { value: serialKey } = isSerialKeyAvailable;
    if (!serialKey) {
      return toast.info("Enter a serial number");
    }
    const response = await axiosPrivate
      .get(`employee/search?pageSize=1000&employee_serial=${serialKey}`)
      .then((res) => {
        if (Number(res.data.statusCode) === 200) {
          setIsSerialKeyAvailable((prev) => ({
            ...prev,
            available: false,
            checked: true,
          }));
          setErrMsg("Serial key is already taken");
          return false;
        } else {
          setIsSerialKeyAvailable((prev) => ({
            ...prev,
            available: true,
            checked: true,
          }));
          return true;
        }
      })
      .catch((err) => {
        setIsSerialKeyAvailable((prev) => ({
          ...prev,
          available: true,
          checked: true,
        }));
        return true;
      });

    return response;
  };

  // Validating the uploaded image file and also creating url for the preview ***
  const validateUploadedImageFile = (e) => {
    setErrMsg("");
    let uploadedFile = e.target.files[0];
    let fileSize = uploadedFile.size;
    let fileType = uploadedFile.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (fileSize < 1033414) {
        setUploadedProfileImage(uploadedFile);
        setPreviewOfProfileImage(URL.createObjectURL(uploadedFile));
      } else {
        setErrMsg(
          "Uploaded image file is too large, try uploading image less than 1 MB"
        );
      }
    } else {
      setErrMsg(
        "Uploaded file is not a valid image. Only JPG and PNG files are allowed"
      );
    }
  };
  // Validation of file upload ended ***

  const updatePersonalDetails = async (reqObj) => {
    try {
      await axiosPrivate
        .put("onboarding/candidate/updatePersonalDetails", reqObj)
        .then((res) => res)
        .catch((err) => err);
    } catch (err) {
      throw err;
    }
  };

  const onSubmit = async (data) => {
    const checkIsSerialKeyAvailable = await checkSerialKeyAvailablity();
    if (isSerialKeyChanges && !checkIsSerialKeyAvailable) {
      return;
    }
    setErrMsg("");
    const {
      email,
      first_name,
      last_name,
      middle_name,
      phone,
      gender,
      title,
      work_phone,
      personal_email,
    } = data;
    if (gender || title) {
      updatePersonalDetails({ gender, employeeId, title });
    }
    let formData = new FormData();
    uploadedProfileImage && formData.append("photo", uploadedProfileImage);
    formData.append("email", email);
    formData.append("personal_email", personal_email);
    formData.append("phone", phone);
    formData.append("work_phone", work_phone);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("middle_name", middle_name);
    formData.append("employeeId", employeeId);
    formData.append(
      "employeeSerialKey",
      isSerialKeyChanges ? isSerialKeyAvailable.value : employeeSerial
    );

    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .put(`employee/updateProfile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // isMounted && console.log(response.data);
        const { status } = response.data;
        if (status) {
          handleMakeApiCall();
          if (pathname === "employee-settings") {
            dispatch(
              ActionCreators.updateUserProfile({
                email: email,
                first_name: first_name,
                last_name: last_name,
                middle_name: middle_name,
                phone: phone,
                photo: previewOfProfileImage,
              })
            );
          }
          toast.success("Personal details updated");
          onCloseModal();
        } else {
          const { message } = response.data;
          setErrMsg(message);
        }
      })
      .catch((err) => {
        // console.error(err);
        const { message } = err.response.data;
        setErrMsg(message);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  return (
    <>
      <Modal open={openModal} onClose={onCloseModal} center>
        <div className="p-5 max-w-[520px]">
          <>
            <h2 className="text-lg leading-6 text-[#1F2937] font-semibold mb-5">
              Edit Basic Informations
            </h2>
            <span className="flex items-center font-medium tracking-wide text-red-500 text-sm ml-3 my-1">
              {errMsg}
            </span>
            <form
              className="flex flex-col gap-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex">
                <div className="mr-4">
                  <Avatar image={previewOfProfileImage} />
                </div>
                <div>
                  <label className="btn btn--border text-sm w-[151px] h-[38px]">
                    <span className="ico-upload mr-1 w-3 h-3"></span> Upload
                    Photo
                    <input
                      type="file"
                      className="hidden"
                      alt="Profile picture"
                      accept="image/jpeg, image/png"
                      onChange={(e) => {
                        validateUploadedImageFile(e);
                      }}
                    ></input>
                  </label>
                  <p className="text-xs mt-2">
                    Allowed file type: jpg and png, max file size 1 mb.
                  </p>
                </div>
              </div>

              <div className="flex">
                <label className="text-sm font-medium text-black mr-4">
                  Employee ID Series <span className="text-red-700">*</span>
                  <Select
                    isDisabled={!hasAccessToEditSerialKey}
                    className="w-[220px]"
                    placeholder={"Serial ID Entry"}
                    options={entryOptions}
                  />
                </label>
                <label className="text-sm font-medium text-black w-[220px]">
                  ID <span className="text-red-700">*</span>
                  <input
                    onChange={(e) =>
                      setIsSerialKeyAvailable((prev) => ({
                        ...prev,
                        value: e.target.value,
                        checked: false,
                        available: false,
                      }))
                    }
                    defaultValue={employeeSerial}
                    type="text"
                    disabled={!hasAccessToEditSerialKey}
                  />
                  {isSerialKeyChanges && (
                    <div className="pt-2">
                      <button
                        type="button"
                        className="text-blue-800 "
                        onClick={checkSerialKeyAvailablity}
                      >
                        click here to check availability{" "}
                      </button>
                    </div>
                  )}
                  {isSerialKeyAvailable.checked && (
                    <div className="py-2">
                      <span
                        className={`rounded-3xl  p-1 ${
                          isSerialKeyAvailable.available
                            ? "bg-green-100 text-green-800"
                            : "text-red-800 bg-red-100"
                        }`}
                      >
                        {isSerialKeyAvailable.available
                          ? "Available"
                          : "Not available"}
                      </span>
                    </div>
                  )}
                </label>
              </div>

              <div className="flex">
                <label className="text-sm font-medium text-black mr-4">
                  Title
                  <Controller
                    name="title"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        placeholder={titleLabel}
                        className="w-[220px]"
                        onChange={(e) => onChange(e.value)}
                        options={titleOptions}
                      />
                    )}
                  />
                </label>
                <label className="text-sm font-medium text-black">
                  Gender
                  <Controller
                    name="gender"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        placeholder={
                          genderOptions?.find((i) => i.value === value)
                            ?.label || "Select Gender"
                        }
                        className="w-[220px]"
                        onChange={(e) => onChange(e.value)}
                        options={genderOptions}
                      />
                    )}
                  />
                </label>
              </div>

              <div>
                <label className="text-sm font-medium text-black w-[220px]">
                  First Name <span className="text-red-700">*</span>
                  <input
                    {...register("first_name", {
                      required: "This field is required",
                      pattern: {
                        value: /^[a-zA-Z]+$/,
                        message:
                          "Numbers , Special Characters and Space are not allowed!",
                      },
                    })}
                    type="text"
                    placeholder="Enter your fullname"
                  />
                </label>
                {errors?.first_name && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.first_name.message}
                  </span>
                )}
              </div>
              <div>
                <label className="text-sm font-medium text-black w-[220px]">
                  Middle Name
                  <input
                    {...register("middle_name")}
                    type="text"
                    placeholder="Enter your fullname"
                  />
                </label>
              </div>
              <div>
                <label className="text-sm font-medium text-black w-[220px]">
                  Last Name <span className="text-red-700">*</span>
                  <input
                    {...register("last_name", {
                      required: "This field is required",
                      pattern: {
                        value: /^[a-zA-Z]+$/,
                        message:
                          "Numbers , Special Characters and Space are not allowed!",
                      },
                    })}
                    type="text"
                    placeholder="Enter your fullname"
                  />
                </label>
                {errors?.last_name && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.last_name.message}
                  </span>
                )}
              </div>
              <div className="">
                <label className="text-sm font-medium text-black">
                  Phone Number
                </label>
                <div className="flex h-[38px]">
                  <input
                    type="text"
                    {...register("phone", {
                      pattern: {
                        value: /^(?=.*[0-9])\d*$/,
                        message: "Please enter a valid phone number",
                      },
                      validate: (value) => {
                        if (/(\d)\1{4}/.test(value)) {
                          return "Please enter a valid phone number";
                        }
                        return true;
                      },
                    })}
                  />
                </div>
                {errors?.phone && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.phone.message}
                  </span>
                )}
              </div>
              <div className="">
                <label className="text-sm font-medium text-black">
                  Work Phone Number
                </label>
                <div className="flex h-[38px]">
                  <input
                    type="text"
                    {...register("work_phone", {
                      pattern: {
                        value: /^(?=.*[0-9])\d*$/,
                        message: "Please enter a valid phone number",
                      },
                      validate: (value) => {
                        if (/(\d)\1{4}/.test(value)) {
                          return "Please enter a valid phone number";
                        }
                        return true;
                      },
                    })}
                  />
                </div>
                {errors?.work_phone && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.work_phone.message}
                  </span>
                )}
              </div>

              {hasAccessToEditSerialKey && (
                <div>
                  <label className="text-sm font-medium text-black w-[220px]">
                    Company Email <span className="text-red-700">*</span>
                    <input
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Please enter a valid email",
                        },
                      })}
                      type="email"
                      placeholder="Enter your email"
                    />
                  </label>
                  {errors?.email && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {errors.email.message}
                    </span>
                  )}
                </div>
              )}
                           <div>
  <label className="text-sm font-medium text-black w-[220px]">
    Personal Email <span className="text-red-700"></span>
    <input
      {...register("personal_email", {
        pattern: {
          value:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "Please enter a valid email",
        },
      })}
      type="email"
      placeholder="Enter your email"
    />
  </label>
  {errors?.personal_email && (
    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
      {errors.personal_email.message}
    </span>
  )}
</div>


              {/* <div className="">
                <label className="text-sm font-medium text-black">
                  Company Phone Number <span className="text-red-700">*</span>
                </label>
                <div className="flex h-[38px]">
                  <Select
                    options={[]}
                    placeholder={"+91"}
                    className="w-[100px] country-input-select"
                  />
                  <input
                    type="text"
                    placeholder="Enter your phone number"
                    className="country-input"
                  />
                </div>
              </div> */}

              {/* <div>
                <label className="text-sm font-medium text-black w-[220px]">
                  Company Email <span className="text-red-700">*</span>
                  <input type="text" placeholder="Enter your fullname" />
                </label>
              </div> */}

              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  className="btn btn--border text-sm font-medium"
                  onClick={onCloseModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn text-sm font-medium">
                  Save Changes
                </button>
              </div>
            </form>
          </>
        </div>
      </Modal>
    </>
  );
};

export default EditBasicInformationModal;
