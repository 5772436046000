import React, { useEffect, useState } from "react";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon } from "../../../../Icons/Icons";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

import Pagination from "../../../../components/Pagination/Pagination";

const AdjustLeaveBalanceSlidingPanel = ({ open, close, employee_id }) => {
  const axiosPrivate = useAxiosPrivate();
  const [leaveHistory, setLeaveHistory] = useState([]);
  const [pagination, setPagination] = useState({
    pageNo: 1,
    pageSize: 5,
    totalRecords: 0,
  });
  const [loading, setLoading] = useState(true); // Add loading state

  const fetchLeaveApplicationHistory = async () => {
    try {
    
      const result = await axiosPrivate.get(
        `lms/leave/leaveAdjustmentHistory?employee_id=${employee_id}&sortOrder=DESC&pageLimit=${pagination.pageSize}&pageNo=${pagination.pageNo}`
      );
      console.log("result",result)
      if (result?.data?.status) {
        setLeaveHistory(result.data.data);
        setPagination((prevPagination) => ({
          ...prevPagination,
          totalRecords: result.data.total,
        }));
      } else {
        setLeaveHistory([]);
      }
    } catch (error) {
      console.error("Error fetching leave history:", error);
      setLeaveHistory([]);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  const handlePageChange = (pageNo) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageNo: pageNo,
    }));
  };

  const handlePageSizeChange = (pageSize) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize: pageSize,
    }));
  };

  useEffect(() => {
    if (employee_id) {
      fetchLeaveApplicationHistory();
    }
  }, [open, employee_id, pagination.pageNo, pagination.pageSize]);

  return (
    <ReactSlidingPane isOpen={open} onRequestClose={close} width="800px">
      <div className="flex flex-col text-black w-full">
        <div className="flex px-5 pb-3 justify-between w-full border-b">
          <div className="flex gap-3 items-center">
            <i onClick={close} className="ico-left text-xs cursor-pointer"></i>
            <h1 className="font-medium text-lg text-black">History</h1>
          </div>
          <div>
            <button onClick={close}>{closeIcon}</button>
          </div>
        </div>
        <div className="px-5 flex justify-between py-3">
          {/* Conditionally render loading message or record count */}
          <p className="text-gray-500">
            {loading ? "Loading..." : `Found ${pagination.totalRecords} records`}
          </p>
        </div>
    
        {leaveHistory.map((item, index) => (
          <div key={index} className="px-5">
            <div className="">
              <p
                className="font-medium"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span>
                  {new Date(item.updated_date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                  })}{" "}
                </span>
                <span>
                  {new Date(item.updated_date).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              </p>

              <div className="flex">
                <p className="font-medium" style={{ marginRight: "0.5em" }}>
                  {item.leave_name}
                </p>
                <p className="font-medium" style={{ marginRight: "0.5em" }}>
                  {item.leave_action}
                </p>
                <p className="font-medium" style={{ marginLeft: "0.5em" }}>
                  By {item.updated_by}
                </p>
              </div>

              <div className="w-full">
                <div className="rounded-md flex gap-5 text-gray-600 text-xs bg-gray-100 p-2 mt-1">
                  <p>
                    <span className="text-blue-600">
                      <p>Adjusted Days: {item.adjusted_days} Days</p>
                      <p> Adjusted Hours: {item.adjusted_hours} hours </p>
                      <p> Notes: {item.note}</p>
                    </span>
                  </p>
                </div>
              </div>
            </div>
         
          </div>
          
        ))}

        <Pagination
          page={{
            pageNo: pagination.pageNo,
            setPageNo: handlePageChange,
            pageSize: pagination.pageSize,
            setPageSize: handlePageSizeChange,
            totalRecords: pagination.totalRecords,
          }}
        />
      </div>
    </ReactSlidingPane>
  );
};

export default AdjustLeaveBalanceSlidingPanel;
