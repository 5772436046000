import React, { useState } from "react";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import formatDate from "../../utils/FormatDate/formatDate";
import { shiftTypeConst, weekDays } from "../../utils/Consts/consts";

const ShiftAccordion = ({ data, index }) => {
  const { shift, workingDays } = data;

  const colorCode = shift?.color_code || "";
  const addedBy = `${shift.first_name || ""} ${shift.last_name || ""}`;
  const addedDate = formatDate(shift.created_at).dateString2;
  const shiftName = shift.shift_name;
  const shiftId = shift?.id
  const effetiveFrom = formatDate(shift.effective_from).dateString2;
  const shiftType = `${
    shift.shift_type === shiftTypeConst.clockBased ? "Clock" : "Duration"
  }-Based`;
  const totalWorkingHours = workingDays.reduce((duration, current) => {
    return (duration += current.duration);
  }, 0);

  const [activeIndex, setActiveIndex] = useState(null);
  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div>
      <div
        className={`mb-4 mt-6 bg-white p-4 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] ${
          index === activeIndex ? "active" : ""
        }`}
      >
        <div
          className="flex items-center justify-between py-2 px-2 cursor-pointer"
          onClick={() => toggleAccordion(index)}
        >
          <div className="flex items-center gap-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`w-5 h-5 transition-transform ${
                index === activeIndex
                  ? "transform rotate-0"
                  : "transform -rotate-90"
              }`}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M6.293 7.293a1 1 0 0 1 1.414 0L10 9.586l2.293-2.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span
             style={{ backgroundColor: colorCode }}
              className={`h-6 w-6 rounded-full inline-block cursor-pointer`}
            ></span>
            <div>
              <h3 className="text-[#111827] text-base font-semibold">
                {shiftName}
              </h3>
              <p>Duration {Math.floor(totalWorkingHours)} hours/week</p>
            </div>
          </div>
        </div>
        {index === activeIndex && (
          <>
            <div className="px-4 py-2 flex gap-20">
              <div>
                <ul className="flex flex-col gap-2 text-sm">
                  <li>Shift Id</li>
                  <li>Added by</li>
                  <li>Added date</li>
                  <li>Shift Name</li>
                  <li>Effective From</li>
                  <li>Shift Type</li>
                  <li>Total working hours/week</li>
                  <li className="mt-5">Daily working hours</li>
                </ul>
              </div>
              <div>
                <ul className="flex flex-col gap-2 text-sm text-[#1F2937]">
                  <li>{shiftId}</li>
                  <li>{addedBy}</li>
                  <li>{addedDate}</li>
                  <li>{shiftName}</li>
                  <li>{effetiveFrom}</li>
                  <li>{shiftType}</li>
                  <li>{`${totalWorkingHours}h 00m`}</li>
                  <li className="flex gap-10 mt-5">
                    <div>
                      <ul>
                        {weekDays.map((item) => (
                          <li key={item.id}>{item.day}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <ul>
                        {weekDays.map((item) => {
                          const workingDayDetail = workingDays.find(
                            (day) => Number(day.week_day_id) === Number(item.id)
                          );
                          const duration = workingDayDetail?.duration
                            ? `${workingDayDetail?.duration}h 00m`
                            : "(non-working day)";
                          return <li key={item.id}>{duration}</li>;
                        })}
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ShiftAccordion;
