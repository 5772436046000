import { React, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useAuth from "../../hooks/useAuth";
import { Link, useNavigate, useLocation, state } from "react-router-dom";
import useInput from "../../hooks/useInput";
import useToggle from "../../hooks/useToggle";
import axios from "../../helpers/axios";
import jwt_decode from "jwt-decode";
import "./Login.scss";
import { useSelector, useDispatch } from "react-redux";
import { ActionCreators } from "../../redux/actions/profile";
import { eye, closeEye } from "../../Icons/Icons";
import LoadingButton from "../../components/Buttons/LoadingButton";

const Login = () => {
  const auth = useSelector((state) => state.user.auth);
  // const [persist, setPersist] = useState(auth?.persist); currently setting this as true once the remeber me flow has done we can take it back
  const persist = true;
  const navigate = useNavigate();
  const location = useLocation();
  const [destination, setDestination] = useState("/");
  const from = location.state?.from?.pathname || destination;
  const [users, resetUser, userAttribs] = useInput("user", "");
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const [check, toggleCheck] = useToggle("persist", false);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const userProfile = useSelector((state) => state.user.auth);
  const browserHistory = useSelector((state) => state.user.browserHistory);
  const [isLoaderBtn, setLoaderBtn] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = async (data) => {
    try {
      setErrMsg("");
      setLoaderBtn(true);
      const response = await axios.post("auth/login", JSON.stringify(data));
      const accessToken = response?.data?.user_auth_data?.hrms_token;
      // console.log(accessToken);
      const decodedToken = jwt_decode(accessToken);
      // console.log(decodedToken);
      const user = decodedToken?.data?.user_name;
      const roles = [];
      roles.push(decodedToken?.data?.role_id);
      // setAuth({ user, roles, accessToken });
      // navigate(from, { replace: true });
      const connectedAthority = auth.connectedAthority;
      const recentNotice = auth.recentNotice;
      dispatch(
        ActionCreators.login({
          user,
          roles,
          accessToken,
          persist,
          connectedAthority,
          recentNotice,
        })
      );
      if (
        response.data.Message === "Success" &&
        Number(response.data.statusCode) === 200
      ) {
        const isSuperAdmin = roles.includes(1);
        const isFirstTimeUser = response.data.firstTimeUser;
        dispatch(ActionCreators.fetchUserProfile(accessToken));
        dispatch(ActionCreators.fetchTenantSetting(accessToken));
        if (isSuperAdmin) {
          if (browserHistory.length) {
            navigate(browserHistory[0]);
          } else {
            navigate("/dashboard");
          }
        } else if (isFirstTimeUser) {
          navigate("/onboarding/about-company");
        } else if (browserHistory.length) {
          navigate(browserHistory[0]);
        } else {
          navigate("/dashboard");
        }
      } else {
        setLoaderBtn(false);
        setErrMsg("Please provide valid username and password");
      }
    } catch (err) {
      setLoaderBtn(false);
      if (!err?.response) {
        // console.log(err)
        setErrMsg("No Server Response!");
      } else if (err.response?.status === 400) {
        setErrMsg(err?.response?.data?.message || "Username or Password not added in request!");
      } else if (err.response?.status === 401) {
        if (err?.response?.data?.userMessage) {
          setErrMsg(
            err?.response?.data?.userMessage === "Invalid credentials"
              ? "Invalid password. Please ensure you have entered the correct password and try again."
              : err.response.data.userMessage
          );
        } else {
          setErrMsg("Please provide valid username and password!");
        }
      } else {
        setErrMsg("Login Failed!");
      }
      errRef.current.focus();
    } finally {
      setLoaderBtn(false);
    }
  };
  // const togglePersist = () => {
  //   setPersist((prev) => !prev);
  // };

  // useEffect(() => {
  //   localStorage.setItem("persist", persist);
  // }, [persist]);

  return (
    <div className="flex h-screen justify-center items-center">
      <div className="flex-1 justify-center set-middle max-w-[380px]">
        <div className="mb-6 text-center">
          <div>
            <img
              src="/images/logo-large.svg"
              className="inline-block mb-6 w-20 h-20"
              alt="logo"
            />
          </div>
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Login</h2>
          <p>Please login to your account using company email
             {/* or username. */}
             </p>
        </div>
        <span
          className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1"
          ref={errRef}
        >
          {errMsg}
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=" mb-4">
            <label htmlFor="">Username</label>
            <input
              type="text"
              {...register("user_name", {
                required: "User name required",
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please enter a valid email",
                },
              })}
            />

            {errors?.user_name && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.user_name.message}
              </span>
            )}
          </div>
          <div className=" mb-4">
            <label htmlFor="">Password</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                {...register("password", { required: "Password is required" })}
              />
              <label
                className="absolute ml-[-33px] mt-[9px]"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? closeEye : eye}
              </label>
            </div>

            {errors?.password && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.password.message}
              </span>
            )}
          </div>
          {/* <div className=" mb-4 flex">
          <label htmlFor="persist" className="pr-1">
            <input
              type="checkbox"
              id="persist"
              onChange={togglePersist}
              checked={persist}
            />
          </label>
          Remember Me
        </div> */}
          <div className=" mb-4">
            {isLoaderBtn ? (
              <LoadingButton title="Logging In" class="w-full" />
            ) : (
              <input type="submit" className="btn btn--full" value="Login" />
            )}
          </div>
          <div className=" mb-4">
            <Link to="/forgot-password" className="btn btn--full btn--border">
              Forgot Password
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
