import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import Line from "../../../components/Line/Line";
import formatDate from "../../../utils/FormatDate/formatDate";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

const EditOrAddPosition = ({
  openModal,
  onCloseModal,
  handleMakeApiCall,
  employee,
}) => {
  const {
    office_location,
    designation_title,
    designation_id,
    employee_type_label,
    department_name,
    line_manager_name,
    user_role_label,
    location_id,
    employee_type_id,
    department_id,
    line_manager_id,
    employee_id,
  } = employee;
  const axiosPrivate = useAxiosPrivate();

  const { register, handleSubmit, control } = useForm({
    mode: "onBlur",
    defaultValues: {
      designation_id,
      location_id,
      employee_type_id,
      department_id,
      line_manager_id,
    },
  });

  const [locationList, setLocationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [jobTitleList, setJobTitleList] = useState([]);
  const [employeeType, setEmployeeType] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [selectedLineManagerId, setSelectedLineManagerId] =
    useState(line_manager_id);

  const fetchLocationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getLocationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setLocationList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDepartmentList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDepartmentList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDepartmentList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDesignationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDesignationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDesignationList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const fetchEmployeeTypeList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getEmployeeTypeList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data?.status) {
          setEmployeeType(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchJobTitleList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getJobTitleList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setJobTitleList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchLineManager = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(
        "employee/search?pageNo=1&pageSize=100&sortOrder=DESC&role=Manager",
        {
          signal: controller.signal,
        }
      )
      .then((response) => {
        // isMounted && console.log(response.data);
        const res = response?.data;
        setManagerList(res.employee || []);
      })
      .catch((err) => {
        throw err;
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchJobTitleList();
    fetchEmployeeTypeList();
    fetchDesignationList();
    fetchDepartmentList();
    fetchLocationList();
    fetchLineManager();
  }, []);

  const assignLineManager = async () => {
    await axiosPrivate
      .put("employee/assignLineManager", {
        employeeList: [
          {
            employeeId: employee_id,
            lineManagerId: selectedLineManagerId,
          },
        ],
      })
      .then((res) => {
        // console.log(res);
        if (res?.data?.status) {
          toast.success(
            res?.data?.message ||
              "Line manager updated for the selected employee(s)"
          );
        } else {
          toast.info(res?.data?.message || "Something went wrong... try again");
        }
      })
      .catch((err) => {
        // console.log(err.response);
        toast.error(
          err?.response?.data?.message ||
            "Line manager updated for the selected employee(s)"
        );
      });
  };

  const onSubmit = async (data) => {
    if (selectedLineManagerId !== line_manager_id) {
      assignLineManager();
    }
    await axiosPrivate
      .put("employee/updateEmployeeInformation", {
        employeeId: employee_id,
        ...data,
      })
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res.data.message);
          handleMakeApiCall()
          onCloseModal();
        } else {
          toast.info(
            res?.data?.message || "Failed to update employee information"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to update employee information");
      });

    return;

  };
  return (
    <>
      <Modal open={openModal} onClose={onCloseModal} center>
        <div className="p-5 w-[520px]">
          <>
            <h2 className="text-lg leading-6 text-[#1F2937] font-semibold mb-5">
              Edit/Add Position
            </h2>
            <form
              className="flex flex-col gap-4 justify-center"
              onSubmit={handleSubmit(onSubmit)}
            >
              {/* <div className="flex flex-col">
                <label className="text-sm font-medium text-black">
                  Effective Date <span className="text-red-700"></span>
                </label>
                <input
                  type="date"
                  disabled
                  className="text-center"
                  value={formatDate(new Date()).date}
                />
              </div> */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-black">
                  Location <span className="text-red-700"></span>
                </label>
                <Controller
                  name="location_id"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Select
                      placeholder={office_location}
                      className=""
                      options={locationList}
                      getOptionLabel={(option) => option.location_name}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => onChange(Number(e.id))}
                    />
                  )}
                />
              </div>
              <div className="flex justify-between">
                <div className="flex w-full flex-col">
                  <label className="text-sm font-medium text-black">
                    Position Type <span className="text-red-700"></span>
                  </label>
                  {/* <Select className="w-[220px] h-[38px]" options={[]} />
                   */}
                  <input
                    type="text"
                    className="max-w-[220px]"
                    value={user_role_label}
                    disabled
                  />
                </div>
                <div className="flex w-full flex-col">
                  <label className="text-sm font-medium text-black">
                    Employement Type <span className="text-red-700"></span>
                  </label>
                  <Controller
                    name="employee_type_id"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Select
                        placeholder={employee_type_label}
                        className=""
                        options={employeeType}
                        getOptionLabel={(option) => option.type_label}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => onChange(Number(e.id))}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-5">
                <div className="flex flex-col w-full">
                  <label className="text-sm font-medium text-black">
                    Designation <span className="text-red-700"></span>
                  </label>
                  <Controller
                    name="designation_id"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Select
                        placeholder={designation_title}
                        className=""
                        options={designationList}
                        getOptionLabel={(option) => option.designation_title}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => onChange(Number(e.id))}
                      />
                    )}
                  />
                </div>
                <div className="flex w-full flex-col">
                  <label className="text-sm font-medium text-black">
                    Department <span className="text-red-700"></span>
                  </label>
                  <Controller
                    name="department_id"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Select
                        placeholder={department_name}
                        className=""
                        options={departmentList}
                        getOptionLabel={(option) => option.department_name}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => onChange(Number(e.id))}
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <label className="text-sm font-medium text-black">
                  Line Manager <span className="text-red-700"></span>
                </label>
                <Select
                  placeholder={line_manager_name}
                  options={managerList}
                  getOptionLabel={(option) =>
                    `${option.first_name} ${option.last_name}`
                  }
                  getOptionValue={(option) => option.employee_id}
                  onChange={(e) => setSelectedLineManagerId(e.employee_id)}
                />
              </div>
              <Line width={""} />
              {/* <div className="">
                <label className="text-sm font-medium text-black">Note</label>
                <input type="text" className="h-20" />
              </div> */}

              {/* MODAL BUTTONS AREA */}

              <div className="flex justify-end gap-3 mr-5">
                <button
                  type="button"
                  className="btn btn--border text-sm font-medium"
                  onClick={() => onCloseModal()}
                >
                  Cancel
                </button>
                <button type="submit" className="btn text-sm font-medium">
                  Save Changes
                </button>
              </div>
            </form>
          </>
        </div>
      </Modal>
    </>
  );
};

export default EditOrAddPosition;
