import React, { useState } from "react";
import Avatar from "../../../../components/Avatar/Avatar";
import AdjustLeaveBalanceSlidingPanel from "../../../../components/Leaves/SlidingPanels/AdjustLeaveBalanceSlidingPanel";
import formatLeaveDuration from "../../../../utils/FormatLeaveDuration/formatLeaveDuration";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../../../config/userRoles";

const CustomizeBalanceListData = ({ employee, leaveTypes, makeApiCall }) => {
  const user = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(user, userRoles.HR);
  const photo = employee?.photo || null;
  const employeeName = `${employee?.first_name || ""} ${
    employee?.last_name || ""
  }`;
  const designationTitle = employee?.designation_title || "";
  const leaveBalances = employee?.leaveBalances || [];

  const [isAdjustBalanceSlidingPanelOpen, setAdjustBalanceSliginPanel] =
    useState(false);

  const handleOpenAdjustBalanceSlidingPanel = () => {
    isHr && setAdjustBalanceSliginPanel(true);
  };

 
  return (
    <>
      <AdjustLeaveBalanceSlidingPanel
        open={isAdjustBalanceSlidingPanelOpen}
        close={() => setAdjustBalanceSliginPanel(false)}
        employeeList={employee ? [employee] : []}
        leaveTypes={leaveTypes}
        makeApiCall={makeApiCall}
      />

      <tr className="bg-white hover:bg-gray-50 text-black">
        <td
          className="h-16 px-10 whitespace-nowrap py-4 flex items-center gap-3 border-r cursor-pointer"
          onClick={handleOpenAdjustBalanceSlidingPanel}
        >
          <Avatar image={photo} />
          <div>
            <p className="font-medium">{employeeName}</p>
            {designationTitle}
          </div>
        </td>
        {Array.isArray(leaveTypes) &&
          leaveTypes.map((leave, index) => {
            const getLeave =
              leaveBalances?.find(
                (i) => Number(i?.leave_type_id) === Number(leave?.id)
              ) || null;
            const isLopLeave = leave.is_lop;
            const isLeaveTypeAssigned = !!getLeave;
            const availableDayBalance = getLeave?.leave_balance_days || 0;
            const availableHourBalance = getLeave?.leave_balance_hours || 0;
            const totalAllowedBalance = leave?.number_days_allowed || 0;
            const availableBalance = formatLeaveDuration({
              durationInDay: availableDayBalance,
              durationInHour: availableHourBalance,
            });
            return (
              <td
                key={`${index}_leave_balance_type_emp`}
                className="text-center text-xs whitespace-nowrap h-16 px-10 py-4 border-r"
              >
                {isLopLeave
                  ? "Not Applicable"
                  : isLeaveTypeAssigned
                  ? `${availableBalance} / ${totalAllowedBalance} day(s)`
                  : "-"}
              </td>
            );
          })}
        {isHr && (
          <td className="text-center px-10 py-4 h-16 border-r">
            <span
              className="text-[14px] font-medium cursor-pointer"
              onClick={handleOpenAdjustBalanceSlidingPanel}
            >
              <i className="ico-edit" />
            </span>
          </td>
        )}
      </tr>
    </>
  );
};

export default CustomizeBalanceListData;
