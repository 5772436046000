import React, { useState } from "react";
import EditBasicInformationModal from "../EditBasicInformationModal/EditBasicInformationModal";

const BasicInfo = ({ employee, handleMakeApiCall }) => {
  const [openModal, setOpenModel] = useState(false);
  const onOpenModal = () => setOpenModel(true);
  const onCloseModal = () => setOpenModel(false);
  const employeeSerial = employee?.employee_serial || "";
  const email = employee?.personal_email || "";

  const fullName = `${employee?.first_name || ""} ${employee?.middle_name || ""} ${employee?.last_name || ""}`;
  const phoneNumber = employee?.phone || "";
  const workPhone = employee?.work_phone || "";
  const gender = employee?.gender || "";
  const companyEmail = employee?.email || "";
  const title = employee?.title || ""

  return (
    <div className="bg-white px-8 py-6 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] mt-6 mb-5">
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center gap-3">
          <img src="/images/basic-info-icon.svg" alt="" />
          <p className="text-base text-[#1F2937] font-semibold">
            Basic Information
          </p>
        </div>
        <div className="cursor-pointer">
          <img
            src="/images/editicon.svg"
            alt=""
            onClick={() => onOpenModal()}
          />
          {openModal && (
            <EditBasicInformationModal
              openModal={openModal}
              onCloseModal={onCloseModal}
              employee={employee}
              handleMakeApiCall={handleMakeApiCall}
            />
          )}
        </div>
      </div>
      <div className="flex justify-between employee-info-card">
        <table className="border-0">
          <tbody>
            <tr>
              <td className="title">Employee ID </td>
              <td className="data">{employeeSerial} </td>
              <td className="title">Personal Email </td>
              <td className="data">{email} </td>
            </tr>
            <tr>
              <td className="title">Title </td>
              <td className="data">{title}</td>
              <td className="title">Phone Number </td>
              <td className="data">{phoneNumber}</td>
            </tr>
            <tr>
              <td className="title">Full Name </td>
              <td className="data">{fullName}</td>
              <td className="title">Work Phone </td>
              <td className="data">{workPhone}</td>
            </tr>
            <tr>
              <td className="title">Gender </td>
              <td className="data">{gender} </td>
              <td className="title">Company Email </td>
              <td className="data">{companyEmail}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BasicInfo;
