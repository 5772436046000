export const getFormErrMsg = (field, errors) => {
  if (field && errors?.[field]?.message) {
    return (
      <p className="text-red-600 font-medium text-xs ml-1 my-1" role="alert">
        {errors?.[field]?.message}
      </p>
    );
  }
  return ""

  };