import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Select, { components } from "react-select";
import { useForm, Controller } from "react-hook-form";
import { relationShipOptionList } from "../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const EditOrAddEmergencyContactModal = ({
  openModal,
  onCloseModal,
  employee,
  handleMakeApiCall,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const employeeId = employee?.employee_id;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      emergency_contact_name: employee?.emergency_contact_name || "",
      emergency_phone_1: employee?.emergency_phone_1 || "",
      emergency_phone_2: employee?.emergency_phone_2 || "",
      emergency_contact_relation: employee?.emergency_contact_relation || "",
    },
  });

  const onSubmit = async (data) => {
    const reqObj = {};
    const dataKeys = Object.keys(data);
    dataKeys.forEach((item) => {
      const getValue = data[item];
      if (getValue || typeof getValue === "boolean") {
        reqObj[item] = getValue;
      }
    });
    await axiosPrivate
      .put("onboarding/candidate/updatePersonalDetails", {
        ...reqObj,
        employeeId,
      })
      .then((res) => {
        if (res?.data?.status) {
          handleMakeApiCall();
          toast.success(res.data.message);
          onCloseModal();
        } else {
          toast.info("Failed to update personal details");
        }
      })
      .catch((err) => {
        toast.info("Failed to update personal details");
      });
  };
  return (
    <>
      <Modal open={openModal} onClose={onCloseModal} center>
        <div className="p-5 max-w-[520px]">
          <>
            <h2 className="text-lg leading-6 text-[#1F2937] font-semibold mb-5">
              Edit/Add Emergency Contact
            </h2>
            <form
              className="flex flex-col gap-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col">
                <label className="text-sm font-medium text-black">
                  Name <span className="text-red-700">*</span>
                </label>
                <input
                  {...register("emergency_contact_name",{
                    required:"This field is required"
                  })}
                  type="text"
                  className="h-[38px]"
                />
                {errors?.emergency_contact_name && <span className="text-red-600"> {errors?.emergency_contact_name?.message}</span>}
              </div>
              <div className="flex flex-col">
                <label className="text-sm font-medium text-black">
                  Relationship
                </label>
                <Controller
                  control={control}
                  name="emergency_contact_relation"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        options={relationShipOptionList}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        placeholder={
                          relationShipOptionList?.find((i) => i.value === value)
                            ?.label || "Select"
                        }
                        onChange={(e) => {
                          onChange(e.value);
                        }}
                      />
                    );
                  }}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-sm font-medium text-black">
                  Emergency Phone Number 1
                  <span className="text-red-700">*</span>
                </label>
                <div className="flex h-[38px]">
                  {/* <Select
                    options={[
                      {
                        label: (
                          <div className="flex">
                            <img src="/images/IN-country.svg" />
                            <span>+91</span>
                          </div>
                        ),
                      },
                    ]}
                    components={{ DropdownIndicator }}
                    placeholder={"+91"}
                    className="w-[150px] country-input-select"
                  /> */}
                  {/* <input
                    type="text"
                    placeholder="Enter your phone number"
                    className="rounded-l-none country-input"
                  /> */}
                  <input
                    {...register("emergency_phone_1")}
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-sm font-medium text-black">
                  Emergency Phone Number 2
                  <span className="text-red-700">*</span>
                </label>
                <div className="flex h-[38px]">
                  <input
                    {...register("emergency_phone_2")}
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                  />
                </div>
              </div>

              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  className="btn btn--border text-sm font-medium"
                  onClick={onCloseModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn text-sm font-medium">
                  Save Changes
                </button>
              </div>
            </form>
          </>
        </div>
      </Modal>
    </>
  );
};

export default EditOrAddEmergencyContactModal;
