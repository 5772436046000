import React from "react";
import SimpleBar from "simplebar-react";
import Menu from "./Menu/Menu";

const DashboardSidebar = ({ submenu }) => {
  return (
    <div className="flex-shrink-0 bg-blue-800 text-white border-r border-r-gray-300 w-[105px] sticky top-[87px] min-h-screen">
      <SimpleBar>
        <div className="flex">
          <Menu submenu={submenu} />
        </div>
      </SimpleBar>
    </div>
  );
};

export default DashboardSidebar;
