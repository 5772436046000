import React, { useState } from "react";
import EditLeaveTypesInPolicyModal from "./EditLeaveTypesInPolicyModal";

const LeaveTypesInPolicyList = ({ leave, policyId, fetchListLeavePolicyMapping, setPolicyData }) => {
  const [editLeaveTypeModal, setEditLeaveTypeModal] = useState(false);

  const leaveName = leave?.leave_name;
  const numberOfDaysAllowed = leave?.number_days_allowed;
  const status = Number(leave?.status);
  const isLopLeave = leave?.is_lop



  return (
    <>
      {editLeaveTypeModal && (
        <EditLeaveTypesInPolicyModal
          leave={leave}
          openModal={editLeaveTypeModal}
          onCloseModal={() => setEditLeaveTypeModal(false)}
          policyId={policyId}
          fetchListLeavePolicyMapping={fetchListLeavePolicyMapping}
          setPolicyData={setPolicyData}
        />
      )}
      <tr className="text-black">
        <td className="px-4 py-4 w-[220px]">{leaveName}</td>
        <td className="px-4 py-4 w-[220px] text-center">
        {isLopLeave ? "Not applicable" : numberOfDaysAllowed}
        </td>
        <td className="px-4 py">

          <span className={`${status ? "status status--green" : "status status--red"}`}>{status ? "Active" : "Inactive"}</span>
        </td>
        <td className="px-4 py-4">
          {" "}
          <i
            className="ico-edit2 text-xl text-gray-600 cursor-pointer"
            onClick={() => setEditLeaveTypeModal(true)}
          ></i>
        </td>
      </tr>
    </>
  );
};

export default LeaveTypesInPolicyList;
