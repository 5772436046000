import React, { useEffect, useState
} from "react";
import MyReqularizationRequestData from "./MyReqularizationRequestData";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";


const MyRegularizationRequestTable = ({filterDate, filterType, filterStatus}) => {
  const [regularizationList, setRegularizationList] = useState([])
  const axiosPrivate = useAxiosPrivate();
  const fetchRegularizationRequestData = async () => {
    let isMounted = true;
    const controller = new AbortController();
    try {
      const url = "/attendance/listOfReqularizationApprovalRequest";
      // console.log("url response",url)
      const params = {
        from_date: filterDate ? new Date(filterDate).toISOString().split("T")[0] : null,
        type: filterType || null,
        status: filterStatus || null,
      };
  
      // console.log("API URL with params:", `${url}?${new URLSearchParams(params).toString()}`);
  
      const response = await axiosPrivate.get(url, { params });
     
      // console.log("API response:", response);
      if (response?.data?.data) {
        const regularizationData = response.data.data;
        // console.log("regulization list", regularizationData);
        setRegularizationList(regularizationData);
      } else {
        // console.log("no response found");
        setRegularizationList([]);
      }
    } catch (error) {
        // console.error("Error fetching data:", error);
        // console.log("Error response:", error.response); 
        setRegularizationList([]);
    }
  
    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  useEffect(() => {
    // console.log("calling api here")
    fetchRegularizationRequestData();
  }, [filterDate, filterType, filterStatus]);
  return (
    <>
      <table>
        <>
      {regularizationList && regularizationList.length > 0 ? (
        <table>
          <thead>
          <tr className="pl-10">
            <td className="items-center">
              <span>Request Date</span>
              <i className="ico-down pl-2 text-blue-600"></i>
            </td>
            <td className="items-center">
              <span>Type</span>
              <i className="ico-down pl-2 text-blue-600"></i>
            </td>
            <td className="items-center">
              <span>Attendance Date</span>
              <i className="ico-down pl-2 text-blue-600"></i>
            </td>
            <td className="items-center">
              <span>Reason</span>
              <i className="ico-down pl-2 text-blue-600"></i>
            </td>
            <td className="items-center">
              <span>Approval Status</span>
              <i className="ico-down pl-2 text-blue-600"></i>
            </td>
            <td className="items-center"></td>
          </tr>
        </thead>
          <tbody>
            {regularizationList.map((item, index) => (
              <MyReqularizationRequestData data={item} key={index} fetchDataAgain={fetchRegularizationRequestData} />
            ))}
          </tbody>
        </table>
      ) : (
        <tr>
        <td className="px-10 py-3 text-center">
          No data available
        </td>
      </tr>
      )}
    </>
   
      </table>
    </>
  );
};

export default MyRegularizationRequestTable;
