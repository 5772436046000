import React, { useState, useEffect, Fragment } from "react";
import LeaveItem from "./LeaveItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "./MyLeaves.scss";
import ApplyLeaveModal from "../../../components/ApplyLeaveModal/ApplyLeaveModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Pagination from "../../../components/Pagination/Pagination";
import Select from "react-select";
import Search from "../../../components/Header/Search/Search";
import EmptyState from "../../../components/EmptyState/EmptyState";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../utils/userRoleAuth/userRoleAuth";
import LeaveRequestList from "../../../components/Leaves/LeaveRequestList/LeaveRequestList";
import PageLoader from "../../../components/PageLoader";
import ApplyAdditionalLeaveModal from "../../../components/ApplyLeaveModal/ApplyAdditionalLeaveModal";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import LeaveDetailsModal from "../../../components/LeaveDetailsModal/LeaveDetailsModal";
import formatDate from "../../../utils/FormatDate/formatDate";
import { DatePicker } from "antd";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

const MyLeaves = () => {
  const { leaveApplicationIdFromParam } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const additionalLeaveApplicationidFromParam = searchParams.get(
    "additional-leave-details"
  );

  const navigate = useNavigate();

  const authState = useSelector((state) => state.user);
  const { auth: user } = authState;
  const isHr = userRoleAuth(user, 2);
  const isManager = userRoleAuth(user, 4);
  const isEmployee = userRoleAuth(user, 6);
  const hrOrManager = isHr || isManager;
  const axiosPrivate = useAxiosPrivate();

  const [errState, setErrState] = useState(<PageLoader />);
  const [leaveBalances, setLeaveBalances] = useState(null);
  const [makeApiCall, setMakeApiCall] = useState(false);

  const [leaveData, setLeaveData] = useState(null);
  const [hrDetails, setHrDetail] = useState(null);
  const [managerDetails, setManagerDetails] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [leaveRequestListURL, setLeaveRequestListURL] = useState(
    `lms/leave/leaveRequestList?pageNo=${pageNo}&pageSize=${pageSize}`
  );

  const additionalLeaveRequestTab = 2;
  const leaveRequestTab = 1;
  const [searchValue, setSearchValue] = useState(null);
  const [applicationDate, setApplicationDate] = useState(null);
  const [appliedDate, setAppliedDate] = useState(null);
  const [leaveType, setLeaveType] = useState(null);
  const [leaveStatus, setLeaveStatus] = useState(null);
  const [loaderComponent, setLoaderComponent] = useState(<PageLoader />);
  const [toggleState, setToggleState] = useState(leaveRequestTab);
  const [additionalLeaveModal, setApplyAdditionalLeaveModal] = useState(false);
  const [conditionalLeave, setConditionalLeave] = useState([]);
  const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);

  const [additionalLeaveRequestListUrl, setAdditionalLeaveRequestListUrl] =
    useState(
      `lms/leave/additionalLeaveListForEmployee?page_number=${pageNo}&page_limit=${pageSize}&sort_order=DESC`
    );

  const isAdditionalLeaveTab = toggleState === additionalLeaveRequestTab || additionalLeaveApplicationidFromParam;

  const searchHandle = (e) => {
    setSearchValue(e);
  };
  const handleDateRangeChange = (dates) => {
    if (dates && dates[0] && dates[1]) {
      setDateRange(dates);
    } else {
      setDateRange([null, null]);
    }
  };



  useEffect(() => {
    let baseURL;
    if (toggleState === leaveRequestTab) {
      baseURL = `lms/leave/leaveRequestList?pageNo=${pageNo}&pageSize=${pageSize}`;
    } else {
      baseURL = `lms/leave/additionalLeaveListForEmployee?page_number=${pageNo}&page_limit=${pageSize}&sort_order=DESC`;
    }

    if (applicationDate) {
      if (toggleState === additionalLeaveRequestTab) {
        baseURL = baseURL + `&application_date=${applicationDate}`;
      } else {
        baseURL = baseURL + `&applicationDate=${applicationDate}`;
      }

      setPageNo(1);
    }
    if (appliedDate) {
      if (toggleState === additionalLeaveRequestTab) {
        baseURL = baseURL + `&applied_for_date=${appliedDate}`;
      }

      setPageNo(1);
    }

    if (dateRange[0]) {
      const fromDateStr = dateRange[0].format("YYYY-MM-DD");
    //  console.log("date", appliedDate);
      baseURL += `&${
        toggleState === additionalLeaveRequestTab ? "from_date" : "fromDate"
      }=${fromDateStr}`;
      setPageNo(1);
     // console.log("from url", baseURL);
    }

    if (dateRange[1]) {
      const toDateStr = dateRange[1].format("YYYY-MM-DD");
      baseURL += `&${
        toggleState === additionalLeaveRequestTab ? "to_date" : "toDate"
      }=${toDateStr}`;
      setPageNo(1);
    }
    if (leaveType) {
      baseURL += `&${
        toggleState === leaveRequestTab ? "leaveType" : "leave_type_id"
      }=${leaveType.value}`;
      setPageNo(1);
    }
    if (leaveStatus) {
      if (toggleState === leaveRequestTab) {
        baseURL = baseURL + `&status=${leaveStatus.value}`;
      } else {
        baseURL =
          baseURL +
          `&manager_approval_status_id=${leaveStatus.value}&hr_approval_status_id=${leaveStatus.value}`;
      }
      setPageNo(1);
    }
    if (searchValue) {
      const delayTimer = setTimeout(() => {
        baseURL = baseURL + `&filter=${searchValue}`;
        setPageNo(1);
        setLeaveRequestListURL(baseURL);
      }, 600);
      return () => {
        clearTimeout(delayTimer);
      };
    }
    if (toggleState === leaveRequestTab) {
      setLeaveRequestListURL(baseURL);
    } else {
      setAdditionalLeaveRequestListUrl(baseURL);
    }
  }, [
    searchValue,
    applicationDate,
    leaveType,
    leaveStatus,
    pageNo,
    pageSize,
    dateRange,
    appliedDate,
  ]);

  const clearAllFilters = () => {
    setSearchValue(null);
    setApplicationDate(null);
    setLeaveType(null);
    setLeaveStatus(null);
    setDateRange([null, null]);
    setAppliedDate(null);

    const fromDateInput = document.getElementById("fromDate");
    const toDateInput = document.getElementById("toDate");

    if (fromDateInput) {
      fromDateInput.value = "";
    }
    if (toDateInput) {
      toDateInput.value = "";
    }
  };

  // FETCH_LEAVE_BALACES_API___STARTS**
  const fetchLeaveBalances = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`lms/leave/leaveBalances`)
      .then((response) => {
        // isMounted && console.log(response.data);
        const res = response?.data;
        if (res?.status) {
          setErrState(null);
          setLeaveBalances(res.results);
        }
      })
      .catch((err) => {
        // console.error(err);
        if (!err?.response?.data?.status) {
          setTimeout(() => {
            setErrState(
              <EmptyState message={"No leave type(s) assined. Contact HR"} />
            );
          }, 300);
        }
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  // FETCH_LEAVE_BALACES_API___ENDS**

  const clearStateValue = async () => {
    setLeaveData(null);
    setTotalRecords(null);
    // setHrDetail(null);
    // setManagerDetails(null);
  };

  // FETCH_LEAVE_REQUEST_LIST_API__STARTS**
  const fetchLeaveRequestList = async () => {
    setLoaderComponent(<PageLoader />);
    clearStateValue();
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(leaveRequestListURL)
      .then((response) => {
        // isMounted && console.log(response.data);
        if (response.data.status) {
          const { hrDetails, managerDetails, leaveRequestList, totalRecords } =
            response.data;
          setTimeout(() => {
            setLeaveData(leaveRequestList);
            setTotalRecords(totalRecords);
            setHrDetail(hrDetails);
            setManagerDetails(managerDetails);
            setLoaderComponent(null);
            setAppliedDate(null);
            setDateRange([null,null]);
          }, 500);
        } else if (
          response.data.message === "No data found." &&
          response.data.statusCode === 400
        ) {
          setLoaderComponent(<EmptyState />);
          clearStateValue();
        } else {
          setLoaderComponent(<EmptyState />);
        }
      })
      .catch((err) => {
        //  console.error(err);
        setLoaderComponent(<EmptyState />);
        clearStateValue();
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  // FETCH_LEAVE_REQUEST_LIST_API__ENDS**

  const fetchConditionalLeaves = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`lms/leave/listConditionalLeaveType`)
      .then((response) => {
        // isMounted && console.log(response.data);
        const res = response?.data;
        if (res.status) {
          setConditionalLeave(res.data);
        }
      })
      .catch((err) => {
        //  console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchAdditionalLeaveRequestList = async () => {
    setLoaderComponent(<PageLoader />);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(additionalLeaveRequestListUrl)
      .then((response) => {
        // isMounted && console.log(response.data);
        const res = response?.data;
        if (res.status) {
          const { total, additional_leave_list } = res;
          setTimeout(() => {
            setLeaveData(additional_leave_list);
            setTotalRecords(total);
            // setHrDetail(hrDetails);
            // setManagerDetails(managerDetails);
            setLoaderComponent(null);
          }, 500);
        } else {
          setLoaderComponent(<EmptyState />);
        }
      })
      .catch((err) => {
        // console.error(err);
        if (Number(err?.response?.data?.total) === 0) {
          setLoaderComponent(<EmptyState />);
        } else {
          setPageNo(1);
        }
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    if (toggleState === leaveRequestTab) {
      fetchLeaveBalances();
    } else {
      fetchConditionalLeaves();
    }
  }, [makeApiCall, toggleState]);

  useEffect(() => {
    if (toggleState === leaveRequestTab) {
      fetchLeaveRequestList();
    } else {
      fetchAdditionalLeaveRequestList();
    }
  }, [
    makeApiCall,
    pageNo,
    leaveRequestListURL,
    toggleState,
    additionalLeaveRequestListUrl,
  ]);

  //state for applyleave modal
  const [applyLeaveSlidingPanel, setApplyLeaveSlidingPanel] = useState(false);

  // function for apply leaveDetailsSlidingPanel
  const applyleave = () => {
    if (toggleState === leaveRequestTab) setApplyLeaveSlidingPanel(true);
    else setApplyAdditionalLeaveModal(true);
  };

  const toggleTab = (e) => {
    setToggleState(e);
  };

  const fetchLeaveFullDetails = async (isAdditionalLeaveApplicationid) => {
    const baseURL =
      isAdditionalLeaveApplicationid || isAdditionalLeaveTab
        ? `lms/leave/additionalLeaveDetails?leave_application_id=${additionalLeaveApplicationidFromParam}`
        : `lms/leave/leaveDetails?leave_application_id=${leaveApplicationIdFromParam}`;
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(baseURL)
      .then((response) => {
        // isMounted && console.log(response.data);
        const res = response?.data;
        if (res.status) {
          setLeaveFullDetails(res.data);
        }
      })
      .catch((err) => {
        //  console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const handleSlidingPanel = (isAdditionalLeaveApplicationid) => {
    setSlidingPanel(true);
    fetchLeaveFullDetails(isAdditionalLeaveApplicationid);
  };

  useEffect(() => {
    if (leaveApplicationIdFromParam || additionalLeaveApplicationidFromParam) {
      const isAdditionalLeaveApplicationid =
        !!additionalLeaveApplicationidFromParam;
      handleSlidingPanel(isAdditionalLeaveApplicationid);
    }
  }, [leaveApplicationIdFromParam, additionalLeaveApplicationidFromParam]);

  const handleCloseSlidingPanel = () => {
    setSlidingPanel(false);
    setLeaveFullDetails(null);
    navigate("/leave/my-leave/");
  };
  const [slidingPanel, setSlidingPanel] = useState(false);
  const [leaveFullDetails, setLeaveFullDetails] = useState(null);

  if (errState) {
    return errState;
  }

  return (
    <Fragment>
      <LeaveDetailsModal
        open={slidingPanel}
        close={() => handleCloseSlidingPanel()}
        makeApiCall={makeApiCall}
        fullDetails={leaveFullDetails}
        isAdditionalLeaveTab={isAdditionalLeaveTab}
      />
      {applyLeaveSlidingPanel ? (
        <ApplyLeaveModal
          open={applyLeaveSlidingPanel}
          close={() => setApplyLeaveSlidingPanel(false)}
          leaveTypes={leaveBalances}
          makeApiCall={() => setMakeApiCall(!makeApiCall)}
        />
      ) : null}

      {additionalLeaveModal && (
        <ApplyAdditionalLeaveModal
          open={additionalLeaveModal}
          close={() => setApplyAdditionalLeaveModal(false)}
          leave={conditionalLeave}
          makeApiCall={() => setMakeApiCall(!makeApiCall)}
        />
      )}
      <div className="overflow-hidden flex-1">
        <div className="px-10 pt-8 pb-4 ">
          <div className="flex items-center justify-between">
            <div>
              <h2>{isEmployee ? "Overview" : "My Leave"}</h2>
              <p>
                {hrOrManager
                  ? "Over view of your leave"
                  : "Manage all employee regularization"}
              </p>
            </div>
            <div className="flex gap-3 items-center">
              {hrOrManager ? (
                <>
                  {/* <div>
                    <input
                      type="date"
                      className="btn btn--border max-w-[157px] max-h-[38px]"
                    />
                  </div> */}
                </>
              ) : null}
              <div className="">
                <button
                  className="btn bg-[#3466E7] max-h-[38px] text-sm"
                  onClick={applyleave}
                >
                  <span>
                    <i className="ico-plus text-white mr-1 h-3.5 w-3.5"></i>
                  </span>
                  {toggleState === leaveRequestTab
                    ? "Apply Leave"
                    : "Request Additional Leave"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-outer pb-6 mr-10 relative overflow-hidden border-b border-gray-200">
          <Swiper
            modules={[Navigation]}
            spaceBetween={16}
            slidesPerView={"auto"}
            navigation
            updateOnWindowResize
          >
            {leaveBalances &&
              leaveBalances.map((item, index) => {
                return (
                  <SwiperSlide key={`key${index}_swiper_slide`}>
                    <LeaveItem leave={item} key={`key${index}_swiper`} />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>

        <div className="flex flex-col justify-between">
          <div className="max-w-full h-screen overflow-x-auto overflow-y-hidden">
            <div className="flex items-center justify-between  border-b-2">
              <div className="flex items-center gap-12 pl-4 pt-6">
                <button
                  className={
                    toggleState === leaveRequestTab
                      ? "tabs active-tabsnew text-blue-800 font-medium"
                      : "tabs"
                  }
                  onClick={() => toggleTab(leaveRequestTab)}
                >
                  Leave
                </button>
                <button
                  className={
                    toggleState === additionalLeaveRequestTab
                      ? "tabs active-tabsnew text-blue-800 font-medium"
                      : "tabs"
                  }
                  onClick={() => toggleTab(additionalLeaveRequestTab)}
                >
                  Additional Leave
                </button>
              </div>
            </div>

            <div className="py-4 px-10">
              <h2 className="text-base text-gray-800 font-semibold">
                Leave Request
              </h2>
              <div className="flex justify-between">
                <div className="flex pt-2.5 ">
                  <div className="flex gap-3 pr-3 ">
             {toggleState === additionalLeaveRequestTab ?  ( <DatePicker
                       placeholder="Leave Date"
                       onChange={(date) => setAppliedDate(date ? dayjs(date).format('YYYY-MM-DD') : null)}
                       />): (
                        <RangePicker
                        className="w-[300px] h-10"
                        placeholder={["From Date", "To Date"]}
                        onChange={handleDateRangeChange}
                       // value={dateRange[0] ? dateRange : []}
                        format="YYYY-MM-DD"
                      />
                       )}



                  </div>

                  <div className="pr-3">
                    <Select
                      className="w-[200px]"
                      options={
                        leaveBalances &&
                        leaveBalances.map((leave, index) => ({
                          label: leave.leave_name,
                          value: leave.leave_type_id,
                          key: `leave_balance_${index}`,
                        }))
                      }
                      placeholder="Type: All"
                      onChange={(e) => {
                        setLeaveType(e);
                      }}
                      value={
                        leaveType
                          ? { label: leaveType.label, value: leaveType.value }
                          : { label: "Type: All", value: "" }
                      }
                    />
                  </div>
                  <div className="pr-3 ">
                    <Select
                      placeholder="Status: All"
                      options={[
                        { label: "Approved", value: 1 },
                        { label: "Pending", value: 2 },
                        { label: "Declined", value: 3 },
                        { label: "Cancelled", value: 4 },
                      ]}
                      onChange={(e) => {
                        setLeaveStatus(e);
                      }}
                      value={
                        leaveStatus
                          ? {
                              label: leaveStatus.label,
                              value: leaveStatus.value,
                            }
                          : {
                              label: "Status: All",
                              value: "",
                            }
                      }
                    />
                  </div>
                  {searchValue ||
                  applicationDate ||
                  leaveType ||
                  appliedDate ||
                  leaveStatus ? (
                    <div
                      className="pr-3  flex items-center font-medium cursor-pointer text-blue-800"
                      onClick={() => clearAllFilters()}
                    >
                      Clear Filters
                    </div>
                  ) : null}
                </div>
                <div className="flex pt-2.5">
                  <button
                    type="button"
                    className="btn btn--border h-[38px]"
                    onClick={() => setMakeApiCall((prev) => !prev)}
                  >
                    <span className="material-symbols-outlined">refresh</span>
                  </button>
                  <div>
                    <Search onChange={searchHandle} mx={3} />
                  </div>
                  {/* <div>
                    <button className="btn btn--border text-[#374151] max-h-[38px] max-w-[127px] text-sm">
                      <span>
                        <i className="ico-download text-[#6B7280] mr-1 h-3.5 w-3.5"></i>
                      </span>
                      Download
                    </button>
                  </div> */}
                </div>
              </div>
            </div>

            {loaderComponent}

            <>
              {leaveData && leaveData.length > 0 ? (
                <>
                  <LeaveRequestList
                    leaveData={leaveData}
                    hr={hrDetails}
                    manager={managerDetails}
                    makeApiCall={() => setMakeApiCall(!makeApiCall)}
                    toggleState={toggleState}
                  />
                </>
              ) : (
                <div className="flex justify-center items-center h-48 text-gray-600">
                  No records found for leave requests.
                </div>
              )}
            </>
          </div>

          <Pagination
            page={{
              pageNo,
              setPageNo,
              pageSize,
              setPageSize,
              totalRecords,
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default MyLeaves;
