import React, { useEffect, useState } from "react";
import "./Search.scss";
import LoadingIcon from "../../../Icons/Loading";
const Search = (props, { placeholder }) => {

  const [inputValue, setInputValue] = useState('')

  const searchHandle = (e) => {
    props.onChange && props.onChange(e);
    setInputValue(e)
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setInputValue('')
    },600)

    return () => {
      clearTimeout(delay);
    }
  }, [inputValue])

  return (
    <div className={`search mx-${props.mx || "6"} relative ${props?.classes || ""}`}>
      <input
        className="rounded-md max-w-[240px] h-[38px]"
        type="text"
        placeholder={placeholder ? placeholder : "search"}
        onChange={(e) => searchHandle(e.target.value)}
      />
      <div className=" icon-div absolute left-4 top-2">
        <i className="ico-search text-base text-gray-400"></i>
      </div>
     {inputValue &&  <div className="absolute top-[7px] right-[7px]"><LoadingIcon/></div>}
    </div>
  );
};
export default Search;