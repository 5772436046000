import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Select, { components } from "react-select";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import Line from "../../Line/Line";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const BulkLeaveSettingModal = ({ open, close }) => {
  const axiosPrivate = useAxiosPrivate();
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);
  const [errMsg, setErrMsg] = useState("");

  const handleCloseModal = () => {
    setSelectedLeaveType(null);
    close();
  };

  const [leaveTypes, setLeaveTypes] = useState(null);

  const fetchLeaveTypes = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`lms/leave/leaveTypesList?status=1&fullDetails=1`)
      .then((response) => {
        // isMounted && console.log(response.data, "leave typees");
        const res = response?.data;
        if (res.status && Number(res.statusCode) === 200) {
          setLeaveTypes(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchLeaveTypes();
  }, []);

  const handleSubmit = async () => {
    if (selectedLeaveType) {
      setErrMsg("");
      let isMounted = true;
      const controller = new AbortController();
      await axiosPrivate
        .post(`lms/leave/bulkSetting`, {
          leaveTypeId: Number(selectedLeaveType.value),
          numberOfDaysToAdd: Number(selectedLeaveType.numberOfDaysAllowed),
        })
        .then((response) => {
          // isMounted && console.log(response.data, "bulk setting leave");
          const res = response?.data;
          if (res.status) {
            toast.success(
              `${selectedLeaveType.label} is assigned to all employees`
            );
            handleCloseModal();
          } else {
            toast.error("Something went wrong, try again.");
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Failed to assign leave type");
        });

      return () => {
        isMounted = false;
        controller.abort();
      };
    } else {
      setErrMsg("Select a leave type");
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleCloseModal} center>
        <div className="p-5 min-w-[420px] text-black">
          <div>
            <p className="font-medium text-lg pb-2">
              Bulk leave setting for all employee
            </p>
            <Line />
          </div>
          {Array.isArray(leaveTypes) ? (
            <>
              {" "}
              <div className="flex flex-col h-56 justify-between">
                <span className="text-red-600 font-medium">{errMsg}</span>
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col">
                    <Select
                      components={{ DropdownIndicator }}
                      options={
                        leaveTypes?.length &&
                        leaveTypes.map((leave) => ({
                          value: leave.id,
                          label: leave.leave_name,
                          numberOfDaysAllowed: leave.number_days_allowed,
                          isLop: leave.is_lop,
                        }))
                      }
                      onChange={(e) => {
                        setSelectedLeaveType(e);
                      }}
                    />
                  </div>
                  {!selectedLeaveType?.isLop ? (
                    <div className="flex items-center gap-3">
                      <label>Number of days allowed:</label>
                      <input
                        type="number"
                        className="w-[150px]"
                        value={selectedLeaveType?.numberOfDaysAllowed}
                        onChange={(e) =>
                          setSelectedLeaveType({
                            ...selectedLeaveType,
                            numberOfDaysAllowed: e.target.value,
                          })
                        }
                      />
                    </div>
                  ) : null}
                  <p className="text-gray-600">
                    Selected leave type will assign to all employees
                  </p>
                </div>
                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    className="btn btn--border"
                    onClick={handleCloseModal}
                  >
                    Close
                  </button>
                  <button type="button" className="btn" onClick={handleSubmit}>
                    Assign Leave Types
                  </button>
                </div>
              </div>
            </>
          ) : (
            <p className="text-red-800">No leave types availbale</p>
          )}
        </div>
      </Modal>
    </>
  );
};

export default BulkLeaveSettingModal;
