import { Types } from "../constants/actionTypes";
import axios from "../../helpers/axios";

export const ActionCreators = {
  addProfile: (user) => ({ type: Types.ADD_USER, payload: { user } }),

  updateProfileImage: (image) => ({
    type: Types.UPDATE_PROFILE_PICTURE,
    payload: { image },
  }),

  updateProfile: (user) => ({ type: Types.UPDATE_USER, payload: { user } }),

  formSubmittionStatus: (status) => ({
    type: Types.FORM_SUBMITION_STATUS,
    payload: { status },
  }),

  login: (user) => ({ type: Types.LOGIN, payload: { user } }),

  userProfile: (user) => ({ type: Types.USER_PROFILE, payload: user }),

  updateUserProfile: (user) => ({
    type: Types.UPDATE_USER_PROFILE,
    payload: user,
  }),

  logout: () => ({ type: Types.LOGOUT }),

  setTenantSetting: (setting) => ({
    type: Types.TENANT_SETTING,
    payload: setting,
  }),

  fetchUserProfile: (accessToken) => {
    return async (dispatch) => {
      const response = await axios
        .get("employee/profile", {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => res)
        .catch((err) => err);
      if (response?.data?.status) {
        const { profile, hrDetails, managerDetails } = response.data;
        dispatch(
          ActionCreators.userProfile({ profile, hrDetails, managerDetails })
        );
      }
    };
  },
  fetchTenantSetting: (accessToken) => {
    return async (dispatch) => {
      await axios
        .get("auth/getSetting", {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => {
          if (res?.data?.status) {
            dispatch(ActionCreators.setTenantSetting(res?.data?.results || []));
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    };
  },
  browserHistory: (history) => ({
    type: Types.BROWSER_HISTORY,
    payload: history,
  }),
};
