import React, { useEffect, useState, useRef } from "react";
import Avatar from "../../components/Avatar/Avatar";
import LeaveDetailsModal from "./LeaveDetailsModal";
import formatDate from "../../utils/FormatDate/formatDate";
import formatLeaveDuration from "../../utils/FormatLeaveDuration/formatLeaveDuration";
import formatLeaveApplicationStatus from "../../utils/FormatLeaveApplicationStatus/formatLeaveApplicationStatus";
import { useSelector } from "react-redux";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ApproveLeaveModal from "./ApproveLeaveModal";
import DeclineLeaveModal from "./DeclineLeaveModal";
import formatAvatarLetter from "../../utils/FormatAvatarLetter/formatAvatarLetters";
import { closeIconRoundRed, greenTickRound } from "../../Icons/Icons";

const LeaveApplicationListData = (props) => {
  const axiosPrivate = useAxiosPrivate();

  const {
    selectedId,
    toggleState,
    requestdata: application,
    makeApiCall,
    setSelectedId,
    approvalBtnAction,
  } = props;
  const additionalLeaveIndex = 2;

  const user = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(user, 2);
  const [isLeaveDetailsModal, setIsLeaveDetailsModal] = useState(false);
  const onCloseLeaveDetailsModal = () => setIsLeaveDetailsModal(false);
  // console.log("application", application)
  const leaveApplicationId =
    application?.additional_leave_application_id || application?.leave_application_id;
  const applicationDate = formatDate(application?.application_date);

  const fromDate = formatDate(
    toggleState !== additionalLeaveIndex
      ? application?.from_date
      : application?.applied_for_date
  );
  const toDate = formatDate(application?.to_date);
  const employeeName =
    application?.employee_name ||
    `${application?.first_name} ${application?.last_name}`;
  const status = formatLeaveApplicationStatus(
    isHr
      ? application?.leave_status || application?.hr_approval_status_label 
      : application?.manager_approval_status_label
  );
  const [isLeaveApproveModal, setIsLeaveApproveModal] = useState(false);
  const [declineLeaveModal, setDeclineLeaveModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const avatarLetter = formatAvatarLetter(employeeName);
  const isCanceled = application?.is_canceled;
  const managerApprovalStatusId = application?.manager_approval_status_id;
  // console.log("manager approve status",managerApprovalStatusId)
  const isForceAction = application?.force_action;
  const depatment = application?.department_name;
  const employeeId = application?.employee_serial || ""

  useEffect(() => {
    let isChecked = false;
    for (let i = 0; i < selectedId.length; i++) {
      if (selectedId[i] === leaveApplicationId) {
        isChecked = true;
        break;
      }
    }
    setIsChecked(isChecked);
  }, [selectedId]);

  const handleCheckbox = (e) => {
    if (e) {
      props.setSelectedId([...selectedId, leaveApplicationId]);
    } else {
      const filteredArr = selectedId.filter((id) => id !== leaveApplicationId);
      props.setSelectedId(filteredArr);
    }
  };

  const leaveSerial = application?.leave_serial;
  const leaveType = application?.leave_name;
  const [fullDetails, setFullDetails] = useState(null);
  const durationInDay = application?.duration_in_day || 0;
  const durationInHour = application?.duration_in_hour || 0;
  const duration = formatLeaveDuration({ durationInDay, durationInHour });

  const fetchFullLeaveDetails = async (id) => {
    const baseUrl =
      toggleState === additionalLeaveIndex
        ? `lms/leave/additionalLeaveDetails?leave_application_id=${id}&include_other_employees=true`
        : `lms/leave/leaveDetails?leave_application_id=${id}&include_other_employees=true`;
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(baseUrl)
      .then((response) => {
        // isMounted && console.log(response.data, "fullDetails");
        const res = response?.data;
        if (res.status) {
          setFullDetails(res.data);
          setIsLeaveDetailsModal(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  return (
    <>
      {isLeaveApproveModal ? (
        <ApproveLeaveModal
          openModal={isLeaveApproveModal}
          onCloseModal={() => setIsLeaveApproveModal(false)}
          leaveApplication={application}
          makeApiCall={makeApiCall}
          toggleState={toggleState}
          setSelectedId={setSelectedId}
        />
      ) : null}
      {isLeaveDetailsModal ? (
        <LeaveDetailsModal
          openModal={isLeaveDetailsModal}
          onCloseModal={onCloseLeaveDetailsModal}
          fullDetails={fullDetails}
          makeApiCall={makeApiCall}
          toggleState={toggleState}
          approvalBtnAction={approvalBtnAction}
        />
      ) : null}
      {declineLeaveModal ? (
        <DeclineLeaveModal
          openModal={declineLeaveModal}
          onCloseModal={() => setDeclineLeaveModal(false)}
          leaveApplication={application}
          makeApiCall={makeApiCall}
          toggleState={toggleState}
          setSelectedId={setSelectedId}
        />
      ) : null}
      <tr className="bg-white border-b text-black hover:bg-gray-50 dark:hover:bg-gray-200">
        <td className="px-10 py-4 flex items-center gap-3 border-0 cursor-pointer">
          <div className="flex items-center">
            {toggleState !== additionalLeaveIndex ? (
              <input
                type="checkbox"
                checked={isChecked}
                onChange={(e) => {
                  if (toggleState !== additionalLeaveIndex)
                    handleCheckbox(e.target.checked);
                }}
                className="mr-3"
              />
            ) : null}

            <div
              className="flex gap-2"
              onClick={() => fetchFullLeaveDetails(leaveApplicationId)}
            >
              <Avatar title={avatarLetter} />
              <div>
                <p className="font-medium">{employeeName}</p>
                <span className="text-sm text-gray-600">{employeeId}</span>
              </div>
            </div>
          </div>
        </td>

        <td className="px-10 py-4">
          {leaveType}
          <br />
          {applicationDate.dateString2}
        </td>

        <td className="px-10 py-4">{fromDate.dateString2}</td>
        {toggleState !== additionalLeaveIndex ? (
          <>
            <td className="px-10 py-4">{toDate.dateString2}</td>
            <td className="px-10 py-4">{duration}</td>
          </>
        ) : null}
        <td className="px-10 py-4">
          <div className="flex items-center gap-4">
            {isCanceled ? (
              <>
                <span className="status status--gray">Canceled</span>
              </>
            ) : isForceAction ? (
              <>
                <span className={status.leaveStatusClass}>
                  Force {status.leaveStatus} by HR
                </span>
              </>
            ) : (
              <>
                {status.leaveStatus !== "Pending" ? (
                  <>
                    {/* <img
                src="/images/revoke-icon.svg"
                alt=""
                className="cursor-pointer justify-end"
              /> */}
                    <span className={`${status.leaveStatusClass}`}>
                      {status.leaveStatus}
                    </span>
                    {/* can be changes later when revoke option available */}
                  </>
                ) : Number(managerApprovalStatusId) === 3 ? (
                  <>
                    <span className="status status--red">
                      Declined by manager
                    </span>
                  </>
                ) : approvalBtnAction ? (
                  <>
                    <button onClick={() => setDeclineLeaveModal(true)}>
                      {closeIconRoundRed}
                    </button>
                    <button onClick={() => setIsLeaveApproveModal(true)}>
                      {greenTickRound}
                    </button>
                  </>
                ) : Number(managerApprovalStatusId) === 1 ? (
                  <span className="status status--green">
                    Approved by manager
                  </span>
                ) : (
                  <span className={`${status.leaveStatusClass}`}>
                    {status.leaveStatus}
                  </span>
                )}
              </>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default LeaveApplicationListData;
