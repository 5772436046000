import React from "react";
import ReactSlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Line from "../../../Line/Line";
import { closeIcon } from "../../../../Icons/Icons";
import Select, { components } from "react-select";
// import { location } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";

const EditHoliday = ({ open, close }) => {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <span className="ico-down text-blue-600"></span>
      </components.DropdownIndicator>
    );
  };
  return (
    <div>
      <ReactSlidingPane isOpen={open} width="480px" onRequestClose={close}>
        <div className="text-black flex flex-col items h-full justify-between">
          <div>
            <div className="px-6 pb-3 flex justify-between items-center">
              <p className=" font-semibold text-base">Edit Holiday</p>
              <span>{closeIcon}</span>
            </div>
            <Line />

            <div className="px-6 flex flex-col justify-between">
              <div>
                <div className="mb-4">
                  <label className="">
                    Name<span className="text-red-700">*</span>
                  </label>
                  <input type="text" className="mt-1" />
                </div>
                <div className="mb-4">
                  <label>Date</label>
                  <input type="date" />
                </div>
                <div className="mb-4">
                  <label className="">
                    Applicable Locations<span className="text-red-700">*</span>
                  </label>
                  {/* <Select
                    className="custom-select-container"
                    components={{ DropdownIndicator }}
                    options={location}
                  /> */}
                </div>
                <div className="mb-4">
                  <label className="">
                    No. of day before when the remainder email is to be sent
                    <span className="text-red-700">*</span>
                  </label>
                  <Select
                    className="custom-select-container"
                    components={{ DropdownIndicator }}
                  />
                </div>

                <div className="flex gap-3 mb-4">
                  <input type="checkbox" />
                  <div>
                    <p className="font-medium">Notify applicable employee</p>
                    <p>
                      (They will receive a feed notification instantly once this
                      holiday is saved)
                    </p>
                  </div>
                </div>
                <div className="flex gap-3 mb-4">
                  <input type="checkbox" />
                  <div>
                    <p className="font-medium">
                      Reprocess leave applications based on this added holiday
                    </p>
                    <p>
                      (Leave that are already applied for this holiday will be
                      reprocessed and the balance will be adjusted accordingly)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Line />
            <div className="flex justify-end gap-4 px-6">
              <button className="btn btn--border">Cancel</button>
              <button className="btn">Add Holiday</button>
            </div>
          </div>
        </div>
      </ReactSlidingPane>
    </div>
  );
};

export default EditHoliday;
