import { useState, useEffect, useRef } from "react";
import Select, { components } from "react-select";
import EmployeeModuleList from "../../../../components/Employee/EmployeeModuleList/EmployeeModuleList";
import Search from "../../../../components/Header/Search";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import BulkUploadEmployeeModal from "../CreateEmployee/BulkUploadEmployeeModal/BulkUploadEmployeeModal";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../../utils/userRoleAuth/userRoleAuth";
import "react-responsive-modal/styles.css";
import AddNewEmployeeModal from "../../../../components/Employee/AddNewEmployeeModal/AddNewEmployeeModal";
import Pagination from "../../../../components/Pagination/Pagination";
import BulkLeaveSettingModal from "../../../../components/Employee/BulkLeaveSettingModal/BulkLeaveSettingModal";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import PageLoader from "../../../../components/PageLoader/PageLoader";
import { personRoundedIcon } from "../../../../Icons/Icons";
import AssignLineManagerModal from "../../../../components/Employee/AssignLineManagerModal/AssignLineManagerModal";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};
const defaultLocationOption = { location_name: "Office: All", id: null }
const defaultDesignationOption = { designation_title: "Designation: All", id: null }
const defaultDepartmentOption = { department_name: "Department: All", id: null }

const EmployeeList = () => {
  localStorage.setItem("isContinue", "true");
  const selectBoxRef = useRef({
    "Employement Status": "",
    "Ofiice": "",
    "Department": "",
    "Designation": ""
  })
  const [isAddEmployeeModal, setIsAddEmployeeModal] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [getEmployee, setGetEmployee] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [department, setDepartment] = useState(null);
  const [office, setOffice] = useState(null);
  const [employementStatus, setEmployementStatus] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const totalRecords = getEmployee ? getEmployee.totalRecords : null;
  const [employeeSearchUrl, setEmployeeSearchUrl] = useState(
    `employee/search?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC`
  );
  const [isBulkUploadEmployee, setIsBulkUploadEmployee] = useState(false);
  const [isBulkLeaveSettingModal, setIsBulkLeaveSettingModal] = useState(false);
  const user = useSelector((state) => state.user.auth);
  const isHrAuth = userRoleAuth(user, 2);
  const isAdmin = userRoleAuth(user, 1);
  const [loaderComponent, setLoaderComponent] = useState(<PageLoader />);
  const [leavePolicyList, setLeavePolicy] = useState(null);

  const fetchLeavePolicies = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`lms/leave/listLeavePolicy`)
      .then((response) => {
        // isMounted && console.log(response.data, "leavePolicyList");
        const res = response?.data;
        if (res.status && Number(res.statusCode) === 200) {
          setLeavePolicy(res.policy_list);
        } else {
          setLeavePolicy(null);
        }
      })
      .catch((err) => {
        console.error(err);
        setLeavePolicy(null);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const [locationList, setLocationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);



  const fetchLocationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getLocationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setLocationList([defaultLocationOption, ...data.result]);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDepartmentList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDepartmentList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDepartmentList([defaultDepartmentOption, ...data.result]);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDesignationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDesignationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDesignationList([defaultDesignationOption, ...data.result]);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchLeavePolicies();
    fetchDesignationList();
    fetchDepartmentList();
    fetchLocationList();
  }, []);

  const openAddEmployeeModal = () => {
    setIsAddEmployeeModal(true);
  };

  const getSearchValue = (data) => {
    setSearchValue(data);
  };

  const fetchEmployeeData = async () => {
    setLoaderComponent(<PageLoader />);
    setGetEmployee(null);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(employeeSearchUrl, {
        signal: controller.signal,
      })
      .then((response) => {
        // isMounted && console.log(response.data);
        const res = response?.data;
        if (Number(res.statusCode) === 200) {
          setTimeout(() => {
            setGetEmployee(res);
            setLoaderComponent(null);
          }, 500);
        } else if (
          Number(res.statusCode) === 400 &&
          res.description === "End of pagination "
        ) {
          setPageNo(1);
        } else if (Number(res.statusCode) === 400) {
          setGetEmployee(null);
          setLoaderComponent(<EmptyState />);
        }
      })
      .catch((err) => {
        console.error(err);
        setGetEmployee(null);
        setLoaderComponent(<EmptyState />);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    let baseUrl = `employee/search?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC`;

    if (Number(department)) {
      setPageNo(1);
      baseUrl = baseUrl + `&department=${department}`;
    }
    if (Number(designation)) {
      setPageNo(1);
      baseUrl = baseUrl + `&designation=${designation}`;
    }
    if (Number(office)) {
      setPageNo(1);
      baseUrl = baseUrl + `&office=${office}`;
    }
    if (Number(employementStatus)) {
      setPageNo(1);
      baseUrl = baseUrl + `&accountStatus=${employementStatus}`;
    }
    if (searchValue) {
      const delayTimer = setTimeout(() => {
        setPageNo(1);
        baseUrl = baseUrl + `&filter=${searchValue}`;
        setEmployeeSearchUrl(baseUrl);
      }, 600);
      return () => {
        clearTimeout(delayTimer);
      };
    }
    setEmployeeSearchUrl(baseUrl);
  }, [
    searchValue,
    department,
    designation,
    office,
    employementStatus,
    pageSize,
    pageNo,
  ]);

  const isFilterApplied =
    searchValue || department || designation || office || employementStatus;

  useEffect(() => {
    fetchEmployeeData();
  }, [employeeSearchUrl]);

  const handleClearFilters = () => {

    const selectBoxRefKeys = Object.keys(selectBoxRef.current);
    selectBoxRefKeys.forEach((item) => {
      selectBoxRef?.current?.[item]?.setValue({ label: `${item}: All`, value: ""});
    })
    setDepartment(0);
    setDesignation(0);
    setOffice(0);
    setEmployementStatus(0);
    setSearchValue("");
  };

  const employeeList = getEmployee ? getEmployee.employee : null;

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const handleSelectEmployee = (employee, status) => {
    if (!employee && typeof status === "boolean") {
      if (status) {
        setSelectedEmployees(employeeList);
      } else {
        setSelectedEmployees([]);
      }
    } else if (employee) {
      if (status) {
        setSelectedEmployees((prev) => {
          const isExists = prev?.find(
            (i) => i?.employee_id === employee?.employee_id
          );
          if (isExists) {
            return prev;
          } else {
            return [...prev, employee];
          }
        });
      } else {
        setSelectedEmployees((prev) =>
          prev.filter((i) => i.employee_id !== employee.employee_id)
        );
      }
    }
  };

  const [isAssignLineManagerModalOpen, setAssignLineManagerModalOpen] =
    useState(false);

  return (
    <>
      {isAssignLineManagerModalOpen && (
        <AssignLineManagerModal
          open={isAssignLineManagerModalOpen}
          close={() => setAssignLineManagerModalOpen(false)}
          selectedEmployees={selectedEmployees}
        />
      )}
      <div className="flex flex-col w-full justify-between">
        <div>
          {isAddEmployeeModal && (
            <AddNewEmployeeModal
              open={isAddEmployeeModal}
              close={() => setIsAddEmployeeModal(false)}
              fetchEmployeeData={fetchEmployeeData}
            />
          )}
          {isBulkUploadEmployee && (
            <BulkUploadEmployeeModal
              open={isBulkUploadEmployee}
              close={() => setIsBulkUploadEmployee(false)}
              fetchEmployeeData={() => fetchEmployeeData()}
            />
          )}
          {isBulkLeaveSettingModal && (
            <BulkLeaveSettingModal
              open={isBulkLeaveSettingModal}
              close={() => setIsBulkLeaveSettingModal(false)}
              leavePolicyList={leavePolicyList}
            />
          )}
          <div className="px-10 py-4 flex flex-col justify-between">
            <div className="flex justify-between">
              <div>
                {" "}
                <h2>Employee List</h2>
                <p className="pb-4">Manage all employee on your organization</p>
              </div>
              <div className="">
                <div className={`flex justify-end pb-5 select-none`}>
                  {isHrAuth || isAdmin ? (
                    <div
                      className={`mr-3 flex gap-3 ${
                        selectedEmployees.length ? "" : "opacity-50"
                      }`}
                    >
                      <button
                        onClick={() => setAssignLineManagerModalOpen(true)}
                        className="btn btn--border flex items-center gap-1"
                        disabled={selectedEmployees.length ? false : true}
                      >
                        {personRoundedIcon} Manage line manager
                      </button>
                    </div>
                  ) : null}

                  <div>
                    {isHrAuth || isAdmin ? (
                      <button
                        onClick={() => setIsBulkLeaveSettingModal(true)}
                        className="btn btn--border mr-3 text-[14px]"
                      >
                        <span>
                          <i className="ico-clock1 text-black mr-1"></i>
                        </span>
                        Bulk Leave Setting
                      </button>
                    ) : null}
                    {isHrAuth || isAdmin ? (
                      <button
                        onClick={() => setIsBulkUploadEmployee(true)}
                        className="btn btn--border mr-3 text-[14px]"
                      >
                        <span>
                          <i className="ico-plus text-black mr-1"></i>
                        </span>
                        Bulk Upload Employee
                      </button>
                    ) : null}

                    {isHrAuth || isAdmin ? (
                      <button
                        onClick={openAddEmployeeModal}
                        className="btn text-[14px]"
                      >
                        <span>
                          <i className="ico-plus text-white mr-1"></i>
                        </span>
                        Add Employee
                      </button>
                    ) : null}
                    {/* <Link to="/employee/manage-employee/create-employee">
                      <button className="btn text-[14px]">
                        <span>
                          <i className="ico-plus text-white mr-1"></i>
                        </span>
                        Add Employee
                      </button>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between">
              <div className="flex gap-3">
                <Select
                ref={(ele) => selectBoxRef.current["Designation"] = ele}
                  placeholder={"Designation: All"}
                  options={designationList.map((i) => ({
                    label: i.designation_title,
                    value: i.id,
                  }))}
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => {
                    setDesignation(e.value);
                  }}
                />
                <Select
                ref={(ele) => selectBoxRef.current["Department"] = ele}
                  placeholder={"Department: All"}
                  options={departmentList.map((i) => ({
                    label: i.department_name,
                    value: i.id,
                  }))}
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => {
                    setDepartment(e.value);
                  }}
                />
                <Select
                ref={(ele) => selectBoxRef.current["Ofiice"] = ele}
                  placeholder={"Office: All"}
                  options={locationList.map((i) => ({
                    label: i.location_name,
                    value: i.id,
                  }))}
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => {
                    setOffice(e.value);
                  }}
                />
                <Select
                ref={(ele) => selectBoxRef.current["Employement Status"] = ele}
                  placeholder={"Employement Status:  All"}
                  options={[
                    { value: "0", label: "Employement Status: All" },
                    { value: "1", label: "Active" },
                    { value: "2", label: "Onboarding" },
                    { value: "3", label: "On Leave" },
                    { value: "4", label: "Probationary" },
                    { value: "5", label: "Offboarding" },
                  ]}
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => {
                    setEmployementStatus(e.value);
                  }}
                />
                {!!isFilterApplied && (
                  <button
                    className="text-blue-600 cursor-pointer"
                    onClick={handleClearFilters}
                  >
                    Clear filters
                  </button>
                )}
              </div>
              <div>
                {" "}
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="btn btn--border h-[38px]"
                    onClick={() => fetchEmployeeData()}
                  >
                    <span className="material-symbols-outlined">refresh</span>
                  </button>
                  <div>
                    <Search onChange={getSearchValue} />
                  </div>
                  {/* <a href="#" className="btn btn--border text-[14px]">
                  <span>
                    <i className="ico-download mr-1"></i>
                  </span>
                  Download
                </a> */}
                </div>
              </div>
            </div>
          </div>

          <div>
            {loaderComponent}
            {employeeList && (
              <>
                <EmployeeModuleList
                  data={employeeList}
                  leavePolicyList={leavePolicyList}
                  handleSelectEmployee={handleSelectEmployee}
                  selectedEmployees={selectedEmployees}
                />
              </>
            )}
          </div>
        </div>
        {employeeList && (
          <Pagination
            page={{
              pageNo,
              setPageNo,
              pageSize,
              setPageSize,
              totalRecords,
            }}
          />
        )}
      </div>
    </>
  );
};

export default EmployeeList;
