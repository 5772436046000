import React from "react";
import LeaveApplicationListData from "./LeaveApplicationListData";

const LeaveApplicationList = ({
  leaveApplications,
  setIsAllChecked,
  selectedId,
  setSelectedId,
  makeApiCall,
  toggleState,
  approvalBtnAction,
}) => {
  const additionalLeaveIndex = 2;
  return (
    <div className="relative overflow-x-auto min-h-screen">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            <th scope="col" className="px-10 py-3">
              <div className="flex items-center">
                {toggleState !== additionalLeaveIndex ? (
                  <input
                    type="checkbox"
                    className="mr-3"
                    onChange={(e) => {
                      if (toggleState !== additionalLeaveIndex)
                        setIsAllChecked(e.target.checked);
                    }}
                  />
                ) : null}

                <div>
                  Requested By
                  <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
                </div>
              </div>
            </th>
            <th scope="col" className="px-10 py-3">
              Request Date
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            <th scope="col" className="px-10 py-3">
              {toggleState !== additionalLeaveIndex
                ? "From"
                : "Applied for date"}
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            {toggleState !== additionalLeaveIndex ? (
              <>
                <th scope="col" className="px-10 py-3">
                  to
                  <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
                </th>
                <th scope="col" className="px-10 py-3">
                  Total
                  <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
                </th>
              </>
            ) : null}
            <th scope="col" className="px-10 py-3 text-right cursor-pointer">
              <i className="ico-grid text-blue-600"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {leaveApplications &&
            leaveApplications.map((val) => {
              return (
                <LeaveApplicationListData
                  requestdata={val}
                  key={val.id || val.leave_application_id}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  makeApiCall={makeApiCall}
                  toggleState={toggleState}
                  approvalBtnAction={approvalBtnAction}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default LeaveApplicationList;
