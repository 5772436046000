import React from 'react'
import { MoonLoader } from "react-spinners";

const SpinnerLoader = () => {
  return (
    <>
     <MoonLoader
        loading={true}
        size={30}
        color={"#307af2"}
        aria-label="Loading Spinner"
        data-testid="loader"
        />
    </>
  )
}

export default SpinnerLoader