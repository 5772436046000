import React, { Fragment, useEffect, useState } from "react";
import BasicInfo from "./BasicInfo";
import EmployeeHeader from "./EmployeeHeader";
import LeaveByTypeOverview from "./LeaveOverview";
import Payslip from "./Payslip";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../utils/userRoleAuth/userRoleAuth";
import EmptyState from "../../../components/EmptyState/EmptyState";
import PageLoader from "../../../components/PageLoader/PageLoader";
import CustomizeWidgetList from "../EmployeePortal/CustomizeDashboardWidget/customiseWidgetList";
import RegularizationApprovalOverviewChart from "../../../components/Attendance/ManageAttendance/Overview/RegularizationApprovalOverviewChart";
import AttendanceOverviewChart from "../../../components/Attendance/ManageAttendance/Overview/AttendanceOverviewChart";
import LeaveApprovalOverviewChart from "../../../components/Attendance/ManageAttendance/Overview/LeaveApprovalOverviewChart";
import AttendanceAnalyticsOverviewChart from "../../../components/Attendance/ManageAttendance/Overview/AttendanceAnalyticsOverviewChart";
import AttendanceLoginInfo from "../../../components/Attendance/ManageAttendance/Overview/AttendanceLoginInfo";
import NewJoiner from "../../../components/Attendance/ManageAttendance/Overview/NewJoiner";
import LeaveTakenOverView from "../../../components/Attendance/ManageAttendance/Overview/LeaveTakenOverView";
import AddWidgetCard from "../../../components/AddWidgetCard/AddWidgetCard";
import AbsentEmployee from "../../../components/Attendance/ManageAttendance/Overview/AbsentEmployee";
import { useNavigate } from "react-router-dom";

const widgetListOrder = [
  { value: '1', label: 'Attendance Clock-in' },
  { value: '2', label: 'Profile' },
  { value: '3', label: 'Payslip' },
  { value: '4', label: 'New Joiner' },
  { value: '11', label: 'Absent List' },
  { value: '5', label: 'Attendance analytics' },
  { value: '6', label: 'Attendance' },
  { value: '7', label: 'Regularization Approval' },
  { value: '8', label: 'Leave Approval' },
  { value: '9', label: 'Leave Taken' },
  { value: '10', label: 'Leave by type' },
];

const EmployeePortal = () => {
  const axiosPrivate = useAxiosPrivate();
  const [widgets, setWidgets] = useState([]);
  const [customizingWidgets, setCustomizingWidgets] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchDashboardWidgetList = async () => {
    setIsLoading(true);
    axiosPrivate
      .get("employee/listOfWidgets")
      .then((response) => {
        if (response?.data?.status) {
          const sortedWidgets = sortWidgets(response?.data?.data);
          setWidgets(sortedWidgets);
        } else {
          setWidgets([]);
        }
      })
      .catch(() => {
        setWidgets([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sortWidgets = (widgets) => {
    return widgets.sort((a, b) => {
      const orderA = widgetListOrder.findIndex(item => item.label === a.widget_name);
      const orderB = widgetListOrder.findIndex(item => item.label === b.widget_name);
      return orderA - orderB;
    });
  };

  useEffect(() => {
    fetchDashboardWidgetList();
  }, []);

  const toggleCustomizingWidgets = () => {
    setCustomizingWidgets(!customizingWidgets);
  };

  const handleWidgetUpdate = () => {
    fetchDashboardWidgetList();
  };

  const handleAddWidgetClick = () => {
    setCustomizingWidgets(true);
  };

  const closeCustomizedWidgetList = () => {
    setCustomizingWidgets(false);
  };

  const widgetMap = {
    "Attendance Clock-in": <AttendanceLoginInfo />,
    "Leave Taken": <LeaveTakenOverView />,
    Payslip: <Payslip />,
    "Absent List": <AbsentEmployee />,
    "Leave Approval": <LeaveApprovalOverviewChart />,
    "Leave by type": <LeaveByTypeOverview />,
    Attendance: <AttendanceOverviewChart />,
    "Attendance analytics": <AttendanceAnalyticsOverviewChart />,
    Profile: <BasicInfo />,
    "Regularization Approval": <RegularizationApprovalOverviewChart />,
    "New Joiner": <NewJoiner />,
  };

  const widgetStyles = {
    "Attendance Clock-in": "col-span-4 md:col-span-1 row-start-1 row-end-2",
    Profile: "col-span-4 md:col-span-2 row-start-1 row-end-2",
    Payslip: "col-span-4 md:col-span-1 row-start-1 row-end-2",
    "New Joiner": "col-span-4 md:col-span-2 row-start-2 row-end-3",
    "Absent List": "col-span-4 md:col-span-2 row-start-2 row-end-3",
    "Attendance analytics": "col-span-4 md:col-span-2 xl:col-span-4 row-auto",
    Attendance: "col-span-4 md:col-span-2 row-auto",
    "Regularization Approval": "col-span-4 md:col-span-2 row-auto",
    "Leave Approval": "col-span-4 md:col-span-2 row-auto",
    "Leave Taken": "col-span-4 md:col-span-2 row-auto",
    "Leave by type": "col-span-4 md:col-span-2 xl:col-span-4 row-auto"
  };

  const enabledWidgets = widgets.filter(widget => Number(widget?.view_status) === 1);
  const disabledWidgets = widgets.filter(widget => Number(widget?.view_status) !== 1);

  return (
    <Fragment>
      <div className="flex-1">
        <EmployeeHeader
          toggleCustomizingWidgets={toggleCustomizingWidgets}
          handleWidgetUpdate={handleWidgetUpdate}
          onHandleClose={closeCustomizedWidgetList}
        />
        <div className="flex justify-center relative">
          {isLoading ? (
            <PageLoader />
          ) : (
            <div className="flex flex-col md:grid grid-cols-2 xl:grid-cols-4 gap-4 p-8 w-full max-w-[1384px] m-fixed">
              {enabledWidgets.map((widget, index) => {
                const divStyle = widgetStyles?.[widget.widget_name] || "";
                return (
                  <div
                    key={`widget_card_list_${index}_${widget.widget_id}`}
                    className={`${divStyle}`}
                  >
                    {widgetMap[widget.widget_name]}
                  </div>
                );
              })}
              {disabledWidgets.length > 0 && (
                <div className={`${widgetStyles?.[disabledWidgets[0].widget_name] || ""}`}>
                  <AddWidgetCard
                    onAddWigetClick={handleAddWidgetClick}
                    key={`add_widget_card_button_0`}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {customizingWidgets && (
        <CustomizeWidgetList
          handleWidgetUpdate={handleWidgetUpdate}
          closeCustomizedWidgetList={closeCustomizedWidgetList}
        />
      )}
    </Fragment>
  );
};

export default EmployeePortal;
