import React, { Fragment, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Line from "../Line";
import Avatar from "../Avatar";
import CancelLeaveModal from "../CancelLeaveModal/CancelLeaveModal";
import formatDate from "../../utils/FormatDate/formatDate";
import formatLeaveDuration from "../../utils/FormatLeaveDuration/formatLeaveDuration";
import formatLeaveApplicationStatus from "../../utils/FormatLeaveApplicationStatus/formatLeaveApplicationStatus";
import { closeIcon } from "../../Icons/Icons";
import LeaveRequestHistory from "../Leaves/SlidingPanels/LeaveRequestHistory";
import { useSelector } from "react-redux";
import LeaveRemarkViewModal from "./LeaveRemarkViewModal";

const PENDING_LABEL = "Pending";
const APPROVE_LABEL = "Approved";

const LeaveDetailsModal = ({
  makeApiCall,
  open,
  close,
  fullDetails,
  isAdditionalLeaveTab,
}) => {
  const authState = useSelector((state) => state.user);
  const { settingValue } = authState;
  const [isCancelLeaveModal, setIsCancelLeaveModal] = useState(false);
  const [isLeaveHistorySlidingPanel, setLeaveHistorySlidingPanel] =
    useState(false);
  const leave = fullDetails?.leave_details;
  const hrDetails = fullDetails?.hr_details;
  const managerDetails = fullDetails?.manager_details;
  const leaveReason = leave?.leave_reason || "No remarks";
  const leaveSerial = leave?.leave_serial;
  const applicationDate = formatDate(leave?.application_date);
  const durationInDay = leave?.duration_in_day || 0;
  const durationInHour = leave?.duration_in_hour || 0;
  const duration = isAdditionalLeaveTab
    ? "1 day"
    : formatLeaveDuration({ durationInDay, durationInHour });

  const fromDate = formatDate(
    isAdditionalLeaveTab ? leave?.applied_for_date : leave?.from_date
  );
  const toDate = formatDate(leave?.to_date);
  const hrStatus = hrDetails?.hr_approval_status_label;
  const managerStatus = managerDetails?.manager_approval_status_label;
  const leaveName = leave?.leave_name;
  const isCanceled = leave?.is_canceled;
  const status = isCanceled
    ? formatLeaveApplicationStatus("canceled")
    : formatLeaveApplicationStatus(hrStatus, managerStatus);
  const {
    leaveStatus,
    leaveStatusClass,
    hrStatusClass,
    managerStatusClass,
    hrStatusLabel,
    managerStatusLabel,
  } = status;
  const hrStatusTime = formatDate(hrDetails?.updated_time);
  const managerStatusTime = formatDate(managerDetails?.updated_time);
  const hrPhoto = hrDetails?.photo;
  const hrName = `${hrDetails?.first_name || ""} ${hrDetails?.last_name || ""}`;
  const hrRemark = hrDetails?.remark || leave?.hr_comments || "No remarks";
  const hrDesignation = hrDetails?.designation_title || "";
  const managerName = `${managerDetails?.first_name || ""} ${
    managerDetails?.last_name || ""
  }`;
  const managerPhoto = managerDetails?.photo;
  const managerRemark =
    managerDetails?.remark || leave?.manager_comments || "No remarks";
  const managerDesignation = managerDetails?.designation_title || "";
  // console.log("leeave details");

  const isLeaveApplicationApprovedOrPendingAndDateOver =
    (leaveStatus === APPROVE_LABEL &&
      !(new Date() > new Date(leave.from_date))) ||
    (leaveStatus === PENDING_LABEL &&
      !(new Date() > new Date(leave.from_date)));
  const isCancelBtnEnabled =
    !isAdditionalLeaveTab &&
    !isCanceled &&
    isLeaveApplicationApprovedOrPendingAndDateOver;

  const handleLeaveHistorySlidingPanel = () => {
    setLeaveHistorySlidingPanel((prev) => !prev);
  };

  const truncatedEmployeeRemark =
    leaveReason.length > 70
      ? leaveReason.substring(0, 70) + "..."
      : leaveReason;

  const truncatedManagerRemark =
    managerRemark.length > 70
      ? managerRemark.substring(0, 70) + "..."
      : managerRemark;

  const truncatedHrRemark =
    hrRemark.length > 70 ? hrRemark.substring(0, 70) + "..." : hrRemark;

  const [isRemarkModalOpen, setIsRemarkModalOpen] = useState(false);
  const [currentRemark, setCurrentRemark] = useState("");

  const HrViewType = "hr";
  const ManagerViewType = "manager";
  const EmployeeViewType = "employee";

  const handleViewMore = (type) => {
    if (type === HrViewType && hrRemark) {
      setCurrentRemark(hrRemark);
    } else if (type === ManagerViewType && managerRemark) {
      setCurrentRemark(managerRemark);
    } else if (type === EmployeeViewType && leaveReason) {
      setCurrentRemark(leaveReason);
    } else {
      setCurrentRemark("");
    }
    setIsRemarkModalOpen(true);
  };

  // addtional leave

  let HrActionView;

  if (isAdditionalLeaveTab) {
    HrActionView = settingValue.isHrLeaveApprovalRequiredForAdditionalLeaves;
  } else {
    HrActionView = settingValue.isHrLeaveApprovalRequired;
  }

  const formattedManagerUpdatedRemark =
    managerStatusLabel === PENDING_LABEL
      ? "--:-- --"
      : `${managerStatusTime?.localDate1 || ""} - ${managerStatusTime?.localString2 || ""}`;
  
  const formattedHrUpdatedRemark =
    hrStatusLabel === PENDING_LABEL
      ? "--:-- --"
      : `${hrStatusTime?.localDate1 || ""} - ${hrStatusTime?.localString2 || ""}`;

  return (
    <Fragment>
      <SlidingPane isOpen={open} width="480px" onRequestClose={close}>
        <CancelLeaveModal
          open={isCancelLeaveModal}
          close={() => setIsCancelLeaveModal(false)}
          leave={leave}
          setState={close}
          makeApiCall={makeApiCall}
        />
        {isLeaveHistorySlidingPanel ? (
          <LeaveRequestHistory
            open={isLeaveHistorySlidingPanel}
            close={handleLeaveHistorySlidingPanel}
            leave={leave}
          />
        ) : null}
        <div className="flex flex-col justify-between h-full ">
          <div>
            <div className="flex justify-between px-5 pb-3">
              <h2 className="">Leave Request Details</h2>
              <div className="flex gap-2 items-center">
                <span
                  className="text-blue-600 cursor-pointer"
                  onClick={handleLeaveHistorySlidingPanel}
                >
                  View History
                </span>
                <span className="cursor-pointer" onClick={close}>
                  {closeIcon}
                </span>
              </div>
            </div>
            <Line />
            <div className="px-5">
              <h2 className="mb-4">Leave Informations</h2>
              <div className="flex justify-between">
                <p>Leave Request ID</p>
                <p className="text-gray-800 font-medium text-sm">
                  {leaveSerial}
                </p>
              </div>
              <div className="flex justify-between mt-2">
                <p>Status</p>
                <span
                  className={`rounded-lg w-20 ${leaveStatusClass} text-center px-2`}
                >
                  {leaveStatus}
                </span>
              </div>
              <div className="flex justify-between mt-2">
                <div>Request Date</div>
                <p className="text-sm font-medium text-gray-800 leading-5">
                  {applicationDate.dateString2}
                </p>
              </div>
              <div className="flex justify-between leading-5 mt-2">
                <p>Leave Type</p>
                <p className="text-gray-800 text-sm font-medium">{leaveName}</p>
              </div>
              <div className="flex justify-between mt-2">
                <div>Leave Date</div>
                <p className="text-gray-800 text-sm font-medium">
                  {`${fromDate.dateString2} ${
                    toDate.dateString2 ? `- ${toDate.dateString2}` : ""
                  }`}
                </p>
              </div>
              <div className="flex justify-between mt-2">
                <p>Total</p>
                <div className="text-gray-800 text-sm font-medium">
                  {duration}
                </div>
              </div>

              <div className="flex justify-between mt-2">
                <p>Note</p>
              </div>
              <div className="bg-[#F9FAFB]  w-[435px] h-14 mt-2 rounded-sm mb-5">
                <div className="flex p-3 gap-3 ">
                  <div className="mt-1 ml-3">
                    <img src="/images/messageicon.png" alt="" />
                  </div>
                  <div className="basis-4/5">
                    <p>
                      {truncatedEmployeeRemark || "No Note"}
                      {leaveReason.length > 70 && (
                        <span
                          className="text-blue-600 cursor-pointer"
                          onClick={() => handleViewMore(EmployeeViewType)}
                        >
                          View more
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="bg-[#F9FAFB]  w-[435px] h-14 mt-2 rounded-sm mb-5">
                <div className="flex p-3 gap-3 ">
                  <div className="mt-1 ml-3">
                    <img src="/images/messageicon.png" alt="" />
                  </div>
                  <div className="basis-1/2">
                    <p>{leaveReason || "No remark"}</p>
                  </div>
                </div>
              </div> */}
              <Line />
              <div>
                <h2>Leave Approval Status</h2>
              </div>
              {HrActionView ? (
                <>
                  <div className="flex justify-between mt-3">
                    <div className="flex justify-between gap-3">
                      <div>
                        <Avatar image={hrPhoto} />
                      </div>
                      <div>
                        <p className="text-sm text-gray-800 font-medium">
                          {hrName}
                        </p>
                        <p className="font-normal text-xs">{hrDesignation}</p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4">
                      <p>
                        {formattedHrUpdatedRemark}
                      </p>
                      <div>
                        <span className={`${hrStatusClass}`}>
                          {!isCanceled && hrStatusLabel}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-[#F9FAFB]  w-[435px] h-14 mt-2 rounded-sm mb-2">
                    <div className="flex p-3 gap-3 ">
                      <div className="mt-1 ml-3">
                        <img src="/images/messageicon.png" alt="" />
                      </div>
                      <div className="basis-basis-4/5">
                        <p>
                          {truncatedHrRemark || "No Note"}
                          {hrRemark.length > 70 && (
                            <span
                              className="text-blue-600 cursor-pointer"
                              onClick={() => handleViewMore(HrViewType)}
                            >
                              View more
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              <div className="flex justify-between mt-3">
                <div className="flex justify-between gap-3">
                  <div>
                    <Avatar image={managerPhoto} />
                  </div>
                  <div>
                    <p className="text-sm text-gray-800 font-medium">
                      {managerName}
                    </p>
                    <p className="font-normal text-xs">{managerDesignation}</p>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <p>
                    {formattedManagerUpdatedRemark}
                  </p>
                  <div>
                    <span className={`${managerStatusClass}`}>
                      {!isCanceled && managerStatusLabel}
                    </span>
                  </div>
                </div>
              </div>
              <div className="bg-[#F9FAFB]  w-[435px] h-14 mt-2 rounded-sm mb-5">
                <div className="flex p-3 gap-3 ">
                  <div className="mt-1 ml-3">
                    <img src="/images/messageicon.png" alt="" />
                  </div>
                  <div className="basis-4/5">
                    <p>
                      {truncatedManagerRemark || "No Note"}
                      {managerRemark.length > 70 && (
                        <span
                          className="text-blue-600 cursor-pointer"
                          onClick={() => handleViewMore(ManagerViewType)}
                        >
                          View more
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div>
              <Line />
            </div>

            <div className="flex justify-end items-end px-5">
              <div className="mr-2">
                <button className="btn btn--border text-[14px]" onClick={close}>
                  Close
                </button>
              </div>
              <LeaveRemarkViewModal
                isOpen={isRemarkModalOpen}
                close={() => setIsRemarkModalOpen(false)}
                remark={currentRemark}
              />
              <div>
                <button
                  className={`btn btn--red text-[14px] pt-[11px] ${
                    !isCancelBtnEnabled ? "bg-red-300 hover:bg-red-300" : ""
                  }`}
                  onClick={() => setIsCancelLeaveModal(true)}
                  disabled={!isCancelBtnEnabled}
                >
                  Cancel Leave
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlidingPane>
    </Fragment>
  );
};

export default LeaveDetailsModal;
