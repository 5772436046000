import React from "react";
import Avatar from "../Avatar";
import { useSelector } from "react-redux";

const ContactHR = () => {
  const profile = useSelector((state) => state.user.auth.connectedAthority);
  const [hrDetails] = useSelector((state) => state.user.hrDetails)
  const photo = hrDetails ? hrDetails.photo : "/images/avatar.jpg";
  const name = hrDetails ? `${hrDetails?.first_name || ""} ${hrDetails?.last_name || ""}` : `${profile?.first_name || ""} ${profile?.last_name || ""}`;
  const roleName = hrDetails ? hrDetails.role_name || "" : profile?.role_name || "";

  return (
    <div className="rounded border border-gray-300 p-6">
      <h2 className="text-base font-semibold text-gray-600 mb-1">
        Your HR Contact
      </h2>
      <p>
        If you have any question about our offer, please call or message us.
      </p>
      <div className="flex items-center mt-4">
        <Avatar classes="mr-4" image={photo} />
        <div>
          <h3 className="text-gray-600 font-semibold">{name}</h3>
          <h4>{roleName}</h4>
        </div>
        <div className="ml-auto">
          <a href="#" className="p-1 hover:text-green-400">
            <i className="ico-phone"></i>
          </a>
          <a href="#" className="p-1 hover:text-green-400 ml-2">
            <i className="ico-email"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactHR;
