import React, { useState, useEffect } from "react";
import PreferenceTab from "../../components/Assets/AssetsTab/PreferenceTab";
import Select, { components } from "react-select";
import Search from "../../components/Header/Search/Search";
import LeaveApplicationList from "./LeaveApplicationList";
import ApproveLeaveModal from "./ApproveLeaveModal";
// import RevokeLeaveModal from "./RevokeLeaveModal"; can be used later
import DeclineLeaveModal from "./DeclineLeaveModal";
import { useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import Pagination from "../../components/Pagination/Pagination";
import EmptyState from "../../components/EmptyState/EmptyState";
import PageLoader from "../../components/PageLoader/PageLoader";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import LeaveDetailsModal from "./LeaveDetailsModal";
import LeaveReportDownloadOption from "../../components/Leaves/ManageLeave/Overview/LeaveReportDownloadOption";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const LEAVE_REQUEST_VIEW_DATA = 1;
const additionalLeaveIndex = 2;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const TaskLeaveApplications = () => {
  const authState = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { leaveApplicationId: leaveApplicationIdFromParams } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const additionalLeaveApplicationidFromParam = searchParams.get(
    "additional-leave-details"
  );
  const user = authState.auth;
  const isAdmin = userRoleAuth(user, 1);
  const isHr = userRoleAuth(user, 2) || isAdmin;
  const isManager = userRoleAuth(user, 4);

  const [index, setIndex] = useState(0);
  const [selectedId, setSelectedId] = useState([]);
  const [leaveApplications, setLeaveApplications] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const [totalRecords, setTotalRecords] = useState(null);
  const [leaveTypesList, setLeaveTypesList] = useState(null);
  const [eligibleToApproveOrDecline, setEligibleToApproveOrDecline] = useState(
    []
  );
  const { settingValue: setting } = authState;
  // console.log("aurh state",authState)

  const [locationList, setLocationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);

  const fetchLocationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getLocationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setLocationList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDepartmentList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDepartmentList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDepartmentList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDesignationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDesignationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDesignationList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchDesignationList();
    fetchDepartmentList();
    fetchLocationList();
  }, []);

  const getTabIndex = (e) => {
    setIndex(e);
  };

  const getCheckboxState = (e) => {
    if (e) {
      if (leaveApplications) {
        const selectedId = leaveApplications.map((leave) => {
          return leave.leave_application_id;
        });
        setSelectedId(selectedId);
      }
    } else {
      setSelectedId([]);
    }
  };

  const filterEligibleLeaveApplications = () => {
    if (leaveApplications) {
      let newArr = [];
      for (let i = 0; i < leaveApplications.length; i++) {
        for (let j = 0; j < selectedId.length; j++) {
          let leaveApplicationId =
            leaveApplications[i].leave_application_id ||
            leaveApplications[i].id;
          if (leaveApplicationId === selectedId[j]) {
            if (isHr) {
              let hrApprovalId = leaveApplications[i].hr_approval_status_id;
              let managerApprovalId =
                leaveApplications[i].manager_approval_status_id;
              if (
                Number(hrApprovalId) === 2 &&
                !leaveApplications[i].is_canceled &&
                Number(managerApprovalId) !== 3
              ) {
                newArr.push(leaveApplications[i]);
              }
            } else if (isManager) {
              if (
                !leaveApplications[i].is_canceled &&
                leaveApplications[i].manager_approval_status_label === "Pending"
              ) {
                newArr.push(leaveApplications[i]);
              }
            }
          }
        }
      }
      setEligibleToApproveOrDecline(newArr);
    }
  };

  useEffect(() => {
    filterEligibleLeaveApplications();
  }, [selectedId]);

  const tabs = ["All Status", "Approved", "Pending", "Declined", "Cancelled"];

  // FILTERS STARTS***
  const [leaveType, setLeaveType] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [applicationDate, setApplicationDate] = useState(null);
  const [appliedDate, setAppliedDate] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [department, setDepartment] = useState(null);
  const [location, setLocation] = useState(null);
  const [search, setSearch] = useState(null);
  const [dateRange, setDateRange] = useState({
    fromDate: null,
    toDate: null,
  });
  // FILTERS ENDS***

  const [approve, setApprove] = useState(false);
  const [revoke, setRevoke] = useState(false);
  const [decline, setDecline] = useState(false);

  const [loaderComponent, setLoaderComponent] = useState(<PageLoader />);

  const [hrBaseUrl, setHrBaseUrl] = useState(
    `lms/leave/hrApprovalList?pageNo=${pageNo}`
  );
  const [managerBaseUrl, setManagerBaseUrl] = useState(
    `lms/leave/managerApprovalList?page_limit=${pageSize}&page_number=${pageNo}&sort_order=DESC`
  );
  const [makeApiCall, setMakeApiCall] = useState(false);
  const [isMultipleAction, setIsMultipleAction] = useState(false);
  const [toggleState, toggleTab] = useState(
    additionalLeaveApplicationidFromParam
      ? additionalLeaveIndex
      : LEAVE_REQUEST_VIEW_DATA
  );
  let approvalBtnAction;

  if (toggleState === LEAVE_REQUEST_VIEW_DATA) {
    approvalBtnAction = isHr ? setting.isHrLeaveApprovalRequired : true;
  } else {
    approvalBtnAction = isHr
      ? setting.isHrLeaveApprovalRequiredForAdditionalLeaves
      : true;
  }

  // console.log('approvalBtnAction:', approvalBtnAction);

  const getSearchValue = (e) => {
    setSearch(e);
  };

  const handleDateRangeChange = (e) => {
    setDateRange({
      fromDate: e ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
      toDate: e ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
    });
  };

  //FILTER SETUP ON BASE URL STARTS***
  // console.log("page number",pageNo , pageSize)
  const setFiltersToQuery = () => {
    let hrBaseUrl;
    let managerBaseUrl;
    if (toggleState === LEAVE_REQUEST_VIEW_DATA) {
      hrBaseUrl = `lms/leave/hrApprovalList?pageNo=${pageNo}&pageSize=${pageSize}`;
      managerBaseUrl = `lms/leave/managerApprovalList?page_limit=${pageSize}&page_number=${pageNo}&sort_order=DESC`;
    } else {
      managerBaseUrl = `lms/leave/additionalLeaveManagerApprovalList?page_number=${pageNo}&page_limit=${pageSize}&sort_order=DESC`;
      hrBaseUrl = `lms/leave/additionalLeaveHrApprovalList?page_number=${pageNo}&page_limit=${pageSize}&sort_order=DESC`;
    }

    if (Number(index) !== 0) {
      managerBaseUrl += `&status_id=${index}`;
      if (toggleState === LEAVE_REQUEST_VIEW_DATA) {
        hrBaseUrl += `&hr_approval_status_id=${index}`;
      } else {
        hrBaseUrl += `&status_id=${index}`;
      }
    }
    if (leaveType) {
      managerBaseUrl += `&leave_type_id=${leaveType.value}`;
      hrBaseUrl += `&leave_type_id=${leaveType.value}`;

      setPageNo(1);
    }
    if (applicationDate) {
      managerBaseUrl += `&application_date=${applicationDate}`;
      hrBaseUrl += `&application_date=${applicationDate}`;
      setPageNo(1);
    }
    if (appliedDate) {
      //   console.log("applied_for_date")
      managerBaseUrl += `&applied_for_date=${appliedDate}`;
      hrBaseUrl += `&applied_for_date=${appliedDate}`;
      setPageNo(1);
    }
    if (designation) {
      managerBaseUrl += `&designation_id=${designation.value}`;
      hrBaseUrl += `&designation=${designation.value}`;
      setPageNo(1);
    }
    if (department) {
      managerBaseUrl += `&department_id=${department.value}`;
      hrBaseUrl += `&department=${department.value}`;
      setPageNo(1);
    }
    if (location) {
      hrBaseUrl += `&location_id=${location.value}`;
      managerBaseUrl += `&location_id=${location.value}`;
      setPageNo(1);
    }
    if (dateRange?.fromDate) {
      hrBaseUrl += `&from_date=${dateRange?.fromDate}`;
      managerBaseUrl += `&from_date=${dateRange?.fromDate}`;
    }
    if (dateRange?.toDate) {
      hrBaseUrl += `&to_date=${dateRange?.toDate}`;
      managerBaseUrl += `&to_date=${dateRange?.toDate}`;
    }

    if (search) {
      const delayTimer = setTimeout(() => {
        hrBaseUrl += `&filter=${search}`;
        setPageNo(1);
        setHrBaseUrl(hrBaseUrl);
        setManagerBaseUrl(managerBaseUrl);
      }, 600);

      return () => {
        clearTimeout(delayTimer);
      };
    }
    setHrBaseUrl(hrBaseUrl);
    setManagerBaseUrl(managerBaseUrl);
  };

  useEffect(() => {
    setFiltersToQuery();
  }, [
    index,
    pageNo,
    pageSize,
    leaveType,
    applicationDate,
    appliedDate,
    location,
    department,
    designation,
    search,
    toggleState,
    dateRange,
  ]);
  //FILTER SETUP ON BASE URL ENDS***

  const setLoadingState = () => {
    setLoaderComponent(<PageLoader />);
    setLeaveApplications(null);
    setTotalRecords(null);
  };

  const setEmptyState = () => {
    setLeaveApplications(null);
    setTotalRecords(null);
    setLoaderComponent(<EmptyState />);
  };

  const fetchHrApprovalList = async () => {
    setLoadingState();
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(hrBaseUrl)
      .then((response) => {
        // isMounted && console.log(response.data, "hr");
        if (response.data.status) {
          const res = response.data;
          setTimeout(() => {
            setLeaveApplications(res.data || res.approval_List);
            setTotalRecords(res.total);
            setLoaderComponent(null);
          }, 500);
        } else if (
          response.data.message === "No data found" ||
          response.data.message === "No data found in page"
        ) {
          if (pageNo === 1) {
            setEmptyState();
            return;
          }
          setPageNo(1);
        } else {
          setEmptyState();
        }
      })
      .catch((err) => {
        console.error(err);
        setEmptyState();
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchManagerApprovalList = async () => {
    setLoadingState();
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(managerBaseUrl)
      .then((response) => {
        // isMounted && console.log(response.data, "manager");
        if (response.data.status) {
          const res = response.data;
          setTimeout(() => {
            setLeaveApplications(res.approval_List);
            setTotalRecords(res.total);
            setLoaderComponent(null);
          }, 500);
        } else {
          setEmptyState();
        }
      })
      .catch((err) => {
        console.error(err);
        if (Number(err.response.data.total) > 0) {
          setPageNo(1);
        } else {
          setEmptyState();
        }
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchLeaveTypesList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("lms/leave/leaveTypesList?status=1")
      .then((response) => {
        // isMounted && console.log(response.data, "leavetypelist");
        const res = response?.data;
        if (res.status) {
          setLeaveTypesList(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchLeaveTypesList();
  }, []);

  useEffect(() => {
    if (isHr) {
      fetchHrApprovalList();
    } else if (isManager) {
      fetchManagerApprovalList();
    } else {
      setEmptyState();
    }
  }, [hrBaseUrl, managerBaseUrl, makeApiCall]);

  const clearFilters = async () => {
    setLeaveType(null);
    setApplicationDate(null);
    setAppliedDate(null);
    setDepartment(null);
    setDesignation(null);
    setSearch(null);
    setLocation(null);
  };

  const multipleDeclineModal = () => {
    setDecline(!decline);
    setIsMultipleAction(!decline);
  };

  const multipleApproveModal = () => {
    setApprove(!approve);
    setIsMultipleAction(!approve);
  };

  const isFilterApplied = () => {
    const filters = [
      applicationDate,
      appliedDate,
      department,
      designation,
      search,
      leaveType,
      location,
    ];
    for (let i = 0; i < filters.length; i++) {
      if (filters[i]) return true;
    }
    return false;
  };

  const [isLeaveDetailsModal, setIsLeaveDetailsModal] = useState(false);
  const onCloseLeaveDetailsModal = () => {
    setIsLeaveDetailsModal(false);
    navigate("/leave/manage-leave/employee-leave-applications");
    setMakeApiCall((prev) => !prev);
  };
  const [fullDetails, setFullDetails] = useState(null);

  const fetchFullLeaveDetails = async (id, isAdditionalLeaveId) => {
    const isAdditionalLeaveApplication =
      isAdditionalLeaveId || toggleState === additionalLeaveIndex;
    const baseUrl = isAdditionalLeaveApplication
      ? `lms/leave/additionalLeaveDetails?leave_application_id=${id}&include_other_employees=true`
      : `lms/leave/leaveDetails?leave_application_id=${id}&include_other_employees=true`;
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(baseUrl)
      .then((response) => {
        // isMounted && console.log(response.data, "fullDetails");
        const res = response?.data;
        if (res.status) {
          setFullDetails(res.data);
          setIsLeaveDetailsModal(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    if (leaveApplicationIdFromParams || additionalLeaveApplicationidFromParam) {
      const id =
        leaveApplicationIdFromParams || additionalLeaveApplicationidFromParam;
      const isAdditionalLeaveId = !!additionalLeaveApplicationidFromParam;
      fetchFullLeaveDetails(id, isAdditionalLeaveId);
    }
  }, []);

  const [isReportDownloadModal, setIsReportDownloadModal] = useState(false);

  return (
    <>
      {isLeaveDetailsModal ? (
        <LeaveDetailsModal
          openModal={isLeaveDetailsModal}
          onCloseModal={onCloseLeaveDetailsModal}
          fullDetails={fullDetails}
          makeApiCall={() => setMakeApiCall(!makeApiCall)}
          toggleState={toggleState}
          approvalBtnAction={approvalBtnAction}
        />
      ) : null}
      {approve ? (
        <ApproveLeaveModal
          openModal={approve}
          onCloseModal={multipleApproveModal}
          eligibleToApproveOrDecline={eligibleToApproveOrDecline}
          makeApiCall={() => setMakeApiCall(!makeApiCall)}
          setSelectedId={setSelectedId}
          isMultipleAction={isMultipleAction}
        />
      ) : null}
      {/* <RevokeLeaveModal
        openModal={revoke}
        onCloseModal={() => setRevoke(!revoke)}
        makeApiCall={() => setMakeApiCall(!makeApiCall)}
      /> */}
      {decline ? (
        <DeclineLeaveModal
          openModal={decline}
          onCloseModal={multipleDeclineModal}
          eligibleToApproveOrDecline={eligibleToApproveOrDecline}
          makeApiCall={() => setMakeApiCall(!makeApiCall)}
          setSelectedId={setSelectedId}
          isMultipleAction={isMultipleAction}
        />
      ) : null}
      <div className="w-full overflow-x-scroll">
        <div className="px-10 py-6">
          <div className="my-2">
            <h2 className="text-lg font-semibold">Leave Approval</h2>
            <p>Manage all leave requests</p>

            <div className="flex items-center justify-between  border-b-2">
              <div className="flex items-center gap-12 pl-4 pt-6">
                <button
                  className={
                    toggleState === LEAVE_REQUEST_VIEW_DATA
                      ? "tabs active-tabsnew text-blue-800 font-medium"
                      : "tabs"
                  }
                  onClick={() => toggleTab(1)}
                >
                  Leave
                </button>
                <button
                  className={
                    toggleState === additionalLeaveIndex
                      ? "tabs active-tabsnew text-blue-800 font-medium"
                      : "tabs"
                  }
                  onClick={() => toggleTab(2)}
                >
                  Additional Leave
                </button>
              </div>
            </div>

            <PreferenceTab tabs={tabs} getTabIndex={getTabIndex} />
            {!selectedId.length ? (
              <>
                <div className="flex  justify-between">
                  <div className="flex  flex-wrap gap-3 my-6">
                    <div className="flex flex-wrap gap-3">
                      {toggleState === additionalLeaveIndex ? (
                        <DatePicker
                          placeholder=" Applied For"
                          onChange={(date) =>
                            setAppliedDate(
                              date ? dayjs(date).format("YYYY-MM-DD") : null
                            )
                          }
                        />
                      ) : (
                        <RangePicker
                          className="w-[300px] h-10"
                          placeholder={["From Date", "To Date"]}
                          onChange={handleDateRangeChange}
                          format="YYYY-MM-DD"
                        />
                      )}
                      <DatePicker
                        placeholder="Requested Date"
                        onChange={(date) =>
                          setApplicationDate(
                            date ? dayjs(date).format("YYYY-MM-DD") : null
                          )
                        }
                        className="h-10"
                      />
                    </div>
                    <Select
                      options={
                        leaveTypesList &&
                        leaveTypesList.map((type) => ({
                          value: type.id,
                          label: type.leave_name,
                        }))
                      }
                      components={{ DropdownIndicator }}
                      className="custom-select-container"
                      value={
                        leaveType || {
                          value: 0,
                          label: "Type: All",
                        }
                      }
                      onChange={(e) => setLeaveType(e)}
                    />
                    <Select
                      options={designationList.map((i) => ({
                        label: i.designation_title,
                        value: i.id,
                      }))}
                      components={{ DropdownIndicator }}
                      className="custom-select-container"
                      value={
                        designation || {
                          value: 0,
                          label: "Designation",
                        }
                      }
                      onChange={(e) => setDesignation(e)}
                    />
                    <Select
                      options={departmentList.map((i) => ({
                        label: i.department_name,
                        value: i.id,
                      }))}
                      components={{ DropdownIndicator }}
                      className="custom-select-container"
                      value={
                        department || {
                          value: 0,
                          label: "Department",
                        }
                      }
                      onChange={(e) => setDepartment(e)}
                    />
                    <Select
                      options={locationList.map((i) => ({
                        label: i.location_name,
                        value: i.id,
                      }))}
                      components={{ DropdownIndicator }}
                      className="custom-select-container"
                      value={
                        location || {
                          value: 0,
                          label: "Location",
                        }
                      }
                      onChange={(e) => setLocation(e)}
                    />
                    {isFilterApplied() ? (
                      <p
                        className="text-blue-800 font-medium whitespace-nowrap flex items-center cursor-pointer"
                        onClick={() => clearFilters()}
                      >
                        Clear Filters
                      </p>
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-3 items-center my-6">
                    <div className="flex flex-wrap w-full">
                      <button
                        type="button"
                        className="btn btn--border h-[38px]"
                        onClick={() => setMakeApiCall(!makeApiCall)}
                      >
                        <span className="material-symbols-outlined">
                          refresh
                        </span>
                      </button>
                      <Search onChange={getSearchValue} />
                    </div>
                    {isHr ? (
                      <div className="relative w-full">
                        <button
                          onClick={() =>
                            setIsReportDownloadModal((prev) => !prev)
                          }
                          className="btn btn--border btn--full  text-[14px]"
                        >
                          <span>
                            <i className="ico-download mr-1"></i>
                          </span>
                          Exports
                        </button>
                        {isReportDownloadModal ? (
                          <div className="absolute top-12 right-0 bg-white z-50 shadow-sm border rounded-md p-2 w-auto">
                            <LeaveReportDownloadOption
                              setIsReportDownloadModal={
                                setIsReportDownloadModal
                              }
                              filters={hrBaseUrl}
                            />
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center justify-between my-4">
                  <div className="flex items-center gap-3">
                    <a href="" className="btn btn--border w-9 h-10">
                      <span>
                        <i className="ico-download"></i>
                      </span>
                    </a>
                    <a href="">
                      <img
                        src="/images/print-icon.svg"
                        alt=""
                        className="cursor-pointer p-2 btn btn--border w-9 h-10"
                      />
                    </a>

                    {/* <button
                      className="btn btn--border"
                      onClick={() => setRevoke(true)}
                      type="button"
                    >
                      Revoke
                      <span>
                        <img
                          src="/images/revoke-icon.svg"
                          alt=""
                          className="pl-3"
                        />
                      </span>
                    </button> */}

                    {approvalBtnAction && (
                      <>
                        <button
                          className="btn btn--red"
                          onClick={multipleDeclineModal}
                          type="button"
                        >
                          Decline
                          <span>
                            <img
                              src="/images/decline-white-icon.svg"
                              alt=""
                              className="pl-3"
                            />
                          </span>
                        </button>

                        <button
                          className="btn btn--green"
                          onClick={multipleApproveModal}
                          type="button"
                        >
                          Approve
                          <span>
                            <img
                              src="/images/approve-white-icon.svg"
                              alt=""
                              className="pl-3"
                            />
                          </span>
                        </button>
                      </>
                    )}
                  </div>
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-medium text-black">
                      {selectedId.length} Leave request selected
                    </p>
                    <img
                      src="/images/decline-red-icon.svg"
                      alt=""
                      className="cursor-pointer p-1 border border-red-600 rounded-full"
                      onClick={() => setSelectedId([])}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {loaderComponent}
        {leaveApplications && (
          <LeaveApplicationList
            setIsAllChecked={getCheckboxState}
            leaveApplications={leaveApplications}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            makeApiCall={() => setMakeApiCall(!makeApiCall)}
            toggleState={toggleState}
            approvalBtnAction={approvalBtnAction}
          />
        )}

        {leaveApplications ? (
          <Pagination
            page={{
              pageNo,
              setPageNo,
              pageSize,
              setPageSize,
              totalRecords,
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default TaskLeaveApplications;
