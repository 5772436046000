import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { closeIcon } from "../../../Icons/Icons";
import Select from "react-select";
import Avatar from "../../Avatar";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

const AssignLineManagerModal = ({ open, close, selectedEmployees }) => {
  const axiosPrivate = useAxiosPrivate();
  const [managerList, setManagerList] = useState([]);
  const [selectedLineManagerId, setSelectedLineManagerId] = useState(null);
  const fetchLineManager = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(
        "employee/search?pageNo=1&pageSize=100&sortOrder=DESC&role=Manager",
        {
          signal: controller.signal,
        }
      )
      .then((response) => {
        // isMounted && console.log(response.data);
        const res = response?.data;
        setManagerList(res.employee || []);
      })
      .catch((err) => {
        throw err;
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchLineManager();
  }, []);

  const assignLineManager = async (reqObj) => {
    await axiosPrivate
      .put("employee/assignLineManager", { employeeList: reqObj })
      .then((res) => {
        // console.log(res);
        if (res?.data?.status) {
          toast.success(
            res?.data?.message ||
              "Line manager updated for the selected employee(s)"
          );
          close();
        } else {
          toast.info(res?.data?.message || "Something went wrong... try again");
        }
      })
      .catch((err) => {
       //  console.log(err.response);
        toast.error(
          err?.response?.data?.error ||
            "Failed to assign line manager(s)"
        );
      });
  };

  const onSubmit = () => {
    if (!selectedLineManagerId) {
      return toast.info("Select a line manager from the list");
    }
    const reqObj = selectedEmployees.map((employee) => ({
      employeeId: employee.employee_id,
      lineManagerId: selectedLineManagerId,
    }));
    assignLineManager(reqObj);
  };

  return (
    <Modal open={open} onClose={close} center showCloseIcon={false}>
      <div className="w-[500px] flex flex-col">
        <div className="flex justify-between items-center gap-5">
          <h1 className="text-xl font-medium text-gray-800">
            Assign Line Manager
          </h1>
          <button onClick={close}>{closeIcon}</button>
        </div>
        <div className="py-2">
          <label>Select line manager</label>
          <Select
            options={managerList}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            getOptionValue={(option) => option.employee_id}
            onChange={(e) => setSelectedLineManagerId(e.employee_id)}
          />
        </div>
        <div>
          {selectedEmployees.map((i) => {
            const empShortName = `${
              i?.first_name?.slice(0, 1)?.toUpperCase() || ""
            }${i?.last_name?.slice(0, 1)?.toUpperCase() || ""}`;
            const empName = `${i.first_name || ""} ${i.last_name || ""}`;
            return (
              <div className="border rounded-3xl px-2 py-1 my-2 flex items-center gap-2 bg-gray-50">
                <Avatar width="8" height="8" title={empShortName} />
                <span className="text-gray-800">{empName}</span>
              </div>
            );
          })}
        </div>

        <div className="flex gap-3 justify-end">
          <button className="btn btn--border" onClick={close}>
            Close
          </button>
          <button className="btn" onClick={onSubmit}>
            Assign Line Manager
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AssignLineManagerModal;
