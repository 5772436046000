import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import ToggleSwitch from "../../../ToggleSwitch/ToggleSwitch";
import { useForm, Controller } from "react-hook-form";
import { carryForwardTypeOptions } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon } from "../../../../Icons/Icons";
import { genderOptions } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const EditLeaveTypeModal = ({
  openModal,
  onCloseModal,
  makeApiCall,
  leaveTypeData,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      leaveTypeName: leaveTypeData.leave_name,
      numberOfDaysAllowed: leaveTypeData.number_days_allowed,
      carryForward: leaveTypeData.carry_forward,
      carryForwardLimit: leaveTypeData.carry_forward_limit,
      carryForwardType: leaveTypeData.carry_forward_type,
      carryForwardYears: leaveTypeData.carry_forward_years,
      status: leaveTypeData.status,
      description: leaveTypeData.description,
      restrictPriorApplication: leaveTypeData.restrict_prior_application,
      priorDays: leaveTypeData.prior_days,
      canRequestByEmployee: Number(leaveTypeData.can_request_by_employee),
      paid: Number(leaveTypeData.paid),
      isLop: Number(leaveTypeData?.is_lop || 0),
      gender: leaveTypeData.gender,
      isAutoIncrementEnable: Number(leaveTypeData.is_auto_increment_enabled),
      autoIncrementValue: leaveTypeData.auto_increment_value ? leaveTypeData.auto_increment_value : 1 ,
    },
    
  });
console.log("deg",leaveTypeData.auto_increment_value)
  const isLopLeaveType = Number(leaveTypeData?.is_lop || 0);
  const [isActive, setIsActive] = useState(getValues("status"));
  const [isAutoIncrement, setIsAutoIncrement] = useState(getValues("isAutoIncrementEnable"));
  const axiosPrivate = useAxiosPrivate();
  const [isCarryForward, setCarryForward] = useState(getValues("carryForward"));
  const [selectedCarryForwardType, setSelectedCarryForwardType] = useState(
    carryForwardTypeOptions[0]
  );

  useEffect(() => {
    setIsActive(getValues("status"));
    setIsAutoIncrement(getValues("isAutoIncrementEnable"));
  }, [leaveTypeData, getValues]);

  const getToggleState = (e) => {
    setValue("status", Number(e));
    setIsActive(e);
  };

  const handleCloseModal = () => {
    makeApiCall();
    onCloseModal();
  };

  const getErrorMessage = (key) => {
    if (errors[key]) {
      return (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-2 ml-1">
          {errors[key].message}
        </span>
      );
    }
  };

  const handleCarryForwardType = (e) => {
    setSelectedCarryForwardType(e);
    setValue("carryForwardType", e.value);
  };
  

  const onSubmit = async (data) => {
    const {
      leaveTypeName,
      numberOfDaysAllowed,
      carryForward,
      carryForwardLimit,
      carryForwardType,
      carryForwardYears,
      status,
      description,
      restrictPriorApplication,
      priorDays,
      canRequestByEmployee,
      paid,
      gender,
      isAutoIncrementEnable,
      autoIncrementValue,
    } = data;
    const reqBody = {
      leaveTypeName,
      numberOfDaysAllowed: Number(numberOfDaysAllowed),
      carryForwardYears: carryForward ? Number(carryForwardYears) : 0,
      status,
      description,
      carryForwardLimit: carryForward ? Number(carryForwardLimit) : 0,
      carryForward: Number(carryForward),
      carryForwardType: Number(carryForwardType),
      restrictPriorApplication,
      priorDays: Number(priorDays),
      leaveTypeId: leaveTypeData.id,
      canRequestByEmployee: canRequestByEmployee ? 1 : 0,
      paid,
      isLop: !!isLopLeaveType,
      gender,
      isAutoIncrementEnable: Number(isAutoIncrementEnable),
      autoIncrementValue: Number(autoIncrementValue),
    };


    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .put(`lms/leave/updateLeaveType`, reqBody)
      .then((response) => {
        const res = response?.data;
        if (res.status) {
          toast.success(res.message);
          handleCloseModal();
        } else {
          toast.error("Something went wrong, try again");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong, try again");
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  return (
    <>
      <ReactSlidingPane
        isOpen={openModal}
        onRequestClose={onCloseModal}
        width="450px"
      >
        <div className="flex justify-between items-center px-5 border-b pb-3">
          <h1 className="font-medium text-black">Edit Leave Type & Policy</h1>
          <button onClick={onCloseModal}>{closeIcon}</button>
        </div>
        <div className="px-5 max-w-[450px] text-black flex flex-col gap-3">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="py-3">
              <label className="py-1">Leave type name</label>
              <input
                type="text"
                {...register("leaveTypeName", {
                  required: "This field is required",
                })}
              />
              {getErrorMessage("leaveTypeName")}
            </div>
            {isLopLeaveType ? (
              <div className="border rounded-md p-2 bg-blue-500 text-white font-medium shadow-md">
                This leave type is defined as Loss of Pay as the deduction in
                salary due to leave taken by an employee
              </div>
            ) : null}
            {!isLopLeaveType ? (
              <div className="py-1">
                <label className="py-3">Number of days allowed</label>
                <input
                  type="text"
                  {...register("numberOfDaysAllowed", {
                    required: "This field is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid number",
                    },
                  })}
                />
                {getErrorMessage("numberOfDaysAllowed")}
              </div>
            ) : null}

            {!isLopLeaveType ? (
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <label className="py-3">Carry forward</label>
                  <input
                    type="checkbox"
                    {...register("carryForward")}
                    onChange={(e) => setCarryForward(e.target.checked)}
                  />
                </div>
              </div>
            ) : null}
            {Number(isCarryForward) ? (
              <>
                <div className="flex flex-col gap-2">
                  <div>
                    <label>Carry forward type</label>
                    <Select
                      components={{ DropdownIndicator }}
                      className="custom-select-container"
                      options={carryForwardTypeOptions}
                      value={selectedCarryForwardType}
                      onChange={(e) => handleCarryForwardType(e)}
                    />
                  </div>
                  <div>
                    <label>Carry forward limit</label>
                    <input
                      type="text"
                      {...register("carryForwardLimit", {
                        required: "This field is required",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid number",
                        },
                      })}
                    />
                    {getErrorMessage("carryForwardLimit")}
                  </div>

                  <div>
                    <label>Carry forward years</label>
                    <input
                      type="text"
                      {...register("carryForwardYears", {
                        required: "This field is required",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid number",
                        },
                      })}
                    />
                    {getErrorMessage("carryForwardYears")}
                  </div>
                </div>
              </>
            ) : null}

            {!isLopLeaveType ? (
              <div className="py-3">
                <div className="flex items-center gap-2">
                  {" "}
                  Paid Leave
                  <ToggleSwitch
                    checked={getValues("paid")}
                    getToggleState={(e) => setValue("paid", e ? 1 : 0)}
                  />
                </div>
              </div>
            ) : null}

            <div className="py-3">
              <div className="flex items-center gap-2">
                {" "}
                Enable Auto Increment
                <ToggleSwitch
                  checked={isAutoIncrement}
                  getToggleState={(e) => {
                    setIsAutoIncrement(e);
                    setValue("isAutoIncrementEnable", Number(e));
                    // if (!e) {
                    //   setValue("autoIncrementValue", "");
                    // }
                  }}
                />
              </div>
            </div>
            {!!isAutoIncrement && (
              <div className="py-3">
                <label>Auto Increment Value</label>
                <input
                  type="text"
                  {...register("autoIncrementValue", {
                    pattern: {
                      value: /^[1-9]+$/,
                      message: "Please enter a valid number",
                    },
                  })}
                  
                />
                {getErrorMessage("autoIncrementValue")}
              </div>
            )}

            <div className="py-3">
              <div className="flex items-center gap-2">
                {" "}
                {isActive ? "Active" : "Inactive"}
                <ToggleSwitch
                  checked={getValues("status")}
                  getToggleState={getToggleState}
                />
              </div>
            </div>
            <div className="py-3 flex items-center gap-3">
              <label>Restrict prior application</label>
              <input
                type="checkbox"
                {...register("restrictPriorApplication")}
              />
            </div>
            {!isLopLeaveType ? (
              <div className=" flex items-center gap-3">
                <label>Can request by employee for additional leave</label>
                <input type="checkbox" {...register("canRequestByEmployee")} />
              </div>
            ) : null}
            {/* gender */}
            <div className="py-3">
              <label>Applicable For</label>
              <select
                {...register("gender")}
                defaultValue={leaveTypeData.gender}
              >
                <option value="" disabled>
                  All Gender
                </option>
                {genderOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="py-3">
              <label>Prior days</label>
              <input
                type="text"
                {...register("priorDays", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a valid number",
                  },
                })}
              />
            </div>

            <div className="pb-4">
              <label className="py-3">Description</label>
              <Controller
                control={control}
                name="description"
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => {
                  return <ReactQuill {...field} theme="snow" />;
                }}
              />
            </div>

            {getErrorMessage("description")}

            <div className="flex justify-end gap-4 mt-10">
              <button
                type="button"
                className="btn btn--border"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button type="submit" className="btn">
                Update leave type
              </button>
            </div>
          </form>
        </div>
      </ReactSlidingPane>
    </>
  );
};

export default EditLeaveTypeModal;
