import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import { toast } from "react-toastify";
import LoadingButton from "../../components/Buttons/LoadingButton";
import formatLeaveDuration from "../../utils/FormatLeaveDuration/formatLeaveDuration";
import MultipleLeaveActionPercentageBar from "../../components/Leaves/MultipleLeaveActionPercentageBar/MultipleLeaveActionPercentageBar";
import { closeIcon } from "../../Icons/Icons";
import Avatar from "../../components/Avatar/Avatar";
import formatDate from "../../utils/FormatDate/formatDate";
import formatAvatarLetter from "../../utils/FormatAvatarLetter/formatAvatarLetters";

const ApproveLeaveModal = ({
  openModal,
  onCloseModal,
  leaveApplication,
  makeApiCall,
  eligibleToApproveOrDecline,
  setSelectedId,
  isMultipleAction,
  toggleState,
}) => {
  // console.log("leave application",leaveApplication)
  const axiosPrivate = useAxiosPrivate();
  const [remark, setRemark] = useState("");
  const user = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(user, 2);
  const employeeName = isHr
    ? leaveApplication?.employee_name ||
      `${leaveApplication?.first_name} ${leaveApplication?.last_name}`
    : `${leaveApplication?.first_name} ${leaveApplication?.last_name}`;
  const [errMsg, setErrMsg] = useState("");
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const leaveApplicationId =
    leaveApplication?.additional_leave_application_id ||
    leaveApplication?.leave_application_id;

  const [hrForceApprove, setHrForceApprove] = useState(false);
  const [submitBtnTitle, setSubmitBtnTitle] = useState("Approve Leave");
  const [numberOfActions, setNumberOfActions] = useState(0);
  const additionalLeaveIndex = 2;
  const availableLeaveBalance = formatLeaveDuration({
    durationInDay: leaveApplication?.leave_balance_days,
    durationInHour: leaveApplication?.leave_balance_hours,
  });

  const applicationDate = formatDate(leaveApplication?.application_date);
  const fromDate = formatDate(
    leaveApplication?.from_date || leaveApplication?.applied_for_date
  );
  const toDate = formatDate(leaveApplication?.to_date);
  const totalDays =
    toggleState === additionalLeaveIndex
      ? "1"
      : formatLeaveDuration({
          durationInDay: leaveApplication?.duration_in_day,
          durationInHour: leaveApplication?.duration_in_hour,
        });
  const leaveName = leaveApplication?.leave_name;

  const hrLeaveApproval = async (id) => {
    const baseUrl =
      toggleState === additionalLeaveIndex
        ? "lms/leave/hrAdditionalLeaveApproval"
        : "lms/leave/hrApproval";
    const hrForceAction = isMultipleAction ? true : hrForceApprove;
    const reqBody =
      toggleState === additionalLeaveIndex
        ? {
            additional_leave_id: id || leaveApplicationId,
            hr_comments: remark,
            force_approval: hrForceAction,
          }
        : {
            leave_application_id: id || leaveApplicationId,
            hr_remarks: remark,
            force_approve: hrForceAction,
          };
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .put(baseUrl, reqBody)
      .then((response) => {
        // isMounted && console.log(response.data, "hrapproval");
        const res = response?.data;
        if (res?.status) {
          setNumberOfActions((prev) => prev + 1);
          if (!isMultipleAction) {
            setIsLoadingBtn(false);
            toast.success("Leave application approved.");
            makeApiCall();
            onCloseModal();
          }
        } else if (res.manager_action_pending) {
          setIsLoadingBtn(false);
          setSubmitBtnTitle("Force Approve");
          setHrForceApprove(true);
          setErrMsg("Manager action pending, do you want to proceed?");
        } else {
          setIsLoadingBtn(false);
          setErrMsg(res.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const managerLeaveApproval = async (id) => {
    const baseUrl =
      toggleState === additionalLeaveIndex
        ? "lms/leave/managerAdditionalLeaveApproval"
        : "lms/leave/managerApproval";
    const reqBody =
      toggleState === additionalLeaveIndex
        ? {
            additional_leave_id: id || leaveApplicationId,
            manager_comments: remark,
          }
        : {
            leave_application_id: id || leaveApplicationId,
            remark: remark,
          };
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .put(baseUrl, reqBody)
      .then((response) => {
        isMounted && console.log(response.data, "managerapproval");
        const res = response?.data;
        if (res?.status) {
          setNumberOfActions((prev) => prev + 1);
          if (!isMultipleAction) {
            toast.success("Leave application approved.");
            setIsLoadingBtn(false);
            onCloseModal();
            makeApiCall();
          }
        } else {
          setErrMsg(res?.message || "Failed to approve leave application");
        }
      })
      .catch((err) => {
        // console.error(err);
        setErrMsg(err?.respose?.data?.message || "Something went wrong");
        setIsLoadingBtn(false);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (remark) {
      setErrMsg("");
      if (leaveApplicationId) {
        if (isHr) {
          setIsLoadingBtn(true);
          hrLeaveApproval();
        } else {
          setIsLoadingBtn(true);
          managerLeaveApproval();
        }
      } else {
        setErrMsg(
          "Select a leave application to proceed or selected leave applications are not eligible to approve."
        );
      }
    } else {
      setErrMsg("Fill out the required field");
    }
  };

  useEffect(() => {
    if (
      numberOfActions > 0 &&
      eligibleToApproveOrDecline?.length === numberOfActions
    ) {
      toast.success(`${numberOfActions} Leave applications approved`);
      setTimeout(() => {
        setIsLoadingBtn(false);
        makeApiCall();
        setRemark(null);
        setNumberOfActions(0);
        onCloseModal();
        setSelectedId([]);
      }, 1000);
    }
  }, [numberOfActions]);

  const handleMultipleApprove = async (e) => {
    e.preventDefault();
    if (remark) {
      setErrMsg("");
      if (eligibleToApproveOrDecline?.length) {
        setIsLoadingBtn(true);
        await Promise.all(
          eligibleToApproveOrDecline.map((item) =>
            isHr
              ? hrLeaveApproval(item?.leave_application_id)
              : managerLeaveApproval(item?.leave_application_id)
          )
        )
          .then((res) => res)
          .catch((err) => err);
      }
    } else {
      setErrMsg("Fill out the required field");
    }
  };

  const handleCloseModal = () => {
    setErrMsg("");
    setRemark("");
    onCloseModal();
    setSelectedId && setSelectedId([]);
  };
  useEffect(() => {
    if (toggleState === additionalLeaveIndex) {
      setSubmitBtnTitle("Approve");
    } else {
      setSubmitBtnTitle("Approve Leave");
    }
  }, [toggleState, additionalLeaveIndex]);
  return (
    <>
      <Modal open={openModal} onClose={handleCloseModal} center closeIcon=" ">
        <div className="p-5 min-w-[420px] text-black">
          <>
            <form
              onSubmit={isMultipleAction ? handleMultipleApprove : handleSubmit}
            >
              <div className="flex pb-3 justify-between items-center">
                <h2 className="text-lg font-semibold">Approve Leave</h2>
                <button type="button" onClick={handleCloseModal}>
                  {closeIcon}
                </button>
              </div>
              {!isMultipleAction && leaveApplication ? (
                <>
                  <div className="p-2 flex gap-2 items-center rounded-3xl w-1/2 bg-gray-100">
                    <Avatar width={"6"} height={"6"} />
                    <p className="font-medium">{employeeName}</p>
                  </div>
                  <div className="p-2 mt-2 flex gap-2 items-center rounded-3xl w-full bg-gray-100">
                    <p className="font-medium">Leave name: {leaveName}</p>
                  </div>

                  {toggleState !== additionalLeaveIndex && (
                    <div className="p-2 mt-2 flex gap-2 items-center rounded-3xl w-full bg-gray-100 ">
                      <p className="font-medium">
                        Available leave balance: {availableLeaveBalance} <br />
                        Leave applied on: {applicationDate.dateString2}
                        <br />
                        Leave requested for: {fromDate.dateString2} -{" "}
                        {toDate.dateString2} ({totalDays})
                      </p>
                    </div>
                  )}
                </>
              ) : isMultipleAction && !eligibleToApproveOrDecline?.length ? (
                <p className="text-red-600 font-medium">
                  Selected leave applications are not eligible to approve.
                </p>
              ) : null}
              {isMultipleAction && eligibleToApproveOrDecline.length ? (
                <>
                  <p className="my-6">
                    <span className="text-black font-medium pb-2">
                      {eligibleToApproveOrDecline.length} eligible leave
                      application to approve
                    </span>

                    {eligibleToApproveOrDecline?.length &&
                      eligibleToApproveOrDecline.map((leave) => {
                        const avatarTitle = formatAvatarLetter(
                          `${leave.first_name} ${leave.last_name}`
                        );
                        return (
                          <>
                            <div className="flex items-center justify-between py-1 gap-x-10">
                              <div className="p-2 flex h-10 gap-2 items-center rounded-3xl w-1/2 bg-gray-100">
                                <Avatar
                                  title={avatarTitle}
                                  width={"6"}
                                  height={"6"}
                                />
                                <p className="font-medium whitespace-nowrap w-full">{`${leave.first_name} ${leave.last_name}`}</p>
                              </div>
                              <div className="flex justify-end">
                                <div>
                                  <p className="text-end font-medium">
                                    {leave.leave_name}
                                  </p>
                                  <span className="text-xs text-gray-500 whitespace-nowrap">
                                    {formatDate(leave?.from_date).dateString2} -{" "}
                                    {formatDate(leave?.to_date).dateString2} (
                                    {formatLeaveDuration({
                                      durationInDay: leave?.duration_in_day,
                                      durationInHour: leave?.duration_in_hour,
                                    })}
                                    )
                                  </span>
                                  <br />
                                  <span className="text-xs text-gray-500 whitespace-nowrap">
                                    Available leave balance:{" "}
                                    {formatLeaveDuration({
                                      durationInDay: leave?.leave_balance_days,
                                      durationInHour:
                                        leave?.leave_balance_hours,
                                    })}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </p>
                  {numberOfActions > 0 ? (
                    <>
                      <p>
                        Approved{" "}
                        {`${numberOfActions} / ${eligibleToApproveOrDecline?.length}`}
                      </p>
                      <MultipleLeaveActionPercentageBar
                        actions={numberOfActions}
                        length={eligibleToApproveOrDecline?.length}
                      />
                    </>
                  ) : null}
                </>
              ) : null}
              {isMultipleAction &&
              !eligibleToApproveOrDecline?.length ? null : (
                <>
                  {" "}
                  <label className="text-sm text-[#1F2937] font-medium">
                    Add note <span className="text-red-600 font-medium">*</span>
                  </label>
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 mb-2">
                    {errMsg}
                  </span>
                  <textarea
                    name="noteContent"
                    value={remark}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\s/.test(value)) {
                        setErrMsg("Note cannot start with a space");
                      } else {
                        setErrMsg("");
                        setRemark(value);
                      }
                    }}
                  />
                </>
              )}

              <div className="flex justify-end gap-3 mt-6">
                <button
                  type="button"
                  className="btn btn--border text-sm font-medium"
                  onClick={onCloseModal}
                >
                  Cancel
                </button>

                {isLoadingBtn ? (
                  <LoadingButton
                    bg={"btn--green"}
                    title={"Approving Leave Application"}
                  />
                ) : isMultipleAction &&
                  !eligibleToApproveOrDecline?.length ? null : (
                  <button
                    type="submit"
                    className="btn btn--green text-sm font-medium"
                  >
                    {submitBtnTitle}
                  </button>
                )}
              </div>
            </form>
          </>
        </div>
      </Modal>
    </>
  );
};

export default ApproveLeaveModal;
