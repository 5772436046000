import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import NewRegularizationRequestSlidingPanel from "../SelfService/MyAttendance/NewRegularizationRequestSlidingPanel";
import LoadingButton from "../../Buttons/LoadingButton";
import AttendanceActivityClock from "./MyAttendance/AttendanceActivityClock";
import formatDate from "../../../utils/FormatDate/formatDate";
import { format } from "date-fns";
import { toast } from "react-toastify";
import moment from "moment";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const TABLE_VIEW = 1;
const CALENDAR_VIEW = 2;
const CLOCK_IN = 1;
const CLOCK_OUT = 2;

const MyAttendanceHead = ({
  handleSetView,
  dataView,
  selectedOption,
  onSelectedOptionChange,
  setSelectedOption,
  setSelectedLabel,
  selectedStatus,
  onStatusChange,
  toDate,
  fromDate,
  onDateChange,
  showRegularizationPanel,
  showClockData,
  employee
}) => {
  const [
    openNewRegularizationRequestSidePanel,
    setOpenNewRegularizationRequestSidePanel,
  ] = useState(false);

  const handleNewRegularizationRequest = () => {
    setOpenNewRegularizationRequestSidePanel(true);
  };
  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    onSelectedOptionChange({ value: "1", label: "All Status" });
    setSelectedLabel({ value: 1, label: "monthly" });
  }, []);
  // const [fromDate, setFromDate] = useState(new Date());
  // const [toDate, setToDate] = useState(new Date());
  const [attendanceActivityType, setAttendanceActivityType] = useState(null);
  const [attendanceActivityBtnLoader, setAttendanceActivityBtnLoader] =
    useState(false);
  const isClockInActivity = attendanceActivityType === CLOCK_IN;
  const [clockActivity, setClockActivity] = useState();
  const [attendanceActivityData, setAttendanceActivityData] = useState(null);
  const [clockActivityMsg, setClockActivityMsg] = useState({
    firstClockInTime: "--:--",
    lastClockInTime: "--:--",
    lastClockOutTime: "--:--",
  });
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedWeekStart, setSelectedWeekStart] = useState(new Date());
  // const [selectedStatus, setSelectedStatus] = useState({
  //   value: "1",
  //   label: "All Status",
  // });

  const [filterSelected, setFilterSelected] = useState(false);

  const fetchAttendanceActivity = async () => {
    try {
      const date = moment().format("YYYY-MM-DD");
      const response = await axiosPrivate.get(
        `attendance/getAttendanceActivity?date=${date}`
      );
      setAttendanceActivityData(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchAttendanceActivity();
  }, []);
  useEffect(() => {
    // console.log("attendance activity", attendanceActivityData);
    if (attendanceActivityData && attendanceActivityData.result) {
      const {
        firstClockIn,
        lastClockIn,
        lastClockOut,
        shiftName,
        fromTime,
        toTime,
      } = attendanceActivityData.result;
      // console.log("data response:", attendanceActivityData);

      const formatTime = (timeString) => {
        if (!timeString) return "--:--";
        const timeParts = timeString.split("T")[1].split(".")[0].split(":");
        const hours = parseInt(timeParts[0]);
        const minutes = parseInt(timeParts[1]);
        const localTime = moment().hour(hours).minute(minutes).local();
        return localTime.format("h:mm A");
      };

      const firstClockInTime = formatTime(firstClockIn);
      const lastClockOutTime = formatTime(lastClockOut);
      const lastClockInTime = formatTime(lastClockIn);

      setClockActivityMsg({
        firstClockInTime,
        lastClockInTime,
        lastClockOutTime,
        shiftName,
        fromTime,
        toTime,
      });
      // console.log("setClockActivityMsg", firstClockInTime);
    } else {
      setClockActivityMsg({
        firstClockInTime: "--:--",
        lastClockInTime: "--:--",
        lastClockOutTime: "--:--",
      });
    }
  }, [attendanceActivityData]);

  const clockInOrOut = (activity) => {
    setClockActivity(activity);
  };

  const getAttendanceActivity = (activityType) => {
    setAttendanceActivityType(activityType);
  };

  const getClockInOutBtnLoaderState = (btnStatus) => {
    setAttendanceActivityBtnLoader(btnStatus);
  };
  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const today = moment(new Date()).format("dddd, MMM D YYYY");

  const monthLabel = format(selectedMonth, "MMMM yyyy");

  const generateWeeklyLabel = () => {
    const startOfWeek = new Date(selectedWeekStart);
    const endOfWeek = new Date(selectedWeekStart);

    startOfWeek.setDate(
      selectedWeekStart.getDate() - selectedWeekStart.getDay()
    );
    endOfWeek.setDate(
      selectedWeekStart.getDate() - selectedWeekStart.getDay() + 6
    );

    return `${format(startOfWeek, "MMM dd, yyyy")} - ${format(
      endOfWeek,
      "MMM dd, yyyy"
    )}`;
  };
  const clearFilters = () => {
    onStatusChange(null);
    setFilterSelected(false);
  };

  useEffect(() => {
    if (selectedStatus) {
      setFilterSelected(true);
    }
  }, [selectedOption, selectedStatus]);

  useEffect(() => {
    const initialOption = { value: 1, label: "monthly" };
    const initialLabel = "monthly";
    const currentDate = moment();
    let fromDate, toDate;

    switch (initialOption.value) {
      case 1:
        fromDate = moment().startOf("month").toDate();
        toDate = moment().endOf("month").toDate();
        break;
      case 2:
        fromDate = moment().startOf("week").toDate();
        toDate = moment().endOf("week").toDate();
        break;
      case 3:
        fromDate = moment().startOf("week").toDate();
        toDate = moment().endOf("week").toDate();
        break;
      default:
        break;
    }

    setSelectedOption(initialOption);
    setSelectedLabel(initialLabel);
    // setFromDate(fromDate);
    //setToDate(toDate);
  }, []);

  const handlePreviousClick = () => {
    switch (selectedOption?.value) {
      case 1:
        setSelectedMonth((prevMonth) => {
          const newMonth = moment(prevMonth).subtract(1, "month").toDate();
          const fromDate = moment(newMonth).startOf("month").toDate();
          const toDate = moment(newMonth).endOf("month").toDate();
          onDateChange(fromDate, toDate);
          return newMonth;
        });
        break;
      case 2:
        setSelectedWeekStart((prevWeekStart) => {
          const newWeekStart = moment(prevWeekStart)
            .subtract(1, "week")
            .toDate();
          const fromDate = moment(newWeekStart).startOf("week").toDate();
          const toDate = moment(newWeekStart).endOf("week").toDate();
          onDateChange(fromDate, toDate);
          return newWeekStart;
        });
        break;
      default:
        break;
    }
  };

  const handleNextClick = () => {
    switch (selectedOption?.value) {
      case 1:
        setSelectedMonth((prevMonth) => {
          const newMonth = moment(prevMonth).add(1, "month").toDate();
          const fromDate = moment(newMonth).startOf("month").toDate();
          const toDate = moment(newMonth).endOf("month").toDate();
          onDateChange(fromDate, toDate);
          return newMonth;
        });
        break;
      case 2:
        setSelectedWeekStart((prevWeekStart) => {
          const newWeekStart = moment(prevWeekStart).add(1, "week").toDate();
          const fromDate = moment(newWeekStart).startOf("week").toDate();
          const toDate = moment(newWeekStart).endOf("week").toDate();
          onDateChange(fromDate, toDate);
          return newWeekStart;
        });
        break;
      default:
        break;
    }
  };

  const handleOptionChange = (selected) => {
    onSelectedOptionChange(selected);
    const currentDate = moment();
    let fromDate, toDate;

    switch (selected.value) {
      case 1:
        fromDate = moment().startOf("month").toDate();
        toDate = moment().endOf("month").toDate();
        // Adjust toDate to current date if it's beyond the current date
        if (moment().endOf("month").isAfter(currentDate, "day")) {
          toDate = currentDate.toDate();
        }
        break;
      case 2:
        fromDate = moment().startOf("week").toDate();
        toDate = moment().endOf("week").toDate();
        break;
      case 3:
        fromDate = moment().startOf("week").toDate();
        toDate = currentDate.toDate(); // current date for current attendance period
        break;
      default:
        break;
    }

    onDateChange(fromDate, toDate);
  };

  let contentToShow;
  switch (selectedOption?.value) {
    case 1:
      contentToShow = <span>{monthLabel}</span>;
      break;
    case 2:
      contentToShow = <span>{generateWeeklyLabel()}</span>;
      break;
    case 3:
      contentToShow = <span>{generateWeeklyLabel()} </span>;
      break;
    default:
      contentToShow = null;
  }
  const handleStatusChange = (selected) => {
    onStatusChange(selected);
  };
  const handleDownload = () => {
    const baseUrl = "attendance/generateAttendanceReport";
    const formattedFromDate = moment(fromDate).format("YYYY-MM-DD");
    const formattedToDate = moment(toDate).format("YYYY-MM-DD");
    const params = {
      label: selectedOption?.value,
      selectedOption: selectedOption?.value,
      from_date: formattedFromDate,
      to_date: formattedToDate,
    };
    const url = `${baseUrl}?${new URLSearchParams(params)}`;

    axiosPrivate
      .get(url, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const a = document.createElement("a");

        a.href = url;

        a.download = "attendance_report.csv";

        a.click();

        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        toast.error("Error in downloading file!");
        // console.error("There was a problem with the Axios request:", error);
      });
  };

  return (
    <div>
      {showRegularizationPanel && (
        <NewRegularizationRequestSlidingPanel
          open={openNewRegularizationRequestSidePanel}
          close={() => setOpenNewRegularizationRequestSidePanel(false)}
        />
      )}
      <div className="flex justify-between mt-8 px-10">
        <div className="text-gray-800">
        
          {showClockData && ( 
            <div>
  <h2 className="text-lg leading-6 font-semibold">{today}</h2>
          <p className="text-sm text-[#1F2937] pt-1">
            {clockActivityMsg.shiftName} ({clockActivityMsg.fromTime} AM -{" "}
            {clockActivityMsg.toTime} PM)
          </p>
            <div className="flex flex-col justify-between mt-4">
              <div className="flex justify-between items-center gap-4">
                <p>First Clock-In</p>
                <p className="text-gray-800 font-medium">
                  {clockActivityMsg.firstClockInTime}{" "}
                </p>
              </div>
              <div className="flex justify-between items-center pt-2 pb-2 gap-4">
                <p>Last Clock-In</p>
                <p className="text-gray-800 font-medium">
                  {clockActivityMsg.lastClockInTime}
                </p>
              </div>
              <div className="flex justify-between items-center gap-4">
                <p>Last Clock-Out</p>
                <p className="text-gray-800 font-medium">
                  {clockActivityMsg.lastClockOutTime}
                </p>
              </div>
            </div>
            </div>
          )}
        </div>
        <div>
          {showRegularizationPanel && (
            <div className="mb-7 text-right">
              <button
                type="button"
                onClick={handleNewRegularizationRequest}
                className="btn text-[14px]"
              >
                <span>
                  <i className="ico-plus text-white mr-1"></i>
                </span>
                Request Regularization
              </button>
              <div className="gap-4 py-3 px-5"></div>
              <div
                className={`border min-w-[350px] flex items-center justify-center gap-4 py-3 px-5 rounded-lg ${
                  attendanceActivityBtnLoader
                    ? "bg-white border-gray-200"
                    : isClockInActivity
                    ? "bg-[#ECFDF5] border-green-200"
                    : "bg-[#FEF2F2] border-red-200"
                }`}
              >
                <h3
                  className={`text-2xl font-semibold ${
                    attendanceActivityBtnLoader
                      ? "text-gray-800"
                      : isClockInActivity
                      ? "text-green-800"
                      : "text-red-800"
                  }`}
                >
                  <AttendanceActivityClock
                    setClockActivityMsg={setClockActivityMsg}
                    clockActivity={clockActivity}
                    getClockInOutBtnLoaderState={getClockInOutBtnLoaderState}
                    getAttendanceActivity={getAttendanceActivity}
                    setAttendanceActivityData={setAttendanceActivityData}
                    setClockActivity={setClockActivity}
                  />
                </h3>
                <div className="">
                  {attendanceActivityBtnLoader ? (
                    <LoadingButton bg={"btn--border"} />
                  ) : (
                    <button
                      onClick={() =>
                        clockInOrOut(isClockInActivity ? CLOCK_IN : CLOCK_OUT)
                      }
                      className={`btn btn--${
                        isClockInActivity ? "green" : "red"
                      }`}
                    >
                      Clock-{isClockInActivity ? "In" : "Out"}
                      <i className="ico-clock ml-4"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="px-10 flex justify-between my-4 ">
        <div className="flex items-center gap-3">
          <Select
            options={[
              { value: 1, label: "monthly" },
              { value: 2, label: "weekly" },
              { value: 3, label: "current attendance period" },
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            onChange={handleOptionChange}
            // value={selectedOption}
          />
          <Select
            options={[
              { value: "1", label: "All Status" },
              { value: "2", label: "Present" },
              { value: "3", label: "Pending" },
              { value: "4", label: "Leave" },
              { value: "5", label: "Absent" },
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            onChange={handleStatusChange}
          />

          {filterSelected && <span onClick={clearFilters}>Clear Filters</span>}
        </div>
        <div className="flex items-center gap-3 text-sm text-[#1F2937] pt-1">
          <button onClick={handlePreviousClick}>{"<"}</button>
          {contentToShow}
          <button onClick={handleNextClick}>{">"}</button>
        </div>

        <div className="flex gap-3">
          <div className="flex">
            <span onClick={() => handleSetView(TABLE_VIEW)}>
              {" "}
              <i
                className={`ico-table btn btn--border border-r-0 rounded-r-none hover:text-blue-500 cursor-pointer ${
                  dataView === TABLE_VIEW ? "text-blue-600 bg-blue-100" : ""
                }`}
              ></i>
            </span>
            <span onClick={() => handleSetView(CALENDAR_VIEW)}>
              {" "}
              <i
                className={`ico-calendar btn btn--border rounded-l-none hover:text-blue-500 cursor-pointer ${
                  dataView === CALENDAR_VIEW ? "text-blue-600 bg-blue-100" : ""
                }`}
              ></i>
            </span>
          </div>
          <a onClick={handleDownload} className="btn btn--border text-[14px]">
            <span>
              <i className="ico-download mr-1"></i>
            </span>
            Download
          </a>
        </div>
      </div>
    </div>
  );
};

export default MyAttendanceHead;
