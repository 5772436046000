export const reactSelectBoxValidator = (selectBoxObjectWithValues) => {
  // console.log(selectBoxObjectWithValues)
  if (typeof selectBoxObjectWithValues === "object") {
    let objLenght = 0;
    const selectBoxEntries = Object.entries(selectBoxObjectWithValues);
    for (let i = 0; i < selectBoxEntries.length; i++) {
      if (
        selectBoxEntries[i][1] === undefined ||
        selectBoxEntries[i][1] === null ||
        selectBoxEntries[i][1] === ''
      ) {
        try {
          console.warn(`Fill all the required fields!`)
          document.getElementById(selectBoxEntries[i][0]).innerHTML =
          "This field is required";
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        } catch(err) {
          // console.log(err)
          console.warn("Maybe the selected field(s) doesn't want to show error messages.")
        }
      } else {
        objLenght += 1;
      }
    }
    if (objLenght === selectBoxEntries.length) {
      return true;
    } else return false;
  } else {
    return {
      status: false,
      message: "instanceof Object is only accepted!",
      error: `${typeof selectBoxObjectWithValues} is not accepted`,
    };
  }
};
// import reactSelectBoxValidator in the page.
// syntax - reactSelectBoxValidator(object) (object that contains select box values with key , the "key" must be the name of the "id" to set error messages div. eg: div id="gender" , then key must be "gender" in the object)
// add reactSelectBoxValidator(object) to an onClick event(like handleSubmit) to validate all the fields before submitting the form
// also add reactSelectBoxValidator(object) inside the onSubmit function assigned to a const. if all values are there it will return true. we can use the returned function to validate the next step.

